import { apiSlice as api } from './apiSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: 'POST',
        body: queryArg.loginRequestDto,
      }),
    }),
    authControllerRefresh: build.mutation<
      AuthControllerRefreshApiResponse,
      AuthControllerRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/refresh`,
        method: 'POST',
        body: queryArg.refreshRequestDto,
      }),
    }),
    authControllerLogout: build.mutation<
      AuthControllerLogoutApiResponse,
      AuthControllerLogoutApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/logout`,
        method: 'POST',
        body: queryArg.logoutRequestDto,
      }),
    }),
    authControllerPasswordRecovery: build.mutation<
      AuthControllerPasswordRecoveryApiResponse,
      AuthControllerPasswordRecoveryApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/password-recovery`,
        method: 'POST',
        body: queryArg.passwordRecoveryRequestDto,
      }),
    }),
    authControllerEmailVerify: build.query<
      BaseResponseDto,
      AuthEmailVerifyArgs
      >({
      query: (queryArg) => ({
        url: `/verify/email/${queryArg.token}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authEndpoints };
export type AuthControllerLoginApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: LoginResponseDto;
  };

export type AuthEmailVerifyArgs = {
  token: string
}

export type AuthControllerLoginApiArg = {
  loginRequestDto: LoginRequestDto;
};
export type AuthControllerRefreshApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: LoginResponseDto;
  };
export type AuthControllerRefreshApiArg = {
  refreshRequestDto: RefreshRequestDto;
};
export type AuthControllerLogoutApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: String;
  };
export type AuthControllerLogoutApiArg = {
  logoutRequestDto: LogoutRequestDto;
};
export type AuthControllerPasswordRecoveryApiResponse = unknown;
export type AuthControllerPasswordRecoveryApiArg = {
  passwordRecoveryRequestDto: PasswordRecoveryRequestDto;
};
export type BaseResponseDto = {
  status: 'OK' | 'ERROR';
  messages?: string[];
  result?: object;
};
export type LoginResponseDto = {
  access_token: string;
  refresh_token: string;
};
export type LoginRequestDto = {
  email: string;
  password: string;
  fingerprint: string;
};
export type RefreshRequestDto = {
  fingerprint: string;
  refresh_token?: string;
};
export type String = {};
export type LogoutRequestDto = {
  fingerprint: string;
  refresh_token?: string;
};
export type PasswordRecoveryRequestDto = {
  phone: string;
  code?: string;
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerRefreshMutation,
  useAuthControllerLogoutMutation,
  useAuthControllerPasswordRecoveryMutation,
  useLazyAuthControllerEmailVerifyQuery
} = injectedRtkApi;
