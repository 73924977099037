import React, { Fragment } from 'react';
import styles from './TabItem.module.scss';
import { Radio, RadioChangeEvent } from 'antd';
import {
  Legend,
  ComposedChart,
  ResponsiveContainer,
  Area,
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  Line,
} from 'recharts';
import { lch } from 'd3-color';
import { colorHash } from 'utils';

type Tab = {
  label: string;
  value: string;
};

interface Props<T> {
  legendPosition?: 'top' | 'bottom';
  tabs: Tab[];
  onTabChange: (value: string) => void;
  title: string;
  data?: T[];
  type: 'linear' | 'bars' | 'lines';
  options: {
    xAxis: keyof T;
    yAxis?: keyof T;
  };
  dataKeys: Array<{ key: keyof T; label: string }>;
  color: string;
  children?: React.ReactNode;
}

const TabItem = <T,>({
  tabs,
  legendPosition = 'top',
  onTabChange,
  title,
  children,
  data,
  options: { xAxis, yAxis },
  color,
  type,
  dataKeys,
}: Props<T>) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0].value);
  const tabChange = (e: RadioChangeEvent) => {
    setActiveTab(e.target.value);
    onTabChange(e.target.value);
  };

  const cellColor = lch(color) || color;

  const GraphLegend = ({ payload }: { payload?: any[] }) => {
    return (
      <div
        className={styles.Tabbar}
        style={
          legendPosition === 'top'
            ? {
                paddingBottom: 10,
                boxShadow: 'inset 0px -1px 0px #F0F0F0',
              }
            : {
                flexDirection: 'column',
              }
        }
      >
        <div className={styles.InnerTabbar}>
          <Radio.Group
            buttonStyle="solid"
            onChange={tabChange}
            size="small"
            value={activeTab}
          >
            {tabs.map(({ value, label }) => (
              <Radio.Button key={value} value={value}>
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        {legendPosition === 'bottom' && <div className={styles.Shadow}></div>}
        <div className={styles.InnerTabbar}>
          <ul className={styles.Legend}>
            {payload?.map((entry, index) => (
              <li key={index} style={{ color: entry.color }}>
                <div>{entry.value}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.TabItem}>
      <div className={styles.GraphItem}>
        <div className={styles.Header}>
          <div className={styles.HeaderPeriod}>
            <div className={styles.HeaderTitle}>{title}</div>
          </div>
        </div>

        <div className={styles.Graph}>
          <ResponsiveContainer>
            <ComposedChart
              layout={type === 'bars' ? 'vertical' : 'horizontal'}
              data={data}
              margin={{ left: 24, right: 24, top: 0, bottom: 0 }}
            >
              {type !== 'bars' && (
                <Legend
                  content={<GraphLegend payload={data} />}
                  verticalAlign="top"
                  wrapperStyle={{
                    top: 0,
                    left: 0,
                    width: '100%',
                  }}
                />
              )}
              <CartesianGrid strokeDasharray="0" />
              <XAxis
                dataKey={(item) => item[xAxis]}
                type={type === 'bars' ? 'number' : undefined}
                domain={
                  type === 'bars'
                    ? [
                        (dataMin: number) => dataMin - dataMin * 0.1,
                        (dataMax: number) => dataMax + dataMax * 0.1,
                      ]
                    : undefined
                }
                style={{ fontSize: 12 }}
              />
              {type !== 'lines' && (
                <>
                  {!yAxis && (
                    <YAxis
                      type={type === 'bars' ? 'category' : 'number'}
                      style={{ fontSize: 12 }}
                      domain={[
                        (dataMin: number) =>
                          dataMin +
                          (dataMin < 0 ? -1 : -1 * Math.abs(dataMin) * 0.5),
                        (dataMax: number) => dataMax + dataMax * 0.1,
                      ]}
                    />
                  )}
                  {yAxis && (
                    <YAxis
                      type={type === 'bars' ? 'category' : 'number'}
                      dataKey={(item) => item[yAxis]}
                      style={{ fontSize: 12 }}
                    />
                  )}
                </>
              )}
              <Tooltip wrapperStyle={{ fontSize: 14 }} />
              {children}
              {type === 'lines' &&
                dataKeys.map(({ key, label }, index) => {
                  const color = colorHash(label);

                  return (
                    <Line
                      strokeWidth={2}
                      key={index}
                      type="stepAfter"
                      dataKey={(data) => data[key]}
                      stroke={color}
                      dot={false}
                      name={label}
                    />
                  );
                })}
              {type === 'linear' &&
                dataKeys.map(({ key, label }, index) => {
                  const color = cellColor.brighter(index * 1.5);

                  function hashCode(s: string) {
                    for (var i = 0, h = 0; i < s.length; i++)
                      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
                    return h;
                  }

                  return (
                    <Fragment key={index}>
                      <Area
                        type="natural"
                        dataKey={(data) => data[key]}
                        strokeWidth={2}
                        fillOpacity={0.5}
                        fill={`url(#color${key}${hashCode(title)})`}
                        name={label}
                        stroke={color.formatHex()}
                      />
                      <defs>
                        <linearGradient
                          id={`color${key}${hashCode(title)}`}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset={'5%'}
                            stopColor={color.formatHex()}
                            stopOpacity={1}
                          />
                          <stop
                            offset="95%"
                            stopColor="#FFFFFF"
                            stopOpacity={1}
                          />
                        </linearGradient>
                      </defs>
                    </Fragment>
                  );
                })}
              {type === 'bars' && (
                <Bar
                  dataKey={(item) => item[xAxis]}
                  barSize={20}
                  radius={[0, 15, 15, 0]}
                >
                  {data &&
                    data.map((item, index) => {
                      const maxL = 100 - cellColor.l;
                      const step = maxL / data.length;
                      const newL = 100 - step * index;

                      return (
                        <Cell
                          key={index}
                          fill={
                            typeof cellColor !== 'string'
                              ? lch(newL, cellColor.c, cellColor.h).formatHex()
                              : cellColor
                          }
                        />
                      );
                    })}
                </Bar>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TabItem;
