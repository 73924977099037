import {lineString, round} from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import polygonSmooth from '@turf/polygon-smooth';
import { EventedProps } from '@react-leaflet/core';
import { Polygon, Marker, Tooltip, useMapEvents } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import ColorHash from 'color-hash';
import { StationType } from 'types';
import { DivIcon } from 'leaflet';
import styles from './index.module.scss';
import SensorMarker from '../SensorMarker';
import { useState } from 'react';
import {StationCommonInfoDto} from "../../../../../store/slices/dku";

const colorHash = new ColorHash({hue: {min: 49, max: 212}, saturation: 1, lightness: 0.47,});
//hsla(212, 100%, 47%, 1)синий
// hsla(49, 100%, 47%, 1) рыжий

function getRGB(c: string) {
  return parseInt(c, 16) || Number(c);
}

function getsRGB(c: string) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor: string) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}

function getContrast(f: string, b: string) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

function getTextColor(bgColor: string) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}

type StationPolygonProps<T> = {
  station: T;
  onsEvents?: EventedProps;
};

const StationPolygon = <T extends StationCommonInfoDto>({
  station,
  onsEvents,
}: StationPolygonProps<T>) => {
  const level = station.actual_level || 1;
  const active_level_sensor_id = station.active_level_sensor_id;
  const level_sensors = station.level_sensors;
  const min = level_sensors.find(sensor => sensor.level_sensor_id===active_level_sensor_id)?.min_level || 0;
  const max = level_sensors.find(sensor => sensor.level_sensor_id===active_level_sensor_id)?.max_level || 2;
  // hsla(212, 100%, 47%, 1)синий max
  // hsla(49, 100%, 47%, 1) желтый min

  const minH = 49;
  const maxH = 212;
  const sat = "100%";
  const l = "47%";
  const a = 1;
  const s1 = level - min; //1
  const len1 = max - min; //2
  const len2 = maxH - minH; //163
  // s1 / len1 = (x - minH)/len2
  const x = (s1/len1)*len2  + minH
  const color = `hsla(${x}, ${sat}, ${l}, ${a})`

  console.log("min", min, "max", max, "actual_level", level, "x",x, "stationId", station.station_id);
  const colorMain = colorHash.hex(color);
  console.log("color", color, "colorMain", colorMain, "station", station);

  const colorMainText = getTextColor(colorMain);
/*  const stationLine = lineString(station.borders);
  const polygonStation = lineToPolygon(stationLine);
  const smoothPoints = polygonSmooth(polygonStation.geometry, {
    iterations: 1,
  }).features[0].geometry.coordinates[0];*/

  const smoothPoints = station.borders;

  //const { title } = station;
  const title = `ОНС-${station.station_id}`
  const [isVisible, setIsVisible] = useState(true);
  const map = useMapEvents({
    zoomend: (e) => {
      if (e.target.getZoom() < 14) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    },
  });
  const [fillOpacity, setFillOpacity] = useState(0.6);

  return (
    <Polygon
      className={styles.polygon}
      pathOptions={{
        color,
        fillOpacity,
        opacity: 0,
        weight: 1,
      }}
      positions={smoothPoints as any}
      eventHandlers={{
        click: (e) => {
          if (map.getZoom() < 14) {
            map.fitBounds(e.sourceTarget.getBounds());
            setFillOpacity(0.6);
          }
        },
        mouseover: (e) => {
          if (map.getZoom() < 14) {
            setFillOpacity(0.8);
          }
        },
        mouseout: (e) => {
          setFillOpacity(0.6);
        },
      }}
    >
      {!isVisible && <Tooltip>{title}</Tooltip>}
      {isVisible && (
        <Marker
          {...onsEvents}
          eventHandlers={{...onsEvents?.eventHandlers,
            click: ()=>{
            if (typeof  onsEvents !=="undefined" &&
              typeof onsEvents.eventHandlers !== "undefined" &&
              typeof onsEvents.eventHandlers.click !== "undefined") {
                onsEvents.eventHandlers.click(station.station_id as any)
            }
          }}}
          //position={station.main.coords as any}
          position={station.coords as any}
          icon={
            new DivIcon({
              html: ReactDOMServer.renderToStaticMarkup(
                <div
                  className={styles.main}
                  style={{
                    backgroundColor: colorMain,
                    color: colorMainText,
                  }}
                >
                  {title}
                </div>
              ),
              iconAnchor: undefined,
              shadowUrl: undefined,
              shadowSize: undefined,
              shadowAnchor: undefined,
              iconSize: undefined,
              popupAnchor: [25, -5],
            })
          }
        />
      )}
      {station?.remote_level_sensors?.map((sensor, i) => (
        <SensorMarker key={i} sensor={{
          coords: sensor.coords,
          level: round(sensor.level_baltic_system, 2),
          sensor_target: sensor?.sensor_target,
          remote_level_sensor_id: sensor?.remote_level_sensor_id,
          sensor_type: sensor?.sensor_type,
          sensor_name: sensor?.sensor_name
        }} />
      ))}
    </Polygon>
  );
};

export default StationPolygon;
