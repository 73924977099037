export const dataFormatter = (archiveData: TArchive)=>{
  return  typeof archiveData !== "undefined" ? Object.entries(archiveData)?.map((el: any, key)=>{
    const date = new Date(el[0]);
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return [Date.UTC(year, month, day, hours, minutes, seconds), el[1]]
  }) : [];
}
type TArchive = {
  [key: string] : number
}

export const buttonTheme = { // styles for the buttons chart range selector
  fill: '#fff',
  stroke: '#D9D9D9',
  'stroke-width': 1,
  r: 2,
  style: {
    color: '#000',
    fontWeight: 'normal',
    fontFamily: 'Montserrat'
  },
  states: {
    hover: {
      fill: '#f2f2f2',
      style: {
        color: '#000'
      }
    },
    select: {
      fill: '#0099DE',
      style: {
        color: 'white',
        fontWeight: 'normal',
      }
    },
    disabled: {
      fill: '#E4E4E4',
      style: {
        color: '#ACACAC',
        fontWeight: 'normal',
      }
    }
  }
}