// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import styles from './AddUserPopup.module.scss';
import {Input, InputNumber, Modal} from 'antd';

import { Form, Select, Button } from 'antd';
import useNotification from '@hooks/useNotification';
import { UserDto } from 'store/slices/usersEndpoints';
import { useAdminUsersControllerRegisterMutation } from 'store/slices/adminEnhanced';
import { ApiError } from 'types';

const { Option } = Select;

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const AddUserPopup: React.FC<Props> = ({ visible, setVisible }) => {
  const [register] = useAdminUsersControllerRegisterMutation();
  const notificate = useNotification();

  const goNext = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (e) {}
  };

  // const goNext = async () => {
  //   try {
  //     await form.validateFields();
  //     form.submit();
  //   } catch (e) {}
  // };

  //логика формы
  const [form] = Form.useForm();

  const onFinish = async (values: UserDto) => {
    try {
      console.log('Received values of form: ', values);
      await register({ createUserDto: values }).unwrap();
      form.resetFields();
      setVisible(false);
      notificate('success', 'Пользователь успешно зарегистрирован');
    } catch (e) {
      const error = e as ApiError;
      if (typeof error?.data?.message !== "undefined" &&
        error.data.message?.length > 0
      ) {
        if (Array.isArray(error.data.message)){
          for (let i = 0; i < error.data.message?.length; i++) {
            notificate('error', error.data.message[i]);
          }
        }else{
          notificate('error', error.data.message);
        }
      }
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      +7
    </Form.Item>
  );

  return (
    <>
      <Modal
        title="Пригласить пользователя"
        centered
        visible={visible}
        onOk={goNext}
        onCancel={() => {form.resetFields(); setVisible(false)}}
        width={738}
        okText="Добавить"
        cancelText="Отмена"
      >
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            type: ['observer'],
            prefix: '7',
          }}
          scrollToFirstError
          className={styles.AddUserForm}
        >
          <div className={styles.LeftItems}>
            <Form.Item
              name="lastName"
              label="Фамилия"
              rules={[
                {
                  required: true,
                  message: 'Укажите фамилию',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="firstName"
              label="Имя"
              rules={[
                {
                  required: true,
                  message: 'Укажите имя',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="role"
              label="Тип учетной записи"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Select placeholder="Выберите тип">
                <Option value="admin">Администратор</Option>
                <Option value="observer">Наблюдатель</Option>
                <Option value="operator">Оператор</Option>
                <Option value="support">Техподдержка</Option>
                <Option value="api">Пользователь API</Option>
              </Select>
            </Form.Item>
          </div>

          <div className={styles.RightItems}>
            <Form.Item
              name="phone"
              label="Телефон"
              rules={[{ required: true, message: 'Введите номер телефона' }]}
              getValueFromEvent={(e) => {
                const value = e.target.value;
                return value.replace(/[^0-9.]/g, "");
              }}
            >
              <Input
                placeholder="996-606-53-23"
                addonBefore={prefixSelector}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Эл.почта"
              rules={[
                {
                  type: 'email',
                  message: 'Введен неверный E-mail!',
                },
                {
                  required: true,
                  message: 'Введите эл.почту',
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/*<Form.Item
              name="telegram"
              label="Telegram ID (опционально)"
              rules={[
                {
                  message: 'Укажите телеграм',
                  whitespace: true,
                },
              ]}
              getValueFromEvent={(e) => {
                const value = e.target.value;
                return value.replace(/[^0-9.]/g, "");
              }}
            >
              <Input />
            </Form.Item>*/}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddUserPopup;
