import Highcharts from 'highcharts/highstock'
import HighchartsReact from "highcharts-react-official";
import {buttonTheme, dataFormatter} from "@content/Analytics/Charts/helper";
import React, {useEffect, useState} from "react";
interface Props{
  archiveData: TArchive;
  color: string;
  title: string;
  textYAxis: string;
  enableLegend?: boolean;
}
type TArchive = {
  [key: string] : number
}


export default function ColumnsChart({archiveData, color, title, textYAxis, enableLegend}: Props){
  const dataChart = dataFormatter(archiveData);
  const  chartOptions = {
    series: [
      {
        data: dataChart,
        type: 'column',
        name: 'Посещений',
        color: color
      }
    ],
    scrollbar: { enabled: false },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        minutes: '%H:%M',
        seconds: '%H:%M:%S',
        millisecond: '%H:%M:%S',
      },
    },
    legend:{ enabled: typeof enableLegend !== "undefined" ? enableLegend : false},
    yAxis: {
      opposite: false,
      allowDecimals: false,
      minValue: 0,
      title: {
        text: textYAxis
      },
    },
    chart: {
      zoomType: 'x',
    },
    title: {
      text: title
    },
    navigator:{
      series: {
        lineColor: color
      }
    },
    tooltip:{
      borderColor: color,
      valueDecimals: 0,
    },
    plotOptions: {
      column: {
        /* Here is the setting to limit the maximum column width. */
        maxPointWidth: 20,
        minPointLength: 3,
      },
      series: {
        dataGrouping: {
          enabled: false,
        }
      },

    },
    hoverData: null,
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    rangeSelector: {
      selected: 1,
      buttons: [
        {
          type: 'week',
          count: 1,
          text: '1н',
        },
        {
          type: 'month',
          count: 1,
          text: '1м',
        },
        {
          type: 'month',
          count: 3,
          text: '3м'
        },
        {
          type: 'year',
          count: 1,
          text: '1г'
        }, {
          type: 'all',
          text: 'Все'
        }],
      buttonTheme: buttonTheme
    },

  };
  const [chart, setChart] = useState({} as any);
  useEffect(()=>{
    try {
      chart?.reflow();
    }catch(err){
      // console.error("CATCH reflow", err)
    }
  },[chart])

  return(
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={chartOptions}
      callback={(chart: any)=>{
        setChart(chart)
      }}
    />
  )
}


export const dataChart2 = [
  [Date.UTC(2021, 9, 24), 0],
  [Date.UTC(2021, 9, 27), 1],
  [Date.UTC(2021, 9, 30), 1],
  [Date.UTC(2021, 10,  3), 2],
  [Date.UTC(2021, 10,  6), 1],
  [Date.UTC(2021, 10,  9), 3],
  [Date.UTC(2021, 10, 12), 0],
  [Date.UTC(2021, 10, 15), 0],
  [Date.UTC(2021, 10, 18), 1],
  [Date.UTC(2021, 10, 21), 1],
  [Date.UTC(2021, 10, 24), 1],
  [Date.UTC(2021, 10, 27), 1],
  [Date.UTC(2021, 10, 30), 5],
  [Date.UTC(2021, 11,  3), 2],
  [Date.UTC(2021, 11,  6), 2],
  [Date.UTC(2021, 11,  9), 1],
  [Date.UTC(2021, 11, 12), 3],
  [Date.UTC(2021, 11, 15), 1],
  [Date.UTC(2021, 11, 18), 3],
  [Date.UTC(2021, 11, 21), 1],
  [Date.UTC(2021, 11, 24), 1],
  [Date.UTC(2021, 11, 27), 1],
  [Date.UTC(2021, 11, 30), 2],
  [Date.UTC(2022, 0,  3), 1],
  [Date.UTC(2022, 0,  6), 2],
  [Date.UTC(2022, 0,  9), 3],
  [Date.UTC(2022, 0, 12), 4],
  [Date.UTC(2022, 0, 15), 5],
  [Date.UTC(2022, 0, 18), 5],
  [Date.UTC(2022, 0, 21), 5],
  [Date.UTC(2022, 0, 24), 1],
  [Date.UTC(2022, 0, 27), 1],
  [Date.UTC(2022, 0, 30), 2],
  [Date.UTC(2022, 1,  3), 2],
  [Date.UTC(2022, 1,  6), 3],
  [Date.UTC(2022, 1,  9), 1],
  [Date.UTC(2022, 1, 12), 0],
  [Date.UTC(2022, 1, 15), 1],
  [Date.UTC(2022, 1, 18), 2],
  [Date.UTC(2022, 1, 21), 2],
  [Date.UTC(2022, 1, 24), 2],
  [Date.UTC(2022, 1, 27), 2],
  [Date.UTC(2022, 2,  3), 2],
  [Date.UTC(2022, 2,  6), 2],
  [Date.UTC(2022, 2,  9), 2],
  [Date.UTC(2022, 2, 13), 3],
  [Date.UTC(2022, 2, 20), 3],
  [Date.UTC(2022, 2, 27), 2],
  [Date.UTC(2022, 2, 30), 2],
  [Date.UTC(2022, 3,  4), 1],
  [Date.UTC(2022, 3,  9), 1],
  [Date.UTC(2022, 3, 12), 1],
  [Date.UTC(2022, 3, 15), 1],
  [Date.UTC(2022, 3, 18), 2],
  [Date.UTC(2022, 3, 21), 2],
  [Date.UTC(2022, 3, 24), 2],
  [Date.UTC(2022, 3, 27), 5],
  [Date.UTC(2022, 3, 30), 2],
  [Date.UTC(2022, 4,  3), 3],
  [Date.UTC(2022, 4,  6), 4],
  [Date.UTC(2022, 4,  9), 5],
  [Date.UTC(2022, 4, 12), 6],
  [Date.UTC(2022, 4, 15), 7],
  [Date.UTC(2022, 4, 18), 4],
  [Date.UTC(2022, 4, 21), 2],
  [Date.UTC(2022, 4, 24), 2],
  [Date.UTC(2022, 4, 27), 2],
  [Date.UTC(2022, 4, 30), 1],
  [Date.UTC(2022, 5,  2), 1],
  [Date.UTC(2022, 5,  6), 1],
  [Date.UTC(2022, 5,  9), 3],
  [Date.UTC(2022, 5, 12), 2],
  [Date.UTC(2022, 5, 15), 0],
  [Date.UTC(2022, 5, 18), 1],
  [Date.UTC(2022, 5, 21), 1],
  [Date.UTC(2022, 5, 23), 1]
]