import TabItem from '@content/Analytics/TabItem/TabItem';
import { CartesianGrid, XAxis, YAxis, Area, Tooltip } from 'recharts';

type ItemType = {
  name: string;
  x: number;
};

const data: ItemType[] = [
  {
    name: 'Н-1',
    x: 4000,
  },
  {
    name: 'Н-2',
    x: 3000,
  },
  {
    name: 'Н-3',
    x: 2000,
  },
  {
    name: 'Н-4',
    x: 2780,
  },
  {
    name: 'Н-5',
    x: 2240,
  },
];

const PumpGraph = ({data}: any) => {
  return (
    <TabItem
      tabs={[
        { label: '1 час', value: 'hour' },
        { label: '1 неделя', value: 'week' },
        { label: '1 месяц', value: 'month' },
      ]}
      onTabChange={(value: string) => {
        console.log(value);
      }}
      title="Наработка насосов, (ч)"
      data={data}
      type="bars"
      options={{ xAxis: 'x', yAxis: 'name' }}
      color="#FA541C"
      dataKeys={[{ key: 'x', label: 'Текущий' }]}
    />
  );
};

export default PumpGraph;
