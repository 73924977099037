import {dkuEndpoints} from "./dku";

export const dkuEnhanced = dkuEndpoints.enhanceEndpoints({
  addTagTypes: ['Station', 'StationSettings'],
  endpoints: {
    dkuControllerGetStation: {
      providesTags: (_result, _error, arg) => {
        return [{ type: 'Station', id: arg.stationId }]},
    },
    dkuControllerPostStationAction: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Station', id: arg.stationId }],
    },
    dkuControllerGetStationSettings: {
      providesTags: (_result, _error, arg) => {
        return [{ type: 'StationSettings', id: arg.stationId }]},
    },
    dkuControllerPostStationSettingsAction: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'StationSettings', id: arg.stationId }],
    }
  },
});

export const {
  useDkuControllerGetStationQuery,
  useLazyDkuControllerGetStationQuery,
  useLazyDkuControllerGetStationSettingsQuery,
  useDkuControllerPostStationActionMutation,
  useDkuControllerPostStationSettingsActionMutation,
  useLazyDkuControllerGetReportQuery,
  useLazyDkuControllerGetArchiveDataQuery
} = dkuEnhanced;
