import styles from '../../TabItem/TabItem.module.scss';
import { MoreOutlined } from '@ant-design/icons';
import {capitalizeFirstLetter} from "../../../../../utils/common";
import {computedColorMode, computedColorOnsState} from "../../../../../utils/computedColor";
import React from "react";

interface Props {
  name: string;
  location: string;
  state: string;
  count: number;
  operatingMode: string;
}
const StationInfo = ({name, location, state, count, operatingMode}: Props) => {
  return (
    <div className={styles.TabItem}>
      <div className={`${styles.GraphItem} ${styles.GraphItemMain}`}>
        <div className={styles.Header}>
          <div className={styles.HeaderTitle}>{name}</div>
        </div>

        <div className={styles.Content}>
          <div className={styles.ContentItem}>
            <div className={styles.Property}>Расположение</div>
            <div className={styles.Value}>
              {location}
            </div>
          </div>
          <div className={styles.ContentItem}>
            <div className={styles.Property}>Режим работы</div>
            <div className={styles.Value}>
                <span
                  className={`${styles.ItemDot} ${computedColorMode(operatingMode)}`}
                />
              {capitalizeFirstLetter(operatingMode)}
            </div>
          </div>
          <div className={styles.ContentItem}>
            <div className={styles.Property}>Количество посещений</div>
            <div className={styles.Value}>{count}</div>
          </div>
          <div className={styles.ContentItem}>
            <div className={styles.Property}>Состояние ОНС</div>
            <div className={styles.Value}>
               <span
                 className={`${styles.ItemDot} ${computedColorOnsState(state)}`}
               />
              {capitalizeFirstLetter(state)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationInfo;
