import React, {useEffect, useMemo, useState} from 'react';
import styles from './CreateUserGroupPopup.module.scss';
import {Button, Input, Modal, notification, Pagination, Radio, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NotificationInstance } from 'antd/lib/notification';
import useNotification from '@hooks/useNotification';
import { Key } from 'antd/es/table/interface';
import {
  useAdminUsersControllerGetAllUsersQuery,
  useAdminUsersControllerGetAllSystemEventsNamesQuery,
  useAdminUsersControllerCreateGroupMutation,
  useAdminUsersControllerGetUsersNotInGroupQuery,
  useAdminUsersControllerEditGroupMutation,
  useAdminUsersControllerDeleteGroupMutation, useAdminUsersControllerGetAllUsersIdsQuery
} from "../../../store/slices/adminEnhanced";
import {GroupDto, UserDto} from "../../../store/slices/adminEndpoints";
import {RoleToText} from "../../../pages/Alerts/Alerts";
import {useSearchParams} from "react-router-dom";
import RoleBasedView from "../../../utils/RoleBasedView";
import {UserRole} from "../../../types";
import {ExclamationCircleOutlined} from "@ant-design/icons";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  id: number;
  data: GroupDto,
}


const columnsAlerts = [
  {
    title: 'Тип оповещения',
    dataIndex: 'name',
  },
];

const columnsUsers: ColumnsType<UserDto> = [
  {
    title: 'Пользователь',
    dataIndex: 'name',
    render: (title, row) => <span className={styles.Name}>{row.firstName} {row.lastName}</span>,
  },
  {
    title: 'Тип учетной записи',
    dataIndex: 'role',
    render: (role)=><span className={styles.Role}>{RoleToText(role)}</span>
  },
];

const EditUserGroupPopup: React.FC<Props> = ({ visible, setVisible, id, data }) => {
  console.log("EditUserGroupPopup id", id, data);
  const [pageMembers, setPageMembers] = useState<number>(1);
  const [pageUsers, setPageUsers] = useState<number>(1);
  const {data: events} = useAdminUsersControllerGetAllSystemEventsNamesQuery("");

  const {data: usersNotInGroup} = useAdminUsersControllerGetUsersNotInGroupQuery({id, page: pageUsers});

  const allUsersIds = usersNotInGroup?.result?.items ? usersNotInGroup?.result?.items?.map((user: any)=>{
    return user.id
  }) : [];
  const allEventsIds = events?.result ? events.result.map((evt: any)=>evt.id) : [];

  const [members, setMembers] = useState(data.users); //тк будут удаляться
  const users = usersNotInGroup?.result?.items as UserDto[];

  const [editGroup] = useAdminUsersControllerEditGroupMutation();
  const [deleteGroup] = useAdminUsersControllerDeleteGroupMutation();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<Key[]>([]); //usersNotInGroup (users)
  const [selectedRowKeysTypes, setSelectedRowKeysTypes] = React.useState<Key[]>(data.event_names.map(el=>el.id));

  const finalSelectedUsers = useMemo(()=>{
    if (typeof members!=="undefined") {
      return [...selectedRowKeys, ...members?.map(el => el.id)];
    }
    return selectedRowKeys //не должно до сюда доходить

  },[members, selectedRowKeys])

  const [title, setTitle] = React.useState(data?.name);

  const notificate = useNotification();

  const [valueTab, setValueTab] = useState('members');

  const onConfirmDeleteGroup = async ()=>{
    deleteGroup({id}).unwrap().then(fulfilled => {
      console.log("fullfiled================================",fulfilled);
      if (fulfilled?.status == "OK"){
        setVisible(false);
        notificate("success", "Группа пользователей удалена");
      }else{
        throw new Error("");
      }
    }).catch(rejected => {
      console.error("rejected================================", rejected)
      setVisible(false);
      notificate("error", "Не получилось удалить группу пользователей");
    })
  }
  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: '  Вы уверены, что хотите удалить группу?',
      icon: <ExclamationCircleOutlined />,
      content: 'Все сведения о ней будут удалены',
      okText: "Удалить",
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        onConfirmDeleteGroup();
      },
      onCancel() {},
    });
  };

  const tabChange = (value: any, query?: string) => {
    console.log("tabChange", value);
    setValueTab( value.target.value);
  };

  console.log("usersNotInGroup",usersNotInGroup);
  const onSelectChange = (selectedRowKeys: Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const onSelectTypesChange = (selectedRowKeysTypes: Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeysTypes(selectedRowKeysTypes);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: (val: any)=>{
      console.log("onSelectAll",val, allUsersIds);
      if (val){
        setTimeout(()=>{setSelectedRowKeys(allUsersIds);}, 100)
      }else{
        setTimeout(()=>{setSelectedRowKeys([]);}, 100)
      }
    },
    preserveSelectedRowKeys: true
  };
  const rowSelectionTypes = {
    selectedRowKeys: selectedRowKeysTypes,
    onChange: onSelectTypesChange,
    preserveSelectedRowKeys: true,
    onSelectAll: (val: any)=>{
      if (val){
        setTimeout(()=>{setSelectedRowKeysTypes(allEventsIds);}, 100)
      }else{
        setTimeout(()=>{setSelectedRowKeysTypes([]);}, 100)
      }
    },
  };

  const clearAndClose = ()=>{
    setVisible(false);
  }
  const onSubmit=()=>{
    editGroup({
      id: id,
      data: {
        name: title,
        users: finalSelectedUsers?.map((el: Key) => {
          return {id: +el}
        }),
        event_names: selectedRowKeysTypes?.map((el: Key) => {
          return {id: +el}
        }),
      }
    }).unwrap().then(fulfilled => {
      console.log("fullfiled================================",fulfilled);
      notificate("success", "Группа пользователей обновлена");
      clearAndClose();
    }).catch(rejected => {
      console.error("rejected================================", rejected)
      notificate("error", "Не получилось обновить группу пользователей");
    })
  }

  const onDeleteGroup = ()=>{
    showConfirm();
  }
  const onDeleteMember = (id: number)=>{
    if (typeof members !== "undefined") {
      const idx = members.findIndex((el) => el.id == id);
      console.log("idx===", idx);
      if (idx>=0){
        const newMembers = [...members.slice(0, idx), ...members.slice(idx+1)];
        setMembers(newMembers)
      }
    }
  }

  const columnsMembers: ColumnsType<UserDto> = [
    {
      title: 'Пользователь',
      dataIndex: 'name',
      render: (title, row) => <span className={styles.Name}>{row.firstName} {row.lastName}</span>,
    },
    {
      title: 'Тип учетной записи',
      dataIndex: 'role',
      render: (role)=><span className={styles.Role}>{RoleToText(role)}</span>
    },
    {
      title: '',
      dataIndex: "delete",
      render: (_, row)=><div className={styles.Delete} onClick={()=>{onDeleteMember(row.id)}}>Удалить</div>
    }
  ];



  return (
    <>
      <Modal
        title="Редактировать"
        centered
        visible={visible}
        onOk={onSubmit}
        onCancel={clearAndClose}
        width={815}
        okText={"Сохранить"}
        cancelText="Отмена"
        footer={
          <div className={styles.RowFooter}>
            <Button key="delete" className={styles.DeleteButton} onClick={onDeleteGroup}>
              Удалить группу
            </Button>
            <Button
              key="cancel"
              onClick={clearAndClose}
            >
              Отмена
            </Button>
            <Button key="submit" type="primary"  onClick={onSubmit}>
              Сохранить
            </Button>
          </div>
        }
      >

        <div className={styles.NameGroup}>Название группы</div>
        <Input
          placeholder="Ведите название группы"
          className={styles.NameGroupInput}
          value={title}
          onChange={(e)=>{setTitle(e.target.value)}}
        />

        <div className={styles.RowTabs}>
          <Radio.Group
            defaultValue={'members'}
            buttonStyle="solid"
            onChange={tabChange}
            value={valueTab}
          >
            <Radio.Button value="members" id="1">
              Участники
            </Radio.Button>
            <Radio.Button value="users" id="2">
              Добавить участников
            </Radio.Button>
            <Radio.Button value="alerts" id="3">
              Редактировать оповещения
            </Radio.Button>
          </Radio.Group>
        </div>

        {valueTab === "members" && (
          <>
            <div className={styles.Members}>Участники</div>
            <Table
              rowKey={(el)=>{return el.id}}
              columns={columnsMembers}
              dataSource={members}
              pagination={{
                onChange: (page) => {
                  setPageMembers(page);
                },
                current: pageMembers,
                total: members?.length,
              }}
            />
          </>
        ) }
        {valueTab === "users" && (
          <>
            <div className={styles.Members}>Участники</div>
            <Table
              rowSelection={rowSelection}
              rowKey={(el)=>{return el.id}}
              columns={columnsUsers}
              dataSource={users}
              pagination={{
                onChange: (page) => {
                  setPageUsers(page);
                },
                current: pageUsers,
                total: usersNotInGroup?.result?.totalItems,
              }}
            />
          </>
        ) }
        {valueTab === "alerts" ? (
          <>
            <div className={styles.Members}>Типы оповещений </div>
            <Table
              rowSelection={rowSelectionTypes}
              rowKey={(el)=>{return el.id}}
              columns={columnsAlerts}
              dataSource={events?.result as any}
              pagination={{
                total: events?.result?.length,
              }}
            />
          </>
        ) : null}

      </Modal>
    </>
  );
};

export { EditUserGroupPopup };
