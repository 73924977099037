import React from 'react';
import { BaseIcon } from '@base/index';
import { ALL_ICONS } from '@constants/icons';
import styles from './Login.module.scss';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  LoginRequestDto,
  useAuthControllerLoginMutation,
} from 'store/slices/authEndpoints';
import { useAppDispatch } from 'store';
import { getFingerprint } from 'store/helpers';
import {UserDto, userEndpoints, useLazyUsersControllerGetQuery} from 'store/slices/userEndpoints';
import { setCredentials, setUser } from 'store/slices/authSlice';
import useNotification from '@hooks/useNotification';
import { ApiError } from 'types';
import useWindowWidth from '@hooks/useWindowWidth';

const Login: React.FC = () => {
  const notificate = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auth] = useAuthControllerLoginMutation();
  const [getUser] = useLazyUsersControllerGetQuery();
  const [form] = Form.useForm();
  const onFinish = async (
    values: Pick<LoginRequestDto, 'email' | 'password'> & { remember: boolean }
  ) => {
    console.log('Received values of form: ', values);

    const fingerprint = await getFingerprint();

    try {
      const credentials = await auth({
        loginRequestDto: {
          email: values.email,
          password: values.password,
          fingerprint,
        },
      }).unwrap();

      if (credentials.result) {
        dispatch(setCredentials({ ...credentials.result }));
        getUser().unwrap().then(res=>{
          console.log("res getUser", res);
          if (res) {
            if (res?.status === 'OK'){
              dispatch(setUser(res?.result as UserDto));
              navigate('/management/map');
            }
          }
        }).catch(err=>console.error(err));

      }

      console.log('credentials', credentials);
    } catch (e) {
      const error = e as ApiError;
      notificate('error', error.data.message);
      form.resetFields();
    }
  };

  const loginHandler = () => {
    // navigate('/management');
  };

  const passwordRecoveryHandler = () => {
    navigate('/password_recovery');
  };

  return (
    <div className={styles.Login}>
      <BaseIcon
        icon={ALL_ICONS.LOGO}
        viewBox="0 0 43 43"
        className={styles.IconLogo}
      />
      <div className={styles.Title}>Мелиорация</div>
      <div className={styles.Subtitle}>управление системами</div>
      <div className={styles.Description}>
        Панель управления мелиоративной системой Калининградской области
      </div>

      <div className={styles.Form}>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Введите логин' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Введите логин"
              className={styles.Input}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Введите пароль' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Введите пароль"
              className={styles.Input}
            />
          </Form.Item>
          <div className={styles.Actions}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Запомнить меня</Checkbox>
            </Form.Item>

            <span onClick={passwordRecoveryHandler}>
              <Link className={styles.LoginForgot} to="">
                Забыли пароль?
              </Link>
            </span>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.LoginButton}
              onClick={loginHandler}
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>

      <BaseIcon
        icon={ALL_ICONS.WAVE}
        viewBox="0 0 1440 250"
        className={styles.Wave}
      />

      <BaseIcon
        icon={ALL_ICONS.WAVE_TABLET}
        viewBox="0 0 768 301"
        className={styles.WaveTablet}
      />
    </div>
  );
};

export default Login;
