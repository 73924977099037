import { notification } from 'antd';
import { NotificationInstance } from 'antd/lib/notification';

const useNotification = () => {
  const notificate = (type: keyof NotificationInstance, text: string) => {
    notification[type]({
      message: text,
    });
  };

  return notificate;
};

export default useNotification;
