import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';

const initialState: Partial<{ position: [number, number]; zoom: number }> = {
  position: JSON.parse(localStorage.getItem('mapPosition') || 'null'),
  zoom: JSON.parse(localStorage.getItem('mapZoom') || 'null'),
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapPosition: (
      state,
      action: PayloadAction<{ position: [number, number] }>
    ) => {
      localStorage.setItem(
        'mapPosition',
        JSON.stringify(action.payload.position)
      );
      state.position = action.payload.position;
    },
    setMapZoom: (state, action: PayloadAction<{ zoom: number }>) => {
      localStorage.setItem('mapZoom', action.payload.zoom.toString());
      state.zoom = action.payload.zoom;
    },
    clearMapOptions: (state) => {
      state.position = undefined;
      state.zoom = undefined;
    },
  },
});

export const { setMapPosition, setMapZoom, clearMapOptions } = mapSlice.actions;

export default mapSlice.reducer;

export const selectMapPosition = (state: RootState) => state.map.position;
export const selectMapZoom = (state: RootState) => state.map.zoom;
