//@ts-nocheck
import { Button, DatePicker, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  UserEventDto,
  UserEventFilterDto,
  UserEventSortingDto,
} from 'store/slices/adminEndpoints';
import { useAdminEventsControllerGetUserEventsQuery } from 'store/slices/adminEnhanced';
import { camelToSnakeCase, getColumnSearchProps } from 'utils';
import {stationsIdsToColor} from "../../../utils/common";
import styles from '../EventLog.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import JSONPretty from "react-json-pretty";
import {useDkuControllerGetStationsCommonInfoQuery} from "../../../store/slices/dku";
import {PresetColorTypes} from "antd/lib/_util/colors";
import {selectNewNotices} from "../../../store/slices/navbarSlice";
import {useAppSelector} from "../../../store";

const { RangePicker } = DatePicker;

const UserEvents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  useEffect(() => {
    if (!page) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, []);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<
    SorterResult<UserEventDto> | SorterResult<UserEventDto>[]
  >({});
  const [filter, setFilter] = useState<UserEventFilterDto[]>([]);
  const [sorting, setSorting] = useState<UserEventSortingDto[]>([]);

  const { data, refetch } = useAdminEventsControllerGetUserEventsQuery({
    getUserEventsDto: { limit: 10, filter, sorting, page },
  }, {skip : page === 0});
  const refetchInterval = async () => {
    try {
      await refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(refetchInterval , 10000);
    }
  };
  useEffect(()=>{
    refetchInterval();
  }, []);
  const newNotices = useAppSelector(selectNewNotices);
  useEffect(()=>{
    console.log("refetch new notices user events", newNotices);
    refetch();
  },[newNotices])

  const navigate = useNavigate();

  const {data: stationsData} = useDkuControllerGetStationsCommonInfoQuery();
  const stationsIds = stationsData?.result?.map(el=>el.station_id);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorting: SorterResult<UserEventDto> | SorterResult<UserEventDto>[]
  ) => {
    console.log('Various parameters', pagination, filters, sorting);
    setFilteredInfo(filters);
    const filterParams = [
      ...Object.entries(filters)
        .filter(([_, value]) => value)
        .reduce((acc, [field, search]) => {
          if (!Array.isArray(search)) {
            acc.push({
              field: camelToSnakeCase(field),
              search: search || undefined,
            } as UserEventFilterDto);
            return acc;
          }

          const multipleFields = search.map(
            (label) =>
              ({
                field: camelToSnakeCase(field),
                search: label as unknown,
              } as UserEventFilterDto)
          );
          acc.push(...multipleFields);

          return acc;
        }, [] as Array<UserEventFilterDto>),
    ];
    setFilter(filterParams);
    setSortedInfo(sorting);

    const sortingParams = (
      Array.isArray(sorting)
        ? sorting.map(({ columnKey: sort, order }) => ({
            sort: sort && camelToSnakeCase(sort as string),
            order: order && order === 'ascend' ? 'ASC' : 'DESC',
          }))
        : [
            {
              sort:
                sorting.columnKey &&
                camelToSnakeCase(sorting.columnKey as string),
              order: sorting.order
                ? sorting.order === 'ascend'
                  ? 'ASC'
                  : 'DESC'
                : undefined,
            },
          ]
    ).filter(({ sort, order }) => sort && order);
    setSorting([...(sortingParams as UserEventSortingDto[])]);

    const url = new URLSearchParams();
    url.append('page', pagination.current?.toString() || '1');
    for (const { field, search } of filterParams) {
      if (field && search) {
        url.append(field, search);
      }
    }
    for (const { sort, order } of sortingParams) {
      if (sort && order) {
        url.append(sort, order);
      }
    }
    navigate('?' + url.toString(), { replace: true });
  };

  const location = useLocation();
  useEffect(() => {
    const filterParams: UserEventFilterDto[] = [];
    const sortingParams: UserEventSortingDto[] = [];

    for (const key of searchParams.entries()) {
      if (
        key[0] === 'date' ||
        key[0] === 'name' ||
        key[0] === 'action' ||
        key[0] === 'parameter'
      ) {
        if (key[1] !== 'ASC' && key[1] !== 'DESC') {
          filterParams.push({
            field: key[0],
            search: key[1],
          });
        }
      }
      if (key[0] === 'date' || key[0] === 'name') {
        if (key[1] === 'ASC' || key[1] === 'DESC') {
          sortingParams.push({
            sort: key[0],
            order: key[1],
          });
        }
      }
    }

    setFilter(filterParams);
    setSorting(sortingParams);
  }, [location]);

  const handleSearch = (
    selectedKeys: any[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const BeforeAfterRender = (value, stationsIds)=>{
    return( <div>
      {Array.isArray(value) && value && value?.length > 0 && value?.map((el, key)=>{
        if (el?.field == "Список ОНС"){
          return <div> Доступные ОНС:&nbsp;&nbsp; {el?.value?.map(( tag) => {
            console.log("TAG===", tag);
            return (
              <Tag color={PresetColorTypes[stationsIdsToColor(stationsIds, tag)]} key={tag}>
                {`ОНС-${tag}`}
              </Tag>
            );
          })}
            {el?.value?.length === 0 && "-"}
          </div>
        }
        return <div key={key}>
          <div>{el?.field} : {typeof el?.value === "boolean" ? (el?.value  ? "Включено" : "Выключено"): el?.value}</div>
        </div>
      })}
    </div>)
  }

  //таблица пользователей
  const columnsUsers: ColumnsType<UserEventDto> = [
    {
      title: 'Дата и время',
      dataIndex: 'date',
      key: 'date',
      width: 186,
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'date' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      sortOrder: sorting.find(({ sort }) => sort === 'date')
        ? sorting.find(({ sort }) => sort === 'date')?.order === 'ASC'
          ? 'ascend'
          : 'descend'
        : undefined,
      sorter: (a, b) => {
        return 0;
      },
      ellipsis: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const startDate =
          selectedKeys[0] &&
          moment((selectedKeys[0] as string).split(',')[0]).isValid()
            ? moment((selectedKeys[0] as string).split(',')[0])
            : null;
        const endDate =
          selectedKeys[0] &&
          moment((selectedKeys[0] as string).split(',')[1]).isValid()
            ? moment((selectedKeys[0] as string).split(',')[1])
            : null;

        return (
          <div className={styles.RangePickerContainer}>
            <RangePicker
              onCalendarChange={(_date, dateString) => {
                setSelectedKeys([dateString.toString()]);
              }}
              value={[startDate, endDate]}
              className={styles.RangePicker}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Поиск
              </Button>
              <Button
                onClick={() => {
                  console.log("СБРОСИТЬ");
                  if (clearFilters) {
                    clearFilters();
                  }
                }}
                size="small"
                style={{ width: 90 }}
              >
                Сбросить
              </Button>
            </Space>
          </div>
        );
      },
      render: (data) => (
        <span className={styles.Name}>
          {new Date(data).toLocaleDateString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
          {' • '}
          {new Date(data).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </span>
      ),
    },
    {
      title: 'ФИО',
      dataIndex: 'firstName',
      key: 'name',
      width: 272,
      filteredValue: filter.reduce((acc, value) => {
        console.log("acc=",acc, "value=", value);
        if (value.field === 'name' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      sortOrder: sorting.find(({ sort }) => sort === 'name')
        ? sorting.find(({ sort }) => sort === 'name')?.order === 'ASC'
          ? 'ascend'
          : 'descend'
        : undefined,
      sorter: (a, b) => {
        return 0;
      },
      ellipsis: true,
      render: (firstName, row) => {
        console.log("firstaname",firstName);
        return <span className={styles.Name}>{firstName} {row.lastName}</span>},
      ...getColumnSearchProps('name', 'Имя', handleSearch, handleReset),
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      width: 289,
      filters: [
        { text: 'Обновление данных пользователя', value: 'Обновление данных пользователя' },
        { text: 'Задание сценария работы ОНС', value: 'Задание сценария работы ОНС' },
        { text: 'Отправка команды на ОНС', value: 'Отправка команды на ОНС' },
        { text: 'Аутентификация', value: 'Аутентификация' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'action' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
    },
    {
      title: 'Параметр',
      dataIndex: 'parameter',
      key: 'parameter',
      width: 189,
     /* filters: [
        { text: 'Активен', value: 'Активен' },
        { text: 'Неактивен', value: 'Неактивен' },
        { text: 'Удален', value: 'Удален' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'parameter' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),*/
      ellipsis: false,
    },
    {
      title: 'Значение до',
      dataIndex: 'valueBefore',
      key: 'valueBefore',
      width: 189,
      ellipsis: false,
      render: (value: string | string[] | any[] | null, row) => {
        console.log("value777!!!!!", row.parameter, ":=", value);
        let  valueBefore = value;
        try{
         valueBefore =  typeof value === "string" ? JSON.parse(value) : value;
        }
        catch(err){
          console.log("CATCH err777", row.parameter, value);
        }
        console.log("valueBefore7772", row.parameter, ":=", valueBefore);
        return BeforeAfterRender(valueBefore, stationsIds);
      },
    },
    {
      title: 'Значение после',
      dataIndex: 'valueAfter',
      key: 'valueAfter',
      width: 189,
      render: (value: any) => {
        console.log("value!!!!!", value);
        let  valueAfter = value as any;
        try{
          valueAfter = typeof value === "string" ? JSON.parse(value) : value;
        }
        catch(err){
          console.log("CATCH err", value)
        }
        console.log("valueAfter", value, "valueAfter?.[0]?.field", valueAfter?.[0]?.field, Array.isArray(valueAfter) && valueAfter?.[0]?.field == "Список ОНС");
        return BeforeAfterRender(valueAfter, stationsIds);
      },
    },
  ];

  return (
    <Table
      columns={columnsUsers}
      dataSource={data?.result?.items}
      onChange={handleChange}
      scroll={{ x: 1500 }}
      className="UsersTable"
      pagination={{
        onChange: (page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        },
        current: data?.result?.currentPage,
        total: data?.result?.totalItems,
      }}
    />
  );
};

export default UserEvents;
