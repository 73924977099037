import { DateTime } from 'luxon';
import {useRef, useEffect} from 'react';

export function capitalizeFirstLetter(string: string | undefined | null) {
  if (typeof string === "undefined" || string == null || string?.length <=0){
    return ""
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function formatDateTime(date: string) {
  if (typeof date === "undefined" || date?.length === 0){
    return "-"
  }
  //@ts-ignore
  if (DateTime.fromISO(date)?.invalid){
    return "-"
  }
  console.log("DateTime.fromISO(date)", DateTime.fromISO(date)?.isValid)
  return `${DateTime.fromISO(date).setLocale('ru-RU').toFormat('dd.LL.yyyy')} • ${DateTime.fromISO(date).setLocale('ru-RU').toFormat('HH:mm:ss')}`;
}
export function formatDateTimeNotification(date: string) {
  console.log("formatDateTimeNotification", date);
  if (typeof date === "undefined" || date?.length === 0){
    return ""
  }
  //@ts-ignore
  if (DateTime.fromISO(date)?.invalid){
    return ""
  }
  const dateFull = DateTime.fromISO(date);
  const today = DateTime.now();
  const isToday = today.startOf("day").equals(dateFull.startOf("day"));
  if (isToday){
    return `${DateTime.fromISO(date).setLocale('ru-RU').toFormat("HH':'mm")}`
  }
  return `${DateTime.fromISO(date).setLocale('ru-RU').toFormat('dd.LL.yyyy')} в ${DateTime.fromISO(date).setLocale('ru-RU').toFormat("HH':'mm")}`;
}
export function usePrevious<T>(value: T): T {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as T;
}

export const stationsIdsToColor = (ids: undefined | string [], tag: string)=>{
  if (typeof ids === "undefined"){
    return 1;
  }
  const idTag = tag;
  const pos = ids.findIndex(el=>el == idTag);
  return pos || 1;
}


export const arrayClone = (arr : any[] | any) => {
  let i, copy;
  if (Array.isArray(arr)) {
    copy = arr.slice(0);
    for (i = 0; i < copy.length; i++) {
      copy[i] = arrayClone(copy[i]);
    }
    return copy;
  } else if (typeof arr === 'object') {
    let obj = {};
    for (let key in arr) {
      if (arr.hasOwnProperty(key)) {
        if ((Array.isArray(arr[key])) || (typeof arr[key] === 'object')) {
          obj = {...obj, [key]: arrayClone(arr[key])}
        } else {
          obj = {...obj, [key]: arr[key]}
        }
      }
    }
    return obj;
  } else {
    return arr;
  }
};