import React, {ReactNode} from 'react';
import styles from './Default.module.scss';

import {Layout, Menu} from 'antd';
import {MainHeader} from '@nav/index';
import {
  BarChartOutlined,
  BookOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  PoweroffOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {Link, matchPath, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store';
import {selectCollapsed, toggle} from 'store/slices/navbarSlice';
import {UserRole} from 'types';
import useUser from '@hooks/useUser';

const { Header, Sider, Content } = Layout;

const links = [
  {
    icon: <PoweroffOutlined />,
    title: 'Контроль и управление',
    value: '/management/map',
  },
  {
    icon: <BarChartOutlined />,
    title: 'Аналитика и отчетность',
    value: '/analytics',
    excludeRoles: [UserRole.observer],
  },
  {
    icon: <TeamOutlined />,
    title: 'Пользователи и доступ',
    value: '/users',
    excludeRoles: [UserRole.observer, UserRole.support, UserRole.operator],
  },
  { icon: <NotificationOutlined />, title: 'Оповещения', value: '/alerts' },
  {
    icon: <BookOutlined />,
    title: 'Журнал событий',
    value: '/event-log/users',
  },
];

interface Props {
  children: ReactNode | ReactNode[];
}

const Default: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const collapsed = useAppSelector(selectCollapsed);
  const dispatch = useAppDispatch();
  const user = useUser();

  console.log("location", location);
  const onMenuToggle = () => {
    dispatch(toggle());
  };

  const filteredLinks = links.filter((link) => {
    return !(user && link.excludeRoles?.includes(UserRole[user.role]));
  });

  const foundedLink = filteredLinks.findIndex(({ value }) =>
    matchPath(
      {
        path: value,
        end: false,
      },
      location.pathname
    )
  );
  const choosedItem = foundedLink === -1 ? '0' : foundedLink.toString();

  const pathname = location.pathname;
  if (pathname === "/" ||  pathname === "/password_recovery" || pathname.indexOf("verify") >=0 ){
    return <>{children}</>
  }

  return (
    <>
      <div className={styles.Content}>
        <MainHeader />
        <Layout className="site-layout">
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={`${!collapsed ? styles.Sider : styles.SiderCollapsed}`}
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[choosedItem]}
              className={styles.Menu}
            >
              {filteredLinks.map((link, index) => {
                return (
                  <Menu.Item
                    key={index}
                    icon={link.icon}
                    className={`${
                      !collapsed ? styles.MenuItem : styles.MenuItemCollapsed
                    }`}
                  >
                    <Link to={link.value}>{link.title}</Link>
                  </Menu.Item>
                );
              })}
            </Menu>
            <Header style={{ padding: 0 }} className={styles.Burger}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: onMenuToggle,
                }
              )}
            </Header>
          </Sider>
          <Layout className="site-layout">
            <Content className="site-layout-background">
              <div className={styles.ChildrenContent}>{children}</div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default Default;
