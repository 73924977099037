import React from "react";
import StockChart from "@content/Analytics/Charts/ChartTypes";
import MultipleStockChart from "@content/Analytics/Charts/ChartTypes/multipleSeriesChart";

interface Props{
  archiveData: TArchive[],
  names: string[]
}
type TArchive = {
  [key: string] : number
}

export default function WaterLevelChannel({archiveData, names}: Props){
  return <MultipleStockChart
                     archiveData={archiveData}
                     colors={[
                       "#096dd9",
                       "#1890ff",
                       "#91d5ff",
                       "#69c0ff",
                       "#bae7ff",
                       "#40a9ff",
                       "#0050b3"]}
                     names={names}
                     title={"Уровень в канале (м над уровнем моря)"}
                     textYAxis={"Уровень (м)"}
                     enableLegend={true}
  />
}


export const dataChart2 = [
  [Date.UTC(2021, 9, 24), 0],
  [Date.UTC(2021, 9, 27), 0.12],
  [Date.UTC(2021, 9, 30), 0.09],
  [Date.UTC(2021, 10,  3), 0.13],
  [Date.UTC(2021, 10,  6), 0.12],
  [Date.UTC(2021, 10,  9), 0.13],
  [Date.UTC(2021, 10, 12), 0.13],
  [Date.UTC(2021, 10, 15), 0.16],
  [Date.UTC(2021, 10, 18), 0.19],
  [Date.UTC(2021, 10, 21), 0.25],
  [Date.UTC(2021, 10, 24), 0.26],
  [Date.UTC(2021, 10, 27), 0.24],
  [Date.UTC(2021, 10, 30), 0.25],
  [Date.UTC(2021, 11,  3), 0.26],
  [Date.UTC(2021, 11,  6), 0.36],
  [Date.UTC(2021, 11,  9), 0.43],
  [Date.UTC(2021, 11, 12), 0.32],
  [Date.UTC(2021, 11, 15), 0.48],
  [Date.UTC(2021, 11, 18), 0.5],
  [Date.UTC(2021, 11, 21), 0.44],
  [Date.UTC(2021, 11, 24), 0.43],
  [Date.UTC(2021, 11, 27), 0.45],
  [Date.UTC(2021, 11, 30), 0.4],
  [Date.UTC(2022, 0,  3), 0.39],
  [Date.UTC(2022, 0,  6), 0.56],
  [Date.UTC(2022, 0,  9), 0.57],
  [Date.UTC(2022, 0, 12), 0.68],
  [Date.UTC(2022, 0, 15), 0.93],
  [Date.UTC(2022, 0, 18), 1.11],
  [Date.UTC(2022, 0, 21), 1.01],
  [Date.UTC(2022, 0, 24), 0.99],
  [Date.UTC(2022, 0, 27), 1.17],
  [Date.UTC(2022, 0, 30), 1.24],
  [Date.UTC(2022, 1,  3), 1.41],
  [Date.UTC(2022, 1,  6), 1.47],
  [Date.UTC(2022, 1,  9), 1.4],
  [Date.UTC(2022, 1, 12), 1.92],
  [Date.UTC(2022, 1, 15), 2.03],
  [Date.UTC(2022, 1, 18), 2.46],
  [Date.UTC(2022, 1, 21), 2.53],
  [Date.UTC(2022, 1, 24), 2.73],
  [Date.UTC(2022, 1, 27), 2.67],
  [Date.UTC(2022, 2,  3), 2.65],
  [Date.UTC(2022, 2,  6), 2.62],
  [Date.UTC(2022, 2,  9), 2.79],
  [Date.UTC(2022, 2, 13), 2.93],
  [Date.UTC(2022, 2, 20), 3.09],
  [Date.UTC(2022, 2, 27), 2.76],
  [Date.UTC(2022, 2, 30), 2.73],
  [Date.UTC(2022, 3,  4), 2.9],
  [Date.UTC(2022, 3,  9), 2.77],
  [Date.UTC(2022, 3, 12), 2.78],
  [Date.UTC(2022, 3, 15), 2.76],
  [Date.UTC(2022, 3, 18), 2.76],
  [Date.UTC(2022, 3, 21), 2.7],
  [Date.UTC(2022, 3, 24), 2.61],
  [Date.UTC(2022, 3, 27), 2.52],
  [Date.UTC(2022, 3, 30), 2.53],
  [Date.UTC(2022, 4,  3), 2.55],
  [Date.UTC(2022, 4,  6), 2.52],
  [Date.UTC(2022, 4,  9), 2.44],
  [Date.UTC(2022, 4, 12), 2.43],
  [Date.UTC(2022, 4, 15), 2.43],
  [Date.UTC(2022, 4, 18), 2.48],
  [Date.UTC(2022, 4, 21), 2.41],
  [Date.UTC(2022, 4, 24), 2.16],
  [Date.UTC(2022, 4, 27), 2.01],
  [Date.UTC(2022, 4, 30), 1.88],
  [Date.UTC(2022, 5,  2), 1.62],
  [Date.UTC(2022, 5,  6), 1.43],
  [Date.UTC(2022, 5,  9), 1.3],
  [Date.UTC(2022, 5, 12), 1.11],
  [Date.UTC(2022, 5, 15), 0.84],
  [Date.UTC(2022, 5, 18), 0.54],
  [Date.UTC(2022, 5, 21), 0.19],
  [Date.UTC(2022, 5, 23), 0]
]