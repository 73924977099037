import React, {useEffect, useState} from 'react';
import styles from './verifyEmail.module.scss';
import { useNavigate, useParams} from 'react-router-dom';
import InfoLayout from "@layouts/InfoLayout/InfoLayout";
import {Button} from "antd";
import {ExclamationCircleFilled, CheckCircleOutlined , CloseCircleFilled } from "@ant-design/icons";


export interface ContentProps {
  state: 'success' | 'warning' | 'error',
  title: string;
  subtitle: string;
  buttonText: string;
  onSubmit: ()=>void;
}

const Content: React.FC<ContentProps> = ({state, title, subtitle, onSubmit, buttonText}) => {
  return (
    <div>

        <div className={styles.ContentIcon}>
          {state === 'success' && <CheckCircleOutlined className={styles.IconSuccess} />}
          {state === 'warning' && <ExclamationCircleFilled className={styles.IconWarning}/>}
          {state === 'error' && <CloseCircleFilled className={styles.IconError}/>}
        </div>
        <div className={styles.ContentTitle}>{title}</div>
        <div className={styles.ContentSubTitle}>{subtitle}</div>
        <div className={styles.ContentButton}>
          <Button type="primary" onClick={onSubmit}>{buttonText}</Button>
        </div>
    </div>
  );
};

export default Content;
