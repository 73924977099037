// @ts-nocheck
import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './GraphReportPopup.module.scss';
import { Modal, Radio, DatePicker } from 'antd';
import { useNotification } from '@hooks/index';
import { useLazyDkuControllerGetReportQuery } from "../../../store/slices/dkuEnhanced"
import {usePrevious} from "../../../utils/common";
import moment from "moment";
import {useMap} from "react-leaflet";

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  stationId: number
}
const {RangePicker} = DatePicker;

const GraphReportPopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  stationId
}) => {

  const [getReport] = useLazyDkuControllerGetReportQuery();
  const [format, setFormat] = useState("pdf");
  const [period, setPeriod] = useState("daily");

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    console.log("Format:", format, "Period", period);
    getReport({period: period === "period" ? "MONTHLY" : period.toUpperCase(),
      format: format.toUpperCase(), id: stationId}).unwrap().then((res)=>{
      console.log("getReport res",res);
    }).catch(err=>{
      console.error("CATCH err", err)
    })
  };


  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [valueDatePicker, setValueDatePicker] = useState([null, null]);
  const [keyPicker, setKeyPicker] = useState(1);

  const count = useRef(0);
  const periodWasSelected = useRef(false);

  const prevOpen = usePrevious(openDatePicker);
  useEffect(()=>{
    if (typeof prevOpen !== "undefined" && !openDatePicker){
      count.current = 0;
    }
  }, [openDatePicker, prevOpen]);

  useEffect(()=>{
    if(openDatePicker){
      setKeyPicker((prev)=>prev+1)
    }
  }, [openDatePicker])
  const textPeriod = useMemo(()=>{
    if (valueDatePicker[0] === null && valueDatePicker[1] === null) {return "Период"}
    let from = "";
    let to = "";
    if (valueDatePicker[0] !== null){
      const mas = valueDatePicker[0].split("-");
      from = `${mas[2]}.${mas[1]}.${mas[0]?.slice(2)}` //2022-12-02
    }
    if (valueDatePicker[1] !== null){
      const mas = valueDatePicker[1].split("-");
      to = `${mas[2]}.${mas[1]}.${mas[0]?.slice(2)}` //2022-12-02
    }
    return `${from}-${to}`;
  }, [valueDatePicker])
  console.log("valueDatePicker", valueDatePicker);
  return (
    <>
      <Modal
        title="Сформировать отчет"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Сохранить"
        width={370}
      >
        <div className={styles.Title}>Период отчёта</div>

        <Radio.Group
          defaultValue="daily"
          buttonStyle="solid"
          onChange={(e)=>{
            setPeriod(e.target.value);
            console.log("e.target.value",e.target.value);

            if (e.target.value === "period"){
              setOpenDatePicker(true);
            }
          }}
          value={period}
          size="middle"
          style={{ marginBottom: 10 }}
        >
          <Radio.Button
            value="daily"
            id="1"
            style={{ width: textPeriod === "Период" ? 116 : 84, textAlign: 'center' }}
          >
            День
          </Radio.Button>
          <Radio.Button
            value="monthly"
            id="2"
            style={{ width: textPeriod === "Период" ? 116 : 84, textAlign: 'center' }}
          >
            Месяц
          </Radio.Button>
          <Radio.Button
            value="period"
            id="3"
            style={{width: textPeriod === "Период" ? 90 : 152, textAlign: 'center' }}
            onClick={()=>{
              setOpenDatePicker(true);
            }}
          >
            {textPeriod}
            <div key={keyPicker}>
              <RangePicker
                placement={"topRight"}
                className={styles.DatePicker}
                open={openDatePicker}
                onCalendarChange={
                  (val)=>{
                    console.log("calendarChange",val);
                  }
                }
                value={[ valueDatePicker[0] === null ? null : moment(valueDatePicker[0]) ,
                         valueDatePicker[1] === null ? null : moment(valueDatePicker[1])]}
                onOpenChange={(status)=>{
                  if (!status){setOpenDatePicker(false)}}}
                onChange={(val, dateString)=>{
                  if (!periodWasSelected.current){
                    periodWasSelected.current = true;
                    setValueDatePicker(dateString);
                    setOpenDatePicker(false);
                  }else{
                    setValueDatePicker(dateString);
                    count.current+=1;
                    if (count.current === 2){ //2 раза выбрали дату => период выбран, нужно закрыть
                      setOpenDatePicker(false);
                    }
                  }
                }}
              />
            </div>
          </Radio.Button>
        </Radio.Group>
        <div className={styles.Title}>Период отчёта</div>
        <Radio.Group
          defaultValue="pdf"
          buttonStyle="solid"
          value={format}
          onChange={(e)=>{setFormat(e.target.value);}}
          size="middle"
        >
          <Radio.Button
            value="pdf"
            id="1"
            style={{ width: 80.5, textAlign: 'center' }}
          >
            PDF
          </Radio.Button>
          <Radio.Button
            value="xls"
            id="2"
            style={{ width: 80.5, textAlign: 'center' }}
          >
            XLSX
          </Radio.Button>
          <Radio.Button
            value="doc"
            id="3"
            style={{ width: 80.5, textAlign: 'center' }}
          >
            DOCX
          </Radio.Button>
          <Radio.Button
            value="xml"
            id="4"
            style={{ width: 80.5, textAlign: 'center' }}
          >
            XML
          </Radio.Button>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default GraphReportPopup;
