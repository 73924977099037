import React, {useEffect} from 'react';
import styles from './WaterLevelSettingPopup.module.scss';
import { InputNumber, Modal, Slider } from 'antd';
import useNotification from "@hooks/useNotification";
import {usePrevious} from "../../../utils/common";

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  onSubmit: (valueHigh: number, valueLow: number, valueSpeed: number) => any;
  initialHigh: number;
  initialLow: number;
  initialSpeed: number;
}

const WaterLevelSettingPopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  initialHigh,
  initialLow,
  initialSpeed
}) => {
  const notificate = useNotification();
  console.log("initialHigh", initialHigh);
  const [valueHigh, setValueHigh] = React.useState(initialHigh || 0);
  const [valueLow, setValueLow] = React.useState(initialLow || 0);
  const [valueSpeed, setValueSpeed] = React.useState(initialSpeed || 0);
  const onChangeValueHigh = (value: number) => {
    setValueHigh(value);
  };
  const onChangeValueLow = (value: number) => {
    setValueLow(value);
  };
  const onChangeValueSpeed = (value: number) => {
    setValueSpeed(value);
  };

  const prevInitialHigh = usePrevious(initialHigh);
  const prevInitialLow = usePrevious(initialLow);
  const prevInitialSpeed = usePrevious(initialSpeed);

  useEffect(()=>{
    if (typeof prevInitialHigh !== "undefined" && initialHigh !== prevInitialHigh){
      setValueHigh(initialHigh);
    }
  },[initialHigh, prevInitialHigh])
  useEffect(()=>{
    if (typeof prevInitialLow !== "undefined" && initialLow !== prevInitialLow){
      setValueLow(initialLow);
    }
  },[initialLow, prevInitialLow])
  useEffect(()=>{
    if (typeof prevInitialSpeed !== "undefined" && initialSpeed !== prevInitialSpeed){
      setValueSpeed(initialSpeed);
    }
  },[initialSpeed, prevInitialSpeed])

  /*//устанавливаем заголовок модалки
  const [turn, setTurn] = React.useState('');
  React.useEffect(() => {
    if (pumpSetting === 'turnOn') {
      setTurn('Уставка уровня воды для включения насосов');
    }
    if (pumpSetting === 'turnOff') {
      setTurn('Уставка уровня воды для выключения насосов');
    }
  }, [pumpSetting]);*/

  const handleOk = async () => {

    const result = await onSubmit(valueHigh, valueLow, valueSpeed);
    console.log("result submit",result);
    if (result?.status === "OK") {
      setIsModalVisible(false);
    }else{
      notificate('error',result?.data?.messages?.[0] || "Не удалось обновить состояние");
    }
  };

  const handleCancel = () => {
    setValueLow(initialLow);
    setValueHigh(initialHigh);
    setValueSpeed(initialSpeed)
    setIsModalVisible(false);
  };

  //логика слайдера
  const marks = {
    0: '0',
    8: '8',
    16: '16',
    24: '24',
  };


  return (
    <>
      <Modal
        title={"Настройки"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Сохранить"
      >
        <div style={{fontWeight: 500}}>Уставка уровня воды для включения насосов</div>
        <div className={styles.Subtitle}>М от ур. моря</div>
        <div className={styles.Inputs}>
          <Slider
            marks={marks}
            min={0}
            max={24}
            defaultValue={1}
            onChange={onChangeValueHigh}
            value={valueHigh}
            step={0.01}
            style={{ width: 353, marginRight: 24 }}
          />
          <InputNumber
            min={0}
            max={24}
            style={{ width: 90, height: 30 }}
            value={valueHigh}
            onChange={onChangeValueHigh}
          />
        </div>
        <div style={{fontWeight: 500}}>Уставка уровня воды для выключения насосов</div>
        <div className={styles.Subtitle}>М от ур. моря</div>
        <div className={styles.Inputs}>
          <Slider
            marks={marks}
            min={0}
            max={24}
            defaultValue={1}
            onChange={onChangeValueLow}
            value={valueLow}
            step={0.01}
            style={{ width: 353, marginRight: 24 }}
          />
          <InputNumber
            min={0}
            max={24}
            style={{ width: 90, height: 30 }}
            value={valueLow}
            onChange={onChangeValueLow}
          />
        </div>
        <div style={{fontWeight: 500}}>Скорость изменения уровня воды в канале
        </div>
        <div className={styles.Subtitle}>см/мин</div>
        <div className={styles.Inputs}>
          <Slider
            marks={marks}
            min={0}
            max={24}
            defaultValue={1}
            onChange={onChangeValueSpeed}
            value={valueSpeed}
            step={0.01}
            style={{ width: 353, marginRight: 24 }}
          />
          <InputNumber
            min={0}
            max={24}
            style={{ width: 90, height: 30 }}
            value={valueSpeed}
            onChange={onChangeValueSpeed}
          />
        </div>
      </Modal>
    </>
  );
};

export default WaterLevelSettingPopup;
