import React from "react";
import StockChart from "@content/Analytics/Charts/ChartTypes";
import ColumnsChart from "@content/Analytics/Charts/ChartTypes/columnsChart";
interface Props{
  archiveData: TArchive
}
type TArchive = {
  [key: string] : number
}


export default function VisitsCount({archiveData}: Props){
  return <ColumnsChart archiveData={archiveData}
                     color={"#FA541C"}
                     title={"Количество посещений ОНС"}
                     textYAxis={"Количество посещений"}/>

}
