import React, { useEffect } from 'react';
import { BaseHeaderContainer, BaseContainer } from '@base/index';
import { Default } from '@layouts/index';
import styles from './EventLog.module.scss';

import { DatePicker, Tabs } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAdminEventsControllerGetUserEventsQuery } from 'store/slices/adminEnhanced';
import SystemEvents from './SystemEvents';
import UserEvents from './UserEvents';

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

interface Props {}

interface User {
  key: string;
  data: string;
  fullname: string;
  action: string;
  parameter: string;
  valueBefore: string[] | string | null;
  valueAfter: string[] | string | null;
}

const EventLog: React.FC<Props> = () => {
  const { activeTab } = useParams();
  console.log(activeTab);
  const navigate = useNavigate();
  const tabChange = (activeKey: string) => {
    navigate('/event-log' + activeKey);
  };

  return (
    <>
      <BaseHeaderContainer className={styles.BaseHeaderContainer}>
        <div className={styles.Header}>
          <div className={styles.Title}>Журнал событий</div>
        </div>
      </BaseHeaderContainer>
      <div className={styles.Tabs}>
        <Tabs onChange={tabChange} activeKey={'/' + activeTab}>
          <TabPane tab="Пользователи" key="/users" />
          <TabPane tab="Система" key="/system" />
        </Tabs>
      </div>

      <BaseContainer>
        <div className={styles.Content}>
          {activeTab === 'users' ? (
            <UserEvents />
          ) : activeTab === 'system' ? (
            <SystemEvents />
          ) : null}
        </div>
      </BaseContainer>
    </>
  );
};

export default EventLog;
