import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../';
import { LoginResponseDto } from './authEndpoints';
import { UserDto } from './adminEndpoints';
import { clearMapOptions } from './mapSlice';
import {apiSlice} from "./apiSlice";
import {authEndpoints} from "./authEndpoints";
import {getFingerprint} from "../helpers";

type TPropsLogout = {
  needLogoutFetch: boolean;
}
export const logout = createAsyncThunk('auth/logout',
  async ({needLogoutFetch}: TPropsLogout, thunkApi) => {
  console.log("logout, needLogoutFetch", needLogoutFetch);
  const fingerprint = await getFingerprint();
  const clearLocal = ()=>{
    thunkApi.dispatch(clearUser());
    thunkApi.dispatch(clearCredentials());
    thunkApi.dispatch(clearMapOptions());
    thunkApi.dispatch(apiSlice.util.resetApiState());
  }
  if (needLogoutFetch){
    try {
      thunkApi.dispatch(authEndpoints.endpoints.authControllerLogout.initiate(
        {
          logoutRequestDto: {
            fingerprint: fingerprint,
            refresh_token: localStorage.getItem("refresh_token") || ""
          }
        },
      )).unwrap().then((res)=>{
        console.log("res logout!", res);
        clearLocal()}).catch((err)=>{
        console.log("catch logout1!", err);
        // /logout провалился с 400 (неверная сессия) - такого не должно быть, но все же
        clearLocal()
      })
    }catch(err){
      console.log("catch logout! 2", err);
      clearLocal();
    }
  }else{
    clearLocal();
  }


 /* try{
    const res = await axios({
      method: 'POST',
      data: {
        fingerprint: fingerprint,
        refresh_token: localStorage.getItem("refresh_token")
       },
      url: "/auth/logout",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`
      },
      baseURL: process.env.REACT_APP_API_URL
    })
    console.log("logout res",res);
  }catch{
  }*/
});

const initialState: Partial<LoginResponseDto> & { user?: UserDto } = {
  user: JSON.parse(localStorage.getItem('user') || 'null'),
  access_token: localStorage.getItem('access_token') || undefined,
  refresh_token: localStorage.getItem('refresh_token') || undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<LoginResponseDto>) => {
      localStorage.setItem('access_token', action.payload.access_token);
      state.access_token = action.payload.access_token;

      localStorage.setItem('refresh_token', action.payload.refresh_token);
      state.refresh_token = action.payload.refresh_token;
    },
    clearCredentials: (state) => {
      localStorage.removeItem('access_token');
      state.access_token = undefined;

      localStorage.removeItem('refresh_token');
      state.refresh_token = undefined;
    },
    setUser: (state, action: PayloadAction<UserDto>) => {
      console.log("SetUser", action.payload);
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },
    clearUser: (state) => {
      console.log("clearUser");
      localStorage.removeItem('user');
      state.user = undefined;
    },
  },
});

export const { setCredentials, clearCredentials, setUser, clearUser } =
  authSlice.actions;

export default authSlice.reducer;

export const selectUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.access_token;
