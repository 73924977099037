import React from 'react';
import { Link } from 'react-router-dom';
import {
  computedColorMode,
  computedColorUPS,
  computedColorSecurity,
  computedColorOnsState,
  computedColorLinkState,
  computedColorSensorState,
  computedColorBoolean,
  computedColorPumpState,
} from 'utils/computedColor';
import styles from './IntelligenceItem.module.scss';

import { SettingOutlined } from '@ant-design/icons';
import {
  EditUserPopup,
  SpeedSettingPopup,
  TimeSettingPopup,
  WaterLevelSettingPopup,
  WorkingModePopup,
} from '@modals/index';
import {capitalizeFirstLetter, formatDateTime} from "../../../../utils/common";
import {OnsPopUpDto, StationDto, StationOperatingMode, StationSettingsDto} from "../../../../store/slices/dku";
import {Button} from "antd";

interface Props {
  data: OnsPopUpDto,
  dataSettings: StationSettingsDto[],
  onChangeOnsState: (value: StationOperatingMode)=>any,
  onChangeSettings: (valueHigh: number, valueLow: number, valueSpeed: number)=>any
}

const IntelligenceItem: React.FC<Props> = ({data,
                                             dataSettings,
                                             onChangeOnsState,
                                             onChangeSettings}) => {
  //Логика модалок
  //..для установки уровня воды
  const [waterLevelSettingPopupVisible, setWaterLevelSettingPopupVisible] =
    React.useState(false);
  const [pumpSetting, setPumpSetting] = React.useState('');
  const showModalWaterLevelSetting = () => {
    setWaterLevelSettingPopupVisible(true);
    //setPumpSetting(pumpSetting);
  };

  //...для установки Режим работы ОНС
  const [workingModePopupVisible, setWorkingModePopupVisible] =
    React.useState(false);
  const showModalWorkingModePopup = () => {
    setWorkingModePopupVisible(true);
  };

  //...для установки Скорость изменения уровня воды в канале
  const [speedSettingPopupVisible, setSpeedSettingPopupVisible] =
    React.useState(false);
  const showModalSpeedSettingPopup = () => {
    setSpeedSettingPopupVisible(true);
  };

  //..для установки Время
  const [timeSettingPopupVisible, setTimeSettingPopupVisible] =
    React.useState(false);
  const [timeSetting, setTimeSetting] = React.useState('');
  const showModalTimeSettingPopup = (timeSetting: string) => {
    setTimeSettingPopupVisible(true);
    setTimeSetting(timeSetting);
  };

  //модалка редактирования юзера
  const [visibleEditPopup, setVisibleEditPopup] = React.useState(false);
  const dataSettingsLast = dataSettings[dataSettings?.length - 1];
  console.log("dataSettingsLast",dataSettingsLast);
  const sensorsState = data?.station?.level_sensors?.find((el)=>{
    return el.level_sensor_id == data?.station?.active_level_sensor_id})?.state;

  const waterLevelOns = data?.station?.level_sensors?.map(
    (el)=> round(el.level_baltic_system, 2))?.join(", ");

  const remote_level_sensors = React.useMemo(()=>{
    const states = ['норма', 'в ремонте', 'авария'];
    type value = {
      state: string,
      ids: string
    };
    const list = data?.polder?.remote_level_sensors;
    let values = [] as value[];
    if (typeof list !== "undefined" && list?.length > 0){
      states.forEach((state)=>{
        const mas = list.filter((el)=>el.state===state);
        values.push({
          state: state,
          ids: mas?.length > 0 ? mas.map(el=>el.remote_level_sensor_id)?.join(", ") : "-"
        })
      })
    }

    return values;
  },[data?.polder?.remote_level_sensors]);
  console.log("remote_level_sensors===", remote_level_sensors);
  console.log("data.polder?.weatherstation?.precipitation", data.polder?.weatherstation?.precipitation);
  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeaderItem}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Состояние ОНС</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorOnsState(data?.station?.state)}`}
            ></span>
            <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.state) || "Нет данных"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Режим работы ОНС</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorMode(data?.station?.operating_mode)}`}
            ></span>
            <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.operating_mode) || "Нет данных"}</span>
            <span
              className={styles.ItemSetting}
              onClick={showModalWorkingModePopup}
            >
              <SettingOutlined width={16} height={16} />
            </span>
          </div>
        </div>
        <div className={styles.HeaderItem}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Время последнего изменения</span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{formatDateTime(data?.station?.last_update_time)} </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Источник последнего изменения
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              <Link to="" onClick={() => setVisibleEditPopup(true)}>
                {capitalizeFirstLetter(dataSettingsLast?.last_update_source) ||  "Нет данных"}
                {/*Сафонов Игорь*/}
              </Link>
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.ContentFullWidth} ${styles.ContentPumpSettings}`}>
        <div className={`${styles.ContentPumpSettingsInner}`}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Уставка уровня воды для включения насосов (м от ур. моря)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              {round(data?.station?.active_setpoint_list?.setpoint_level_high, 2) || "Нет данных"}
             </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Уставка уровня воды для отключения насосов (м от ур. моря)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              {data?.station?.active_setpoint_list?.setpoint_level_low || "Нет данных"}
            </span>
          </div>
          <div className={styles.Item}>
              <span className={styles.ItemTitle}>
                Уставка скорости изменения уровня воды <br /> в канале (м от ур.
                моря)
              </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
                {round(data?.station?.active_setpoint_list?.setpoint_level_change_speed, 2) || "Нет данных"}
              </span>
          </div>
        </div>

        <div
          className={styles.ItemSettingPump}
        >
          <Button type="primary" onClick={showModalWaterLevelSetting}>
           Настроить
          </Button>
        </div>
      </div>


      <div className={styles.Content}>
        <div className={styles.ContentItem}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Время начала выполнения</span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              {formatDateTime(dataSettingsLast?.start_time)}
              {/*20.03.22 • 04:24:07*/}</span>
            {/*<span
              className={styles.ItemSetting}
              onClick={() => showModalTimeSettingPopup('start')}
            >
              <SettingOutlined />
            </span>*/}
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Время окончания выполнения</span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              {formatDateTime(dataSettingsLast?.finish_time)}
              {/*20.03.22 • 06:22:51*/}</span>
           {/* <span
              className={styles.ItemSetting}
              onClick={() => showModalTimeSettingPopup('end')}
            >
              <SettingOutlined />
            </span>*/}
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Состояние связи с ЦОД</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorLinkState(data?.station?.link_state)}`}
            ></span>
            <span className={styles.ItemValue}>
              {capitalizeFirstLetter(data?.station?.link_state) || "Нет данных"}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Состояние ИБП</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorUPS(data?.station?.UPS_state)}`}
            ></span>
            <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.UPS_state) || "Нет данных"} {/*Питание от сети*/}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Состояние датчиков уровня воды в подземной <br /> части ОНС
            </span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorSensorState(sensorsState)}`}
            ></span>
            <span className={styles.ItemValue}>
              {capitalizeFirstLetter(sensorsState) || "Нет данных"}
              {/*Норма*/}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Уровень воды в подземной части ОНС
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{round(data?.station?.actual_level, 2) || "Нет данных"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Уровень воды в подземной части ОНС <br /> от резервных датчиков
              (от ур. моря)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              { waterLevelOns || "Нет данных" }
             </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Температура воздуха внутри ОНС (°С).
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              {round(data?.station?.air_temperature,2) || "Нет данных"}
              {/*35°*/}</span>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <div className={styles.Item}>
            <span className={`${styles.ItemTitle} ${styles.Important}`}>
              Режим охраны
            </span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorSecurity(data?.station?.security_system_mode)}`}
            ></span>
            <span className={styles.ItemValue}>
              {capitalizeFirstLetter(data?.station?.security_system_mode) || "Нет данных"}{/*Под охраной*/}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Дверь </span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.station?.security_system_state?.door_opened)}`}
            ></span>
            <span className={styles.ItemValue}>{data?.station?.security_system_state?.door_opened}
              {data?.station?.security_system_state?.door_opened ? "Открыта" : "Закрыта"}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Окно</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.station?.security_system_state?.window_opened)}`}
            ></span>
            <span className={styles.ItemValue}>{data?.station?.security_system_state?.window_opened}
              {data?.station?.security_system_state?.window_opened ? "Открыто" : "Закрыто"}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Окно разбито</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.station?.security_system_state?.window_broken)}`}
            ></span>
            <span className={styles.ItemValue}>{data?.station?.security_system_state?.window_broken ? "Да" : "Нет"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Щит</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.station?.security_system_state?.automation_shield_opened)}`}
            ></span>
            <span className={styles.ItemValue}>
              {data?.station?.security_system_state?.automation_shield_opened ? "Открыт" : "Закрыт"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>Присутствие в здании</span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.station?.security_system_state?.presence_detection)}`}
            ></span>
            <span className={styles.ItemValue}>{data?.station?.security_system_state?.presence_detection ? "Есть" : "Нет" }</span>
          </div>
        </div>
      </div>

      <div className={styles.Middle}>
        <div className={styles.MiddleBorder}></div>
        <div className={styles.MiddleTitle}>Насосы</div>
        <div className={styles.MiddleBorder}></div>
      </div>

      {/*<div className={styles.ContentFullWidth}>
        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Уставка уровня воды для включения насосов (м от ур. моря)
          </span>
          <span className={styles.ItemBorder}></span>
          <span className={styles.ItemValue}>
            {round(data?.station?.active_setpoint_list?.setpoint_level_high, 2) || "Нет данных"}
           </span>
          <span
            className={styles.ItemSetting}
            onClick={() => showModalWaterLevelSetting()}
          >
            <SettingOutlined />
          </span>
        </div>
        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Уставка уровня воды для отключения насосов (м от ур. моря)
          </span>
          <span className={styles.ItemBorder}></span>
          <span className={styles.ItemValue}>
            {data?.station?.active_setpoint_list?.setpoint_level_low || "Нет данных"}
          </span>
          <span
            className={styles.ItemSetting}
            onClick={() => showModalWaterLevelSetting()}
          >
            <SettingOutlined />
          </span>
        </div>
      </div>*/}

      <div className={styles.Content}>
        {data?.station?.pumps?.map((el, key)=>{
          return <div className={styles.ContentItem} key={key}>
            <div className={styles.Item}>
            <span className={`${styles.ItemTitle} ${styles.Important}`}>
              {capitalizeFirstLetter(el.priority)}
            </span>
              <span className={styles.ItemBorder}></span>
              <span
                className={`${styles.ItemDot} ${computedColorPumpState(el?.pump_state)}`}
              ></span>
              <span className={styles.ItemValue}>{capitalizeFirstLetter(el?.pump_state) || "Нет данных"}</span>
            </div>
            <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Потреблённый насосом ток (А)
            </span>
              <span className={styles.ItemBorder}></span>
              <span className={styles.ItemValue}>{round(el?.actual_current, 2) || "Нет данных"}</span>
            </div>
            <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Текущая потребляемая насосом мощность (Вт)
            </span>
              <span className={styles.ItemBorder}></span>
              <span className={styles.ItemValue}>{round(el?.actual_power, 2) || "Нет данных"}</span>
            </div>
            <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Потребляемая электроэнергия (кВТ*ч)
            </span>
              <span className={styles.ItemBorder}></span>
              <span className={styles.ItemValue}>{round(el?.consumed_energy, 2) || "Нет данных"}</span>
            </div>
            <div className={styles.Item}>
              <span className={styles.ItemTitle}>Наработка (ч)</span>
              <span className={styles.ItemBorder}></span>
              <span className={styles.ItemValue}>{el?.operating_time || "Нет данных"}</span>
            </div>
          </div>
        })}
      </div>

      <div className={styles.Middle}>
        <div className={styles.MiddleBorder}></div>
        <div className={styles.MiddleTitle}>Польдер</div>
        <div className={styles.MiddleBorder}></div>
      </div>

      <div className={styles.Content}>
        <div className={styles.ContentItem}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Возникающие заторы и/или подтопления
            </span>
            <span className={styles.ItemBorder}></span>
            <span
              className={`${styles.ItemDot} ${computedColorBoolean(data?.polder?.flood_remote_sensor_list?.length > 0)}`}
            ></span>
            <span className={styles.ItemValue}>
              {data?.polder?.flood_remote_sensor_list?.length > 0 ? (
                <>
                  Есть (
                  { data?.polder?.flood_remote_sensor_list?.join(",") }
                  )
               </>
              )
                : "Отсутствуют"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Состояние удаленных датчиков уровня воды <br /> в мелиоративном
              канале
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>
              Статус
            </span>
          </div>
          {remote_level_sensors?.map((item, key)=>{
            return <div className={styles.Item} key={key}>
                     <span className={styles.ItemTitleSmall}>
                       {`Датчики: ${item.ids}`}
                    </span>
                    <span className={styles.ItemBorder}></span>
                    <span
                          className={`${styles.ItemDot} ${computedColorSensorState(item?.state)}`}
                    />
                  <span className={styles.ItemValue}>
                  {item.state}
                  </span>
            </div>
          })}

          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Скорость изменения уровня воды в канале (см/мин)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>Нет данных</span>
          </div>
        </div>

        <div className={styles.ContentItem}>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Уровень воды в канале, текущий/прогноз (м от ур. моря)
            </span>
            <span className={styles.ItemBorder}></span>

          </div>
          <div className={styles.Item}>
             <span className={styles.ItemValue}>
              {data?.polder?.remote_level_sensors?.map((el)=>{
                return round(el?.level_baltic_system, 2)
              })?.join(", ")
              || "-"}/-</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Интенсивность осадков, текущий/прогноз (мм/мин)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{round(data?.polder?.weatherstation?.precipitation, 2) || "-"}/-</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Температура воздуха, текущий/прогноз (°С)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{round(data?.polder?.weatherstation?.air_temperature, 2) || "-"}/-</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Влажность (%)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{round(data?.polder?.weatherstation?.air_humidity, 2) || "Нет данных"}</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.ItemTitle}>
              Атмосферное давление (гПа)
            </span>
            <span className={styles.ItemBorder}></span>
            <span className={styles.ItemValue}>{round(data?.polder?.weatherstation?.atmospheric_pressure, 2) || "Нет данных"}</span>
          </div>
        </div>
      </div>

      <WaterLevelSettingPopup
        isModalVisible={waterLevelSettingPopupVisible}
        setIsModalVisible={setWaterLevelSettingPopupVisible}
        onSubmit={onChangeSettings}
        initialHigh={round(data?.station?.active_setpoint_list?.setpoint_level_high, 2)}
        initialLow={round(data?.station?.active_setpoint_list?.setpoint_level_low, 2)}
        initialSpeed={round(data?.station?.active_setpoint_list?.setpoint_level_change_speed, 2)}
      />

      <WorkingModePopup
        isModalVisible={workingModePopupVisible}
        setIsModalVisible={setWorkingModePopupVisible}
        initialValue={data?.station?.operating_mode}
        onSubmit={onChangeOnsState}
      />

      <SpeedSettingPopup
        isModalVisible={speedSettingPopupVisible}
        setIsModalVisible={setSpeedSettingPopupVisible}
      />

      {/*<TimeSettingPopup
        timeSetting={timeSetting}
        isModalVisible={timeSettingPopupVisible}
        setIsModalVisible={setTimeSettingPopupVisible}
        onSubmit={}
        initialValue={}
      />*/}

      {/* <EditUserPopup
        visible={visibleEditPopup}
        setVisible={setVisibleEditPopup}
      /> */}
    </>
  );
};

export default IntelligenceItem;

const round = function (value: number | null, n: number){
  if (typeof value !== "number" ){
    return 0
  }
  const del = Math.pow(10, n);
  return Math.round(value*del)/del;
}