import { useAppSelector } from 'store';
import { selectUser } from 'store/slices/authSlice';

const useUser = () => {
  const user = useAppSelector(selectUser);

  return user;
};

export default useUser;
