import { apiSlice as api } from './apiSlice';
import {UserDto} from "./userEndpoints";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    notificationsControllerGetNotifications: build.query<
      GetNotificationsApiResponse,
      PaginationArgs
      >({
      query: (query) => ({
        url: `/notification?page=${query.page}&limit=${query.limit}`,
      }),
      keepUnusedDataFor: 5
    }),
    notificationsControllerGetUnreadCount: build.query<
      BaseResponseDto,
      void
      >({
      query: () => ({
        url: `/notification/unread-count`,
      }),
      keepUnusedDataFor: 10
    }),
    notificationsControllerReadNotifications: build.mutation<
      BaseResponseDto,
      ReadNotificationsArgs
      >({
      query: (queryArg) => ({
        url: `/notification/mark-as-read`,
        method: 'POST',
        body: queryArg
      }),
    }),
    notificationsControllerDeleteAll: build.mutation<
      BaseResponseDto,
      void
      >({
      query: () => ({
        url: `/notification`,
        method: 'DELETE',
        body: {
          ids: []
        }
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useNotificationsControllerGetNotificationsQuery,
               useLazyNotificationsControllerGetNotificationsQuery,
               useNotificationsControllerReadNotificationsMutation,
               useNotificationsControllerGetUnreadCountQuery,
               useLazyNotificationsControllerGetUnreadCountQuery,
               useNotificationsControllerDeleteAllMutation
              } = injectedRtkApi;
export { injectedRtkApi as notificationsEndpoints };

export type NotificationDto = {
  id:	number;
  user:	UserDto;
  message: string;
  level:	'info' | 'warning' | 'error';
  isRead:	boolean;
  createdAt:	string;
  stationId:	string;
}

export type GetNotificationsApiResponse = BaseResponseDto & {
  result: PaginationDto & {
    items: NotificationDto []
  }
}

export type ReadNotificationsArgs = {
  ids: number | number[]
}
export type BaseResponseDto = {
  status: 'OK' | 'ERROR';
  messages?: string[];
  result?: object;
};
export type PaginationArgs = {
  page?: number;
  limit?: number;
};
export type PaginationDto = {
  totalItems: number;
  currentPage: number;
  totalPages: number;
  limit: number;
  items: object[];
};