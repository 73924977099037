import React from 'react';
import styles from './InfoLayout.module.scss';
import {BaseIcon} from '@base/index';
import {ALL_ICONS} from "@constants/icons";

interface Props {
  children: React.ReactNode
}

const InfoLayout: React.FC<Props> = ({children}) => {
  return (
    <div className={styles.InfoLayout}>
      <BaseIcon
        icon={ALL_ICONS.LOGO}
        viewBox="0 0 43 43"
        className={styles.IconLogo}
      />
      <div className={styles.Title}>Мелиорация</div>
      <div className={styles.Subtitle}>управление системами</div>
      <div className={styles.Description}>
        Панель управления мелиоративной системой Калининградской области
      </div>
      <div className={styles.Container}>
        {children}
      </div>
      <BaseIcon
        icon={ALL_ICONS.WAVE}
        viewBox="0 0 1440 250"
        className={styles.Wave}
      />
      <BaseIcon
        icon={ALL_ICONS.WAVE_TABLET}
        viewBox="0 0 768 301"
        className={styles.WaveTablet}
      />
    </div>
  );
};

export default InfoLayout;
