import { Default } from '@layouts/index';
import React, {useEffect, useState} from 'react';
import styles from './Management.module.scss';
import { BaseHeaderContainer } from '@base/index';
import { Radio } from 'antd';
import { ManagementMap, ManagementOptions } from '@content/index';
import RoleBasedView from 'utils/RoleBasedView';
import { UserRole } from 'types';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useUser} from "@hooks/index";

interface Props {}

const Management: React.FC<Props> = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const [valueTab, setValueTab] = useState('/' + activeTab);

  const user = useUser();

  useEffect(()=>{
    if (user?.role === "observer"){
      navigate("/management/map");
      setValueTab("/map")
    }
  },[user])

  console.log("activeTab", activeTab);
  const tabChange = (value: any, query?: string) => {
    navigate('/management' + value.target.value);
    setValueTab( value.target.value);
  };
  const goToParams = (value: string) => {
    tabChange({target: {value: `/options?station=${value}`}})
  };
  const changeTabOns = (stationId: string)=>{
    navigate(`/management/options?station=${stationId}`);
  }
  console.log("valueTab", valueTab);
  const valueTabClear =  valueTab?.indexOf("?") > 0 ? valueTab?.slice(0, valueTab?.indexOf("?")) : valueTab;
  return (
    <>
      <BaseHeaderContainer>
        <div className={styles.Title}>Контроль и управление</div>

        <div className="">
          <Radio.Group
            defaultValue={'/' + activeTab}
            buttonStyle="solid"
            onChange={tabChange}
            value={valueTabClear}
          >
            <Radio.Button value="/map" id="1">
              Карта
            </Radio.Button>
            <RoleBasedView excludeRoles={[UserRole.observer]}>
              <Radio.Button value="/options" id="2">
                Параметры
              </Radio.Button>
            </RoleBasedView>
          </Radio.Group>
        </div>
      </BaseHeaderContainer>

      {activeTab === 'map' ? (
        <ManagementMap goToParams={goToParams} />
      ) : activeTab === 'options' ? (
        <ManagementOptions changeTabOns={changeTabOns} />
      ) : (
        'Упс, что-то пошло не так!'
      )}
    </>
  );
};

export default Management;
