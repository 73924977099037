import {notificationsEndpoints} from "./notificationsEndpoints";

export const notificationsEnhanced = notificationsEndpoints.enhanceEndpoints({
  addTagTypes: ['noticesCustom'],
  endpoints: {
    notificationsControllerGetNotifications: {
      providesTags: ['noticesCustom'],
    },
    notificationsControllerGetUnreadCount: {
      providesTags: ['noticesCustom'],
    },
    notificationsControllerDeleteAll: {
      invalidatesTags: ['noticesCustom'],
    },
  },
});

export const {
  useNotificationsControllerDeleteAllMutation,
  useLazyNotificationsControllerGetNotificationsQuery,
  useLazyNotificationsControllerGetUnreadCountQuery
} = notificationsEnhanced;
