export enum Icons {
  LOGO = 'LOGO',
  LOGO2 = 'LOGO2',
  WAVE = 'WAVE',
  WAVE_TABLET = 'WAVE_TABLET',
  RECOVERY_SUCCESS = 'RECOVERY_SUCCESS',
}

export const icons = {
  [Icons.LOGO]: (
    <>
      <g clipPath="url(#clip0_216_49919)">
        <path
          d="M42.3032 21.1516C42.3032 32.8332 32.8332 42.3032 21.1516 42.3032C9.4698 42.3032 0 32.8332 0 21.1516C0 9.4698 9.4698 0 21.1516 0C32.8332 0 42.3032 9.4698 42.3032 21.1516Z"
          fill="#0C5872"
        />
        <rect
          x="-1.98291"
          y="19.499"
          width="45.7734"
          height="22.8041"
          fill="#8BCA91"
        />
        <path
          d="M21.3641 10.0361C21.3641 10.0361 27.9473 20.7557 27.9473 24.6605C27.9473 27.7736 26.4915 31.519 21.3641 31.519C16.2366 31.519 14.7783 27.7714 14.7783 24.6585C14.7783 20.8879 21.3641 10.0361 21.3641 10.0361"
          fill="white"
        />
        <path
          d="M16.6128 13.6357C16.6128 13.6357 20.7562 20.3824 20.7562 22.8402C20.7562 24.7995 19.8399 27.1569 16.6128 27.1569C13.3856 27.1569 12.4678 24.7982 12.4678 22.8388C12.4678 20.4657 16.6128 13.6357 16.6128 13.6357"
          fill="#61AAD4"
        />
      </g>
      <defs>
        <clipPath id="clip0_216_49919">
          <rect width="42.3032" height="42.3032" rx="21.1516" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  [Icons.LOGO2]: (
    <>
      <g clipPath="url(#clip0_216_49901)">
        <path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37253 24 0 18.6274 0 12C0 5.37253 5.37253 0 12 0C18.6274 0 24 5.37253 24 12Z"
          fill="#89CFDE"
        />
        <rect x="-1" y="11" width="26" height="13" fill="#8BCA91" />
        <path
          d="M12.0008 6C12.0008 6 16 11.9878 16 14.1689C16 15.9078 15.1156 18 12.0008 18C8.8859 18 8 15.9066 8 14.1678C8 12.0616 12.0008 6 12.0008 6"
          fill="white"
        />
        <path
          d="M9.5005 8C9.5005 8 12 11.4928 12 12.7652C12 13.7796 11.4472 15 9.5005 15C7.55365 15 7 13.7789 7 12.7645C7 11.5359 9.5005 8 9.5005 8"
          fill="#0099DE"
        />
      </g>
      <defs>
        <clipPath id="clip0_216_49901">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  [Icons.WAVE]: (
    <>
      <g clipPath="url(#clip0_216_49925)">
        <path
          d="M825.52 108.148C1151.22 -37.958 1479.68 -6.39905 1600.44 32.9335V397.278H36.1084V141.272C219.66 204.755 530.18 240.635 825.52 108.148Z"
          fill="#267379"
        />
        <path
          d="M679.92 111.371C354.219 -34.7354 25.7578 -3.17644 -95 36.1561V400.501H1469.33V144.494C1285.78 207.977 975.26 243.858 679.92 111.371Z"
          fill="#0C5872"
        />
      </g>
      <defs>
        <clipPath id="clip0_216_49925">
          <rect width="1440" height="250" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  [Icons.WAVE_TABLET]: (
    <>
      <path
        d="M463.808 82.2692C717.077 -28.0359 972.493 -4.21 1066.4 25.4847V300.552H-150.048V107.276C-7.31604 155.203 234.148 182.292 463.808 82.2692Z"
        fill="#267379"
      />
      <path
        d="M350.588 84.7028C97.3186 -25.6023 -158.097 -1.77641 -252 27.9183V302.986H964.444V109.71C821.712 157.637 580.248 184.725 350.588 84.7028Z"
        fill="#0C5872"
      />
    </>
  ),
  [Icons.RECOVERY_SUCCESS]: (
    <>
      <g clipPath="url(#clip0_16_40857)">
        <path
          d="M26.9307 12.2554H24.9416C24.509 12.2554 24.0976 12.4632 23.8432 12.8194L17.1762 22.065L14.1566 17.8748C13.9021 17.5228 13.495 17.3107 13.0581 17.3107H11.0691C10.7934 17.3107 10.6322 17.6246 10.7934 17.8493L16.0778 25.1779C16.2026 25.3522 16.3672 25.4941 16.5578 25.5921C16.7485 25.69 16.9598 25.7411 17.1741 25.7411C17.3884 25.7411 17.5997 25.69 17.7903 25.5921C17.981 25.4941 18.1456 25.3522 18.2704 25.1779L27.2021 12.794C27.3675 12.5692 27.2063 12.2554 26.9307 12.2554Z"
          fill="#52C41A"
        />
        <path
          d="M19 0C8.50759 0 0 8.50759 0 19C0 29.4924 8.50759 38 19 38C29.4924 38 38 29.4924 38 19C38 8.50759 29.4924 0 19 0ZM19 34.7768C10.2888 34.7768 3.22321 27.7112 3.22321 19C3.22321 10.2888 10.2888 3.22321 19 3.22321C27.7112 3.22321 34.7768 10.2888 34.7768 19C34.7768 27.7112 27.7112 34.7768 19 34.7768Z"
          fill="#52C41A"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_40857">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
};
