import { providesList } from '../helpers';
import { adminEndpoints } from './adminEndpoints';

export const adminEnhanced = adminEndpoints.enhanceEndpoints({
  addTagTypes: ['User', 'Groups'],
  endpoints: {
    adminUsersControllerGetById: {
      providesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    },
    adminUsersControllerGetAllUsers: {
      providesTags: (result) => providesList(result?.result?.items, 'User'),
    },
    adminUsersControllerRegister: {
      invalidatesTags: ['User'],
    },
    adminUsersControllerUpdateUser: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    },
    adminUsersControllerUpdateUserStatus: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    },
    adminUsersControllerGetAllGroups: {
      providesTags: ['Groups']
    },
    adminUsersControllerGetUsersNotInGroup:{
      providesTags: ['Groups']
    },
    adminUsersControllerCreateGroup:{
      invalidatesTags: ['Groups']
    },
    adminUsersControllerEditGroup: {
      invalidatesTags:  ['Groups']
    },
    adminUsersControllerDeleteGroup: {
      invalidatesTags:  ['Groups']
    }
  },
});

export const {
  useLazyAdminUsersControllerGetByIdQuery,
  useAdminUsersControllerGetByIdQuery,
  useAdminUsersControllerGetAllUsersQuery,
  useAdminUsersControllerRegisterMutation,
  useAdminUsersControllerUpdateUserMutation,
  useAdminUsersControllerUpdateUserStatusMutation,
  useAdminEventsControllerGetSystemEventsQuery,
  useLazyAdminEventsControllerGetSystemEventsQuery,
  useLazyAdminEventsControllerGetUserEventsQuery,
  useAdminEventsControllerGetUserEventsQuery,
  useAdminUsersControllerGetAllGroupsQuery,
  useAdminUsersControllerGetAllSystemEventsNamesQuery,
  useAdminUsersControllerCreateGroupMutation,
  useAdminUsersControllerEditGroupMutation,
  useAdminUsersControllerGetUsersNotInGroupQuery,
  useAdminUsersControllerDeleteGroupMutation,
  useAdminUsersControllerSendSocketMutation,
  useAdminUsersControllerGetAllUsersIdsQuery
} = adminEnhanced;
