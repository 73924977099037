import React, {useEffect, useRef, useState} from 'react';
import styles from './NotificationPopup.module.scss';
import {Badge, Modal, Pagination, Select} from 'antd';
import {StationOperatingMode} from "../../../store/slices/dku";
import {formatDateTimeNotification, usePrevious} from "../../../utils/common";
import {
  NotificationDto,
  useNotificationsControllerReadNotificationsMutation
} from "../../../store/slices/notificationsEndpoints";
import {useNotificationsControllerDeleteAllMutation,
  useLazyNotificationsControllerGetNotificationsQuery,
  useLazyNotificationsControllerGetUnreadCountQuery}
from "../../../store/slices/notificationsEnhanced";
import {useNavigate} from "react-router-dom";
import useOnScreen from "../../../utils/useOnScreen";
import {ExclamationCircleFilled, ExclamationCircleOutlined, InfoCircleFilled, WarningOutlined} from "@ant-design/icons";


interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  onSubmit: (value: StationOperatingMode)=>any
}

const NotificationsPopup: React.FC<Props> = ({
                                             isModalVisible,
                                             setIsModalVisible,
                                             onSubmit,
                                           }) => {

  const [page, setPage] = useState(1);

  //const {data: notifications} = useNotificationsControllerGetNotificationsQuery({page, limit: 5});
  const [getNotices, {data: notifications}] = useLazyNotificationsControllerGetNotificationsQuery();
  const [readNotices] = useNotificationsControllerReadNotificationsMutation();
  const [getUnreadCount, {data: countUnread}] = useLazyNotificationsControllerGetUnreadCountQuery();
  const [deleteAll] = useNotificationsControllerDeleteAllMutation();

  useEffect(()=>{
    getNotices({page, limit: 5})
  },[page])

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onReadNotification = (id: number)=>{
    console.log(" onReadNotification", id);
    readNotices({ids: [id]}).unwrap().then(()=>{
      getUnreadCount();
      getNotices({page, limit: 5 })
    });
  }

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: '  Вы уверены, что хотите очистить все уведомления?',
      icon: <ExclamationCircleOutlined />,
      content: 'Все уведомления будут удалены безвозвратно',
      okText: "Удалить",
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        deleteAll()
      },
      onCancel() {},
    });
  };
  const Header = ()=>{
    return <div className={styles.Header}>
      <div className={styles.Title}>
        История уведомлений
      </div>
      <div className={styles.Clear} onClick={showConfirm}>Очистить все</div>
    </div>
  }

  return (
    <>
      <Modal
        //title="История уведомлений"
        title={<Header/>}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={"588px"}
        footer={null}
        className={"modal-notifications"}
      >

        {notifications?.result?.items?.map(el=>{
          //@ts-ignore
          return <NotificationItem onRead={onReadNotification} key={el.id} notification={el} isRead={el.isRead}
                                   onClose={()=>{setIsModalVisible(false)}}/>
        })}

      <div className={styles.Pagination}>
          <Pagination
            defaultCurrent={page}
            size="default"
            total={notifications?.result?.totalItems || 0}
            pageSize={5}
            onChange={(page) => {
              setPage(page);
            }}
          />
      </div>
      </Modal>
    </>
  );
};

export default NotificationsPopup;
interface PropsItem {
  notification: NotificationDto;
  isRead: boolean;
  onRead: (id: number)=>void;
  onClose: ()=>void;
}
const NotificationItem: React.FC<PropsItem> = ({notification, isRead, onRead, onClose}: PropsItem) => {
  console.log("NotificationItem===", notification);
  const navigate = useNavigate();
  const ref: any = useRef<HTMLDivElement>();
  // In this case it would only be considered onScreen if more ...
  // ... than 100px of element is visible.
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, "200px");
  const prevOnScreen = usePrevious(onScreen);
  const [wasOnScreen, setWasOnScreen] = useState(false);
  useEffect(()=>{
    console.log(notification.message,"onScreen!!, prevOnScreen, wasOnScreen, isREad", onScreen, prevOnScreen, wasOnScreen, isRead);
    if (typeof prevOnScreen === "undefined" && onScreen && !wasOnScreen ||
      typeof prevOnScreen !== "undefined" && !prevOnScreen && onScreen && !wasOnScreen ||
      onScreen && !isRead
    ){
      //отследить появление на экране первый и единственный раз
      setWasOnScreen(true);
      if (!isRead){
        onRead(notification.id);
      }
    }
  }, [onScreen, prevOnScreen, wasOnScreen, isRead])

  return(
    <div className={styles.NotificationItem} ref={ref}>
      <div className={styles.Content}>
        <div className={styles.Date}>{formatDateTimeNotification(notification?.createdAt)}</div>
        <div className={styles.TitleRow}>
          <div className={styles.TitleLeft}>
            <div className={`${styles.Icon}             
               ${notification?.level === "info" ? styles.IconInfo : ""}
               ${notification?.level === "warning" ? styles.IconWarning : ""}
               ${notification?.level === "error" ? styles.IconError : ""}
               `}>
              {notification?.level === "info" && <InfoCircleFilled />}
              {notification?.level === "warning" && <ExclamationCircleFilled />}
              {notification?.level === "error" && <WarningOutlined />}
            </div>
            <div className={styles.Title}>На станции <span
              onClick={()=>{
                onClose();
                navigate(`/management/map?stationId=${notification?.stationId}`);
              }}
              className={styles.LinkOns}>ОНС-{notification?.stationId}</span> произошло событие:</div>
          </div>
          <div className={styles.IsNew}>{isRead === false ? <Badge color={"#1890FF"}/> : ""}</div>
        </div>
        <div className={styles.Message}>{notification?.message}</div>

      </div>

    </div>
  )}