import React from "react";
export const TelegramIcon = () =>{
  return(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1004_59411)">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9.32252 6.6442C8.44714 7.0083 6.6976 7.7619 4.07392 8.90499C3.64787 9.07442 3.42469 9.24016 3.40438 9.40223C3.37004 9.67612 3.71303 9.78397 4.1801 9.93084C4.24364 9.95082 4.30947 9.97152 4.37695 9.99346C4.83648 10.1428 5.45462 10.3176 5.77597 10.3245C6.06746 10.3308 6.3928 10.2106 6.75198 9.964C9.20337 8.30925 10.4688 7.47286 10.5482 7.45483C10.6043 7.44211 10.6819 7.42611 10.7346 7.47288C10.7872 7.51966 10.782 7.60824 10.7765 7.632C10.7425 7.77686 9.39609 9.02857 8.69934 9.67633C8.48213 9.87827 8.32805 10.0215 8.29656 10.0542C8.226 10.1275 8.15409 10.1968 8.08498 10.2635C7.65807 10.675 7.33793 10.9836 8.10271 11.4876C8.47023 11.7298 8.76431 11.9301 9.05771 12.1299C9.37813 12.3481 9.69771 12.5657 10.1112 12.8367C10.2166 12.9058 10.3172 12.9775 10.4152 13.0474C10.7881 13.3132 11.1231 13.5521 11.5369 13.514C11.7774 13.4918 12.0258 13.2657 12.152 12.5913C12.4502 10.9974 13.0362 7.54384 13.1717 6.12073C13.1835 5.99605 13.1686 5.83648 13.1566 5.76643C13.1446 5.69638 13.1196 5.59658 13.0285 5.5227C12.9207 5.4352 12.7542 5.41675 12.6798 5.41806C12.3413 5.42403 11.8219 5.60461 9.32252 6.6442Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1004_59411">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}