import useUser from '@hooks/useUser';
import { Navigate, useLocation } from 'react-router-dom';
import { UserRole } from 'types';

export function RequireAuth({
  children,
  excludeRoles = [],
}: {
  children: JSX.Element;
  excludeRoles?: Array<UserRole>;
}) {
  const user = useUser();
  const location = useLocation();

  const userHasNotRequiredRole =
    user && !excludeRoles.includes(UserRole[user.role]);

  if (!userHasNotRequiredRole) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
