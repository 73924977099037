import React, {useEffect, useRef, useState} from "react";
import styles from './NotificationsDropdown.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import {NotificationDto} from "../../../../store/slices/notificationsEndpoints";
import {Badge} from "antd"
import {InfoCircleFilled, ExclamationCircleFilled, WarningOutlined, LoadingOutlined} from '@ant-design/icons'
import {formatDateTimeNotification, usePrevious} from "../../../../utils/common";
import useOnScreen from "../../../../utils/useOnScreen";
import {Link, useNavigate} from "react-router-dom";

interface Props {
  notifications: NotificationDto[];
  getNotifications: (page: number)=>void;
  onRead: (id: number)=>void;
  onClose: ()=>void;
  hasMore: boolean;
  rootEl: any;
  openAll: ()=>void
}
interface PropsItem {
  notification: NotificationDto;
  isRead: boolean;
  onRead: (id: number)=>void;
  onClose: ()=>void;
}

const NotificationsDropdown: React.FC<Props> = ({notifications, rootEl, getNotifications, onClose, hasMore, onRead, openAll}: Props) => {

  console.log("hasMore========", hasMore);

  console.log("notifications:", notifications);
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const element = rootEl?.current as any;
      if (element?.contains(e.target)){
        console.log("click inside")
      }else{
        console.log("click outside")
        onClose();
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);
  return(
    <div className={styles.Container} ref={rootEl}>
      <div className={styles.HeaderRow}>
        <div className={styles.TitleModal}>
          Уведомления
        </div>
        <div className={styles.Link} onClick={openAll}>Смотреть все</div>
      </div>
      <div className={styles.NotificationsContainer}>

        <InfiniteScroll
          pageStart={0}
          initialLoad={true}
          loadMore={getNotifications}
          hasMore={hasMore}
          loader={<div className={styles.Loader} key={0}> <LoadingOutlined />  </div>}
          useWindow={false}
        >
          {notifications?.length > 0 && notifications.map((item)=>{
            return <NotificationItem key={item?.id}
                                     onClose={onClose}
                                     notification={item} isRead={item.isRead} onRead={onRead}/>})
          }
        </InfiniteScroll>
        {!hasMore && <div className={styles.EmptyText}>Вы посмотрели все уведомления</div>}
      </div>
    </div>
  )
 }

export default NotificationsDropdown;

export const NotificationItem: React.FC<PropsItem> = ({notification, isRead, onRead, onClose}: PropsItem) => {
  console.log("NotificationItem===", notification);
  const navigate = useNavigate();
  const ref: any = useRef<HTMLDivElement>();
  // In this case it would only be considered onScreen if more ...
  // ... than 100px of element is visible.
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, "200px");
  const prevOnScreen = usePrevious(onScreen);
  const [wasOnScreen, setWasOnScreen] = useState(false);
  useEffect(()=>{
    if (typeof prevOnScreen === "undefined" && onScreen && !wasOnScreen ||
      typeof prevOnScreen !== "undefined" && !prevOnScreen && onScreen && !wasOnScreen
    ){
      //отследить появление на экране первый и единственный раз
      setWasOnScreen(true)
      if (!isRead){
        onRead(notification.id);
      }
    }
  }, [onScreen, prevOnScreen, wasOnScreen])

  return(
    <div className={styles.NotificationItem} ref={ref}>
      <div className={styles.Content}>

          <div className={styles.TitleRow}>
            <div className={styles.TitleLeft}>
              <div className={`${styles.Icon}             
               ${notification?.level === "info" ? styles.IconInfo : ""}
               ${notification?.level === "warning" ? styles.IconWarning : ""}
               ${notification?.level === "error" ? styles.IconError : ""}
               `}>
                {notification?.level === "info" && <InfoCircleFilled />}
                {notification?.level === "warning" && <ExclamationCircleFilled />}
                {notification?.level === "error" && <WarningOutlined />}
              </div>
              <div className={styles.Title}>На станции <span
                onClick={()=>{
                  onClose();
                  navigate(`/management/map?stationId=${notification?.stationId}`);
                }}
                className={styles.LinkOns}>ОНС-{notification?.stationId}</span> произошло событие:</div>
            </div>
            <div className={styles.IsNew}>{isRead === false ? <Badge color={"#1890FF"}/> : ""}</div>
          </div>
          <div className={styles.Message}>{notification?.message}</div>
          <div className={styles.Date}>{formatDateTimeNotification(notification?.createdAt)}</div>
        </div>

    </div>
)}