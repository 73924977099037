import React from "react";
import StockChart from "@content/Analytics/Charts/ChartTypes";

interface Props{
  archiveData: TArchive
}
type TArchive = {
  [key: string] : number
}

export default function WaterLevelUnderground({archiveData}: Props){
  return <StockChart archiveData={archiveData}
                     color={"#1D09C0"}
                     title={"Уровень в подземной части, (м)"}
                     textYAxis={"Уровень (м)"}/>
}
