import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import InfoLayout from "@layouts/InfoLayout/InfoLayout";
import Content, {ContentProps} from "./Content";
import {useLazyAuthControllerEmailVerifyQuery} from "../../store/slices/authEndpoints";

interface Props {}

const states = {
  success: {
    state: "success",
    title: "Почта подтверждена",
    subtitle: "Войдите в сервис для начала работы.",
    buttonText: "Авторизация"
  },
  warning: {
    state: "warning",
    title:"Ссылка устарела",
    subtitle:"Почта уже была подтверждена ранее.",
    buttonText: "Авторизация"
  },
  error: {
    state: "error",
    title: "Ошибка",
    subtitle: "Вы перешли по неверной ссылке.",
    buttonText: "Авторизация"
  },
}

const VerifyEmail: React.FC<Props> = () => {
  const { token } = useParams();
  console.log("token",token);
  const navigate = useNavigate();
  const [content, setContent] = useState({} as ContentProps);

  const [verifyEmail, {data}] = useLazyAuthControllerEmailVerifyQuery();
  useEffect(()=>{
    if (typeof token !=="undefined" && token?.length > 0) {
      verifyEmail({token})
    }
  },[token])

  useEffect(()=>{
    if (data?.status === "ERROR"){
      if (data?.messages?.[0] === "Ваша почта уже подтверждена"){
        setContent(states.warning as ContentProps)
      }else {
        setContent(states.error as ContentProps)
      }
    }
    if (data?.status === "OK"){
      setContent(states.success as ContentProps)
    }
  },[data])

  return (
    <InfoLayout>
     <Content  state={content.state}
               title={content.title}
               subtitle={content.subtitle}
               buttonText={content.buttonText}
               onSubmit={()=>{ navigate("/") }}/>
    </InfoLayout>
  );
};

export default VerifyEmail;

