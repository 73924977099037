import useUser from '@hooks/useUser';
import { FC } from 'react';
import { UserRole } from 'types';

const RoleBasedView: FC<{
  excludeRoles: Array<UserRole>;
}> = ({ children, excludeRoles }) => {
  const user = useUser();

  const userHasNotExcudedRole =
    user && !excludeRoles.includes(UserRole[user.role]);

  console.log("RoleBasedView user", user, "excludeRoles", excludeRoles, "userHasNotExcudedRole", userHasNotExcudedRole );
  if (userHasNotExcudedRole) {
    return <>{children}</>;
  } else return null;
};

export default RoleBasedView;
