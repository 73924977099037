import React, {useEffect, useMemo, useState} from 'react';
import styles from './ManagementOptions.module.scss';
import { BaseContainer } from '@base/index';
import { Tabs } from 'antd';
import { IntelligenceItem } from '@content/index';
import {
  useDkuControllerGetStationSettingsQuery,
  StationDto, StationOperatingMode, isValueStationOperatingMode, useDkuControllerGetStationsCommonInfoQuery
} from '../../../../store/slices/dku';
import { useDkuControllerGetStationQuery,
  useLazyDkuControllerGetStationQuery,
  useLazyDkuControllerGetStationSettingsQuery,
  useDkuControllerPostStationSettingsActionMutation,
  useDkuControllerPostStationActionMutation} from '../../../../store/slices/dkuEnhanced';
import {util} from "prettier";
import {useParams, useSearchParams} from "react-router-dom";


interface Props {
  changeTabOns: (value: string)=>void
}

const { TabPane } = Tabs;

const ManagementOptions: React.FC<Props> = ({changeTabOns}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stationId = searchParams.get("station");
  console.log("stationId query=======", stationId);
  //данные с апи
  const { data: commonData } = useDkuControllerGetStationsCommonInfoQuery();
  const stationsList = useMemo(()=>{
    return commonData?.result?.map(el=>el.station_id)
  }, [commonData]);

  //логика табов
  const [activeTab, setActiveTab] = useState(typeof stationId !== "undefined" ? stationId : "");
  console.log("activeTab====", activeTab, "stationsList?.[0]?.toString()", stationsList?.[0]?.toString());
  console.log("activeTab?.length === 0", activeTab?.length === 0);
  const  [getStation, { isSuccess, data }] = useLazyDkuControllerGetStationQuery();
  const [getStationSettings, {data: settings}] = useLazyDkuControllerGetStationSettingsQuery();

  useEffect(()=>{
    console.log("useeffect activeTab", activeTab);
    if (typeof stationsList !== "undefined" && stationsList?.length > 0 && (activeTab === "" || activeTab == null)){
      console.log("HERE==", activeTab);
      setActiveTab(stationsList?.[0]?.toString());
    }
  },[stationsList, activeTab])

  useEffect(()=>{
    if (typeof activeTab !== "undefined") {
      // @ts-ignore
      if (activeTab?.toString()?.length > 0) {
        getStation({stationId: activeTab as string});
        getStationSettings({stationId: activeTab as string});
        changeTabOns(activeTab as string);
      }
    }
  },[activeTab])


  console.log("stationsList",stationsList);
  /* const prevInitialData = usePrevious(initialData);
   const prevInitialSettings = usePrevious(initialSettings);
   // копия данных с апи для локального изменения
   const [settings, setSettings] = useState(initialSettings);
   const [data, setData] = useState(initialData);
   useEffect(()=>{
     if (typeof prevInitialData !== "undefined" && initialData !== prevInitialData){
       setData(initialData);
     }
   },[initialData, prevInitialData])
   useEffect(()=>{
     if (typeof prevInitialSettings !== "undefined" && initialSettings !== prevInitialSettings){
       setSettings(initialSettings);
     }
   },[initialSettings])*/


  console.log("data dku", data, activeTab, "dataSettings", settings);
  const [sendAction, result] = useDkuControllerPostStationActionMutation();
  const [sendActionSettings, resultSettings] = useDkuControllerPostStationSettingsActionMutation();
  const onChangeOnsState = (value: StationOperatingMode)=>{
    return new Promise((resolve, reject)=>{
      sendAction({
        stationId: activeTab as string,
        body: {
          action: "set_operating_mode",
          operating_mode: value as StationOperatingMode
        }
      }).unwrap().then(fulfilled => {
        console.log("fullfiled================================",fulfilled);
        resolve(fulfilled);
      }).catch(rejected => {
        console.error("rejected================================", rejected)
        resolve(rejected)
      })
    })

  }

  const onChangeSettings = (valueHigh: number, valueLow: number, valueSpeed: number) =>{
    return new Promise((resolve, reject)=>{
      sendActionSettings({
        stationId: activeTab as string,
        body: {
          setpoint_level_high: valueHigh,
          setpoint_level_low: valueLow,
          setpoint_level_change_speed: valueSpeed,
        }
      }).unwrap().then(fulfilled => {
        console.log("fullfiled================================",fulfilled);
        resolve(fulfilled);
      }).catch(rejected => {
        console.error("rejected================================", rejected)
        resolve(rejected)
      })
    })
  }

  const tabChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  return (
    <>
      <BaseContainer>
        <div className={styles.Title}>Сведения об ОНС</div>
        <div className={styles.Tabs}>
          <Tabs onChange={tabChange} activeKey={activeTab || ""}>
            {stationsList?.map((id)=>{
              return <TabPane tab={`ОНС-${id}`} key={id?.toString()} />
            })}
          </Tabs>
        </div>

        {typeof data !== "undefined" &&
        typeof settings !== "undefined" &&
        data?.result &&
        settings?.result &&
        <IntelligenceItem data={data?.result}
                          dataSettings={settings?.result}
                          onChangeOnsState={onChangeOnsState}
                          onChangeSettings={onChangeSettings}
        />
        }
       {/* {activeTab === '123' ? (
          <IntelligenceItem />
        ) : activeTab === '4' ? (
          <IntelligenceItem />
        ) : (
          <h1>Упс, что-то пошло не так!</h1>
        )}*/}
      </BaseContainer>
    </>
  );
};

export default ManagementOptions;
