import React from 'react';
import styles from './SpeedSettingPopup.module.scss';
import { InputNumber, Modal, Slider } from 'antd';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}

const SpeedSettingPopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //логика слайдера
  const marks = {
    0: '0',
    8: '8',
    16: '16',
    24: '24',
  };
  const [inputValue, setInputValue] = React.useState(1);
  const onChange = (value: number) => {
    setInputValue(value);
  };

  return (
    <>
      <Modal
        title="Скорость изменения уровня воды в канале"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Сохранить"
      >
        <div className={styles.Subtitle}>См/мин</div>
        <div className={styles.Inputs}>
          <Slider
            marks={marks}
            min={0}
            max={24}
            defaultValue={1}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
            style={{ width: 353, marginRight: 24 }}
          />
          <InputNumber
            min={1}
            max={24}
            style={{ width: 90, height: 30 }}
            value={inputValue}
            onChange={onChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default SpeedSettingPopup;
