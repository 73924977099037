import { EventedProps } from '@react-leaflet/core';
import { StationType } from 'types';
import StationPolygon from '../StationPolygon';
import {StationCommonInfoDto} from "../../../../../store/slices/dku";

type StationClusterProps<T> = {
  stations: T[];
  onsEvents?: EventedProps;
};

const StationCluster = <T extends StationCommonInfoDto>({
  stations,
  onsEvents,
}: StationClusterProps<T>) => {
  return (
    <>
      {stations.map((station, i) => {
        return (
          <StationPolygon key={i} station={station} onsEvents={onsEvents} />
        );
      })}
    </>
  );
};

export default StationCluster;
