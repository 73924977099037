import Highcharts from 'highcharts/highstock'
import HighchartsReact from "highcharts-react-official";
import {dataFormatter} from "@content/Analytics/Charts/helper";
import React, {useEffect, useState} from "react";
import StockChart from "@content/Analytics/Charts/ChartTypes";
interface Props{
  archiveData: TArchive
}
type TArchive = {
  [key: string] : number
}


export default function RainIntensity({archiveData}: Props){
  return <StockChart archiveData={archiveData}
                     color={"#08979C"}
                     title={"Интенсивность осадков, (мм/мин)"}
                     textYAxis={"Интенсивность (мм/мин)"}
  />


}
