import React, {useEffect, useState} from 'react';
import styles from './CreateUserGroupPopup.module.scss';
import {Input, Modal, notification, Pagination, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NotificationInstance } from 'antd/lib/notification';
import useNotification from '@hooks/useNotification';
import { Key } from 'antd/es/table/interface';
import { useAdminUsersControllerGetAllUsersQuery,
  useAdminUsersControllerGetAllSystemEventsNamesQuery,
  useAdminUsersControllerCreateGroupMutation,
  useAdminUsersControllerGetAllUsersIdsQuery
} from "../../../store/slices/adminEnhanced";
import {UserDto} from "../../../store/slices/adminEndpoints";
import {RoleToText} from "../../../pages/Alerts/Alerts";
import {useSearchParams} from "react-router-dom";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const columnsMembers: ColumnsType<UserDto> = [
  {
    title: 'Пользователь',
    dataIndex: 'name',
    render: (title, row) => <span className={styles.Name}>{row.firstName} {row.lastName}</span>,
  },
  {
    title: 'Тип учетной записи',
    dataIndex: 'role',
    render: (role)=><span className={styles.Role}>{RoleToText(role)}</span>
  },
];

const columnsAlerts = [
  {
    title: 'Тип оповещения',
    dataIndex: 'name',
  },
];


const CreateUserGroupPopup: React.FC<Props> = ({ visible, setVisible }) => {

  const [page, setPage] = useState<number>(1);
  const [pageEvents, setPageEvents] = useState<number>(1);
  const {data: users} = useAdminUsersControllerGetAllUsersQuery({
    getAllUsersDto: { page, limit: 10}});
   const {data: usersAll} = useAdminUsersControllerGetAllUsersIdsQuery();
  const {data: events} = useAdminUsersControllerGetAllSystemEventsNamesQuery("");
  console.log("event=", events);
  console.log("usersAll", usersAll);
  const allUsersIds = usersAll?.result ? usersAll.result : [];
  const allEventsIds = events?.result ? events.result.map((evt: any)=>evt.id) : [];

  const [createGroup] = useAdminUsersControllerCreateGroupMutation();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<Key[]>([]);
  console.log("selectedRowKeys", selectedRowKeys);
  const [selectedRowKeysTypes, setSelectedRowKeysTypes] = React.useState<Key[]>([]);
  const [counter, setCounter] = React.useState(0);
  const [title, setTitle] = React.useState('');

  const notificate = useNotification();
  console.log("users",users);
  const onSelectChange = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const onSelectTypesChange = (selectedRowKeysTypes: Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeysTypes(selectedRowKeysTypes);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: (val: any)=>{
      console.log("onSelectAll",val, allUsersIds);
      if (val){
        setTimeout(()=>{setSelectedRowKeys(allUsersIds);}, 100)
      }else{
        setTimeout(()=>{setSelectedRowKeys([]);}, 100)
      }
    },
    preserveSelectedRowKeys: true
  };
  const rowSelectionTypes = {
    selectedRowKeys: selectedRowKeysTypes,
    onChange: onSelectTypesChange,
    preserveSelectedRowKeys: true,
    onSelectAll: (val: any)=>{
      if (val){
        setTimeout(()=>{setSelectedRowKeysTypes(allEventsIds);}, 100)
      }else{
        setTimeout(()=>{setSelectedRowKeysTypes([]);}, 100)
      }
    },
  };

  const goNext = () => {
    setPage(1);
    setCounter(+1);
  };
  const clearAndClose = ()=>{
    setSelectedRowKeys([]);
    setSelectedRowKeysTypes([]);
    setTitle("")
    setPage(1);
    setCounter(0);
    setVisible(false);
  }
  const onSubmit=()=>{
    createGroup({
      name: title,
      sms: false,
      telegram: false,
      email: false,
      browser: true,
      users: selectedRowKeys?.map((el: Key)=>{return {id: +el}}),
      event_names: selectedRowKeysTypes?.map((el: Key)=>{return {id: +el}}),
    }).unwrap().then(fulfilled => {
      console.log("fullfiled================================",fulfilled);
      notificate("success", "Группа пользователей создана");
      clearAndClose();
    }).catch(rejected => {
      console.error("rejected================================", rejected)
      notificate("error", "Не получилось создать группу пользователей");
    })
  }

  return (
    <>
      <Modal
        title="Создать группу пользователей"
        centered
        visible={visible}
        onOk={counter === 0 ? goNext : onSubmit }
        onCancel={clearAndClose}
        width={815}
        okText={counter === 1 ? "Создать" : "Далее"}
        cancelText="Отмена"
      >
        <div className={styles.NameGroup}>Название группы</div>
        <Input
          placeholder="Ведите название группы"
          className={styles.NameGroupInput}
          value={title}
          onChange={(e)=>{setTitle(e.target.value)}}
        />

        {counter === 0 ? (
          <>
            <div className={styles.Members}>Участники</div>
            <Table
              rowSelection={rowSelection}
              rowKey={(el)=>{return el.id}}
              columns={columnsMembers}
              dataSource={users?.result?.items}
              pagination={{
                onChange: (page) => {
                  setPage(page);
                },
                current: users?.result?.currentPage,
                total: users?.result?.totalItems,
              }}
            />
          </>
        ) : counter === 1 ? (
          <>
            <div className={styles.Members}>Типы оповещений </div>
            <Table
              rowSelection={rowSelectionTypes}
              rowKey={(el)=>{return el.id}}
              columns={columnsAlerts}
              dataSource={events?.result as any}
              pagination={{
                current: pageEvents,
                onChange: (page) => {
                  setPageEvents(page);
                },
              }}
            />
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default CreateUserGroupPopup;
