import { ConfigProvider, notification } from 'antd';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/scss/main.scss';
import { store } from './store';
import { Provider } from 'react-redux';
import React from 'react';
import ruRU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

import './assets/scss/main.scss';

notification.config({
  placement: 'top',
  duration: 3,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
