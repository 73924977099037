import { Marker, Popup, useMapEvents } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import styles from './SensorMarker.module.scss';
import { DivIcon } from 'leaflet';
import { ReactComponent as Sensor } from './sensor.svg';
import { useRef, useState } from 'react';
import { SensorType } from 'types';

const getSensorClassname = (level: number) => {
  if (level > 70) return styles.icon + ' ' + styles.iconFifth;
  if (level > 50) return styles.icon + ' ' + styles.iconFourh;
  if (level > 30) return styles.icon + ' ' + styles.iconThird;
  if (level > 20) return styles.icon + ' ' + styles.iconSecond;
  return styles.iconFirst;
};

type SensorMarkerProps = {
  sensor: SensorType;
};

const SensorMarker: React.FC<SensorMarkerProps> = ({
  sensor,
}: SensorMarkerProps) => {
  const { level, coords, remote_level_sensor_id, sensor_target, sensor_type, sensor_name } = sensor;
  const [isVisible, setIsVisible] = useState(true);
  const map = useMapEvents({
    zoomend: (e) => {
      if (e.target.getZoom() < 14) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    },
  });

  return isVisible ? (
    <Marker
      position={coords as any}
      icon={
        new DivIcon({
          className: getSensorClassname(level),
          html: ReactDOMServer.renderToStaticMarkup(<Sensor />),
        })
      }
      zIndexOffset={1000}
    >
      <Popup
        offset={[10, -5]}
        autoClose={false}
        closeOnClick={false}
        autoPan={false}
      >
        Удаленный датчик {sensor_name}
        <br/>
        Тип датчика: {sensor_type}
        <br/>
        Предмет измерения: {sensor_target}
        <br/>
        Уровень: {level} м
      </Popup>
    </Marker>
  ) : (
    <></>
  );
};

export default SensorMarker;
