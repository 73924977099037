//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import styles from './Analytics.module.scss';
import { BaseHeaderContainer } from '@base/index';
import { Default } from '@layouts/index';
import { Button, Tabs, Empty, Spin } from 'antd';
import { GraphReportPopup } from '@modals/index';
import StationInfo from '@content/Analytics/Items/StationInfo';
import PumpGraph from '@content/Analytics/Items/PumpGraph';
import {useLazyDkuControllerGetStationQuery, useLazyDkuControllerGetArchiveDataQuery} from "../../store/slices/dkuEnhanced";
import {useDkuControllerGetStationsCommonInfoQuery} from "../../store/slices/dku";
import {capitalizeFirstLetter, usePrevious} from "../../utils/common";
import Highcharts from 'highcharts'
import HighchartsStock from 'highcharts/highstock'
import WaterLevelChannel from "@content/Analytics/Charts/WaterLevelChannel";
import RainIntensity from "@content/Analytics/Charts/RainIntensity";
import AirTemperature from "@content/Analytics/Charts/AirTemperature";
import WaterLevelUnderground from "@content/Analytics/Charts/WaterLevelUnderground";
import ConsumedEnergyChart from "@content/Analytics/Charts/ConsumedEnergy";
import {useUser} from "@hooks/index";
import {useNavigate} from "react-router-dom";
import ActualPowerPumpsChart from "@content/Analytics/Charts/ActualPowerPumpsChart";
import VisitsCount from "@content/Analytics/Charts/VisitsCount";

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
    thousandsSep: ' ',
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Ноя', 'Дек'],
    rangeSelectorZoom: "Сбросить увеличесние",
    resetZoom: "Сбросить увеличение",
    resetZoomTitle: "Сбросить увеличение"
  }
});
HighchartsStock.setOptions({
  lang: {
    decimalPoint: ',',
    thousandsSep: ' ',
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Ноя', 'Дек'],
    rangeSelectorZoom: "",
    resetZoom: "Сбросить увеличение",
    resetZoomTitle: "Сбросить увеличение"
  }
});

HighchartsStock.seriesTypes.area.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;

const { TabPane } = Tabs;

interface Props {}

type TTabs = {
  tab: string;
  key: string;
}

const Analytics: React.FC<Props> = () => {

  const user = useUser();
  const navigate = useNavigate();
  useEffect(()=>{
    if (user?.role === "observer"){
      navigate("/management/map");
    }
  },[user])
  const  [getStation, {  data: dataStationDetail }] = useLazyDkuControllerGetStationQuery();
  const { data } = useDkuControllerGetStationsCommonInfoQuery();
  const [getArchiveData] = useLazyDkuControllerGetArchiveDataQuery();
  const prevData = usePrevious(data);


  const [allArchiveData, setAllArchiveData] = useState({
    archiveUndergroundLevelChannel: {},
    archiveRainIntensity: {},
    archiveAirTemperature: {},
    archiveWaterLevel: [],
    archiveWaterLevelNames: [],
    archiveConsumedEnergy: [],
    archiveConsumedEnergyNames: [],
    archivePumpsPower: [],
    archivePumpsPowerNames: [],
    archiveVisitsCount: {}
  })

  const { archiveUndergroundLevelChannel,
    archiveRainIntensity,
    archiveAirTemperature,
    archiveWaterLevel,
    archiveWaterLevelNames,
    archiveConsumedEnergy,
    archiveConsumedEnergyNames,
    archivePumpsPower,
    archivePumpsPowerNames,
    archiveVisitsCount
  } = allArchiveData;

  console.log("allArchiveData", allArchiveData);

  const [ready, setReady] = useState(false);

  //логика табов
  const [activeTab, setActiveTab] = React.useState('');
  const activeTabRef = useRef("");
  useEffect(()=>{
    activeTabRef.current = activeTab;
  }, [activeTab])

  const [tabs, setTabs] = React.useState([] as TTabs[]);

  const tabChange = (activeKey: string) => {
    setReady(false);
    setActiveTab(activeKey);
  };

  useEffect(()=>{
    if (typeof prevData === "undefined" && typeof data !== "undefined"){
      const tabsList = data?.result?.map((el)=>{return {
        tab: `ОНС-${el.station_id}`,
        key: el.station_id
      }}) as TTabs[];
      setTabs(tabsList);
      setActiveTab(tabsList?.[0]?.key);
    }
  },[data, prevData])

  useEffect( ()=>{
    if (activeTab?.length > 0) {
      handleData(activeTab);
    }
  }, [activeTab])

  //логика модалки
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const pumpData = typeof dataStationDetail?.result !== "undefined" ? dataStationDetail.result.station.pumps.map((el)=>{
    return {
      name: capitalizeFirstLetter(el.priority),
      x: el?.operating_time || 0
    }
  }) : [];

  async function handleData(activeTab){
    console.log("handleData", activeTab);
    const wasTabChange = ()=>{
      const result = activeTab.toString() !== activeTabRef.current.toString();
      if (result) {
        console.log("WAS TAB CHANGE");
      }
      return result;
    }
    let count = 4; //количество графиков,для которых будет только 1 запрос. Чтгобы учесть остальные будет count = count + n
    getStation({stationId: activeTab}).unwrap().then(async (res)=>{
      if (res?.status !== "OK") {return}
      const data = res.result;

      const remoteIds = data?.polder?.remote_level_sensors
        ?.filter(el=>el?.sensor_target?.toLowerCase() === "канал" &&
          el?.remote_level_sensor_id?.length > 0 )
        ?.map(el=>el.remote_level_sensor_id);

      const pumpsIds = data?.station?.pumps?.map(el=>el.pump_id);
      count+=remoteIds.length;
      count+=pumpsIds.length*2;

      let ArchiveUndergroundLevelChannelData = {};
      let ArchiveRainIntensityData = {};
      let ArchiveAirTemperatureData = {};
      let ArchiveWaterLevelData = [];
      let ArchiveWaterLevelNames = [];
      let ArchiveConsumedEnergyData = [];
      let ArchiveConsumedEnergyNames = [];
      let ArchivePumpsPowerData = [];
      let ArchivePumpsPowerNames = [];
      let ArchiveVisitsCountData = [];

      const checkAllReady = (count)=>{
        console.log("checkAllReady count", count);
        if (count < 0){
          console.error("COUNT<0")
        }
        if (count === 0){
          const preparedVisitsCount = prepareVisitsCountData(ArchiveVisitsCountData);
          console.log("preparedVisitsCount", preparedVisitsCount);
          setAllArchiveData({
            archiveUndergroundLevelChannel: ArchiveUndergroundLevelChannelData,
            archiveRainIntensity: ArchiveRainIntensityData,
            archiveAirTemperature: ArchiveAirTemperatureData,
            archiveWaterLevel: ArchiveWaterLevelData,
            archiveWaterLevelNames: ArchiveWaterLevelNames ,
            archiveConsumedEnergy:  ArchiveConsumedEnergyData,
            archiveConsumedEnergyNames: ArchiveConsumedEnergyNames,
            archivePumpsPower: ArchivePumpsPowerData,
            archivePumpsPowerNames: ArchivePumpsPowerNames,
            archiveVisitsCount: preparedVisitsCount
          });
          setTimeout(()=>{setReady(true);}, 1000)
        }
      }

      if (wasTabChange()){
        return;
      }
      /*--------уровень в подземной части м---------------------------------------------------*/
      await getArchiveData({
        source_type:'station',
        source_id: activeTab,
        parameter_name: "actual_level",
        time_from: '2022-01-01',
      }).unwrap().then((res)=>{
        if (wasTabChange()){
          return;
        }
        count = count - 1;
        const mas =  Object.keys(res?.result?.data);
        console.log("actual_level length", mas?.length,  mas[mas?.length - 2]);
        ArchiveUndergroundLevelChannelData = res?.result?.data || {};
        checkAllReady(count);
      })
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*--------интенсивность осадков---------------------------------------------------*/
      await getArchiveData({
        source_type:'weatherstation',
        source_id: data?.polder?.weatherstation?.weatherstation_id?.toString(),
        parameter_name: "precipitation",
        time_from: '2022-01-01',
      }).unwrap().then((res)=>{
        if (wasTabChange()){
          return;
        }
        count = count - 1;
        ArchiveRainIntensityData = res?.result?.data || {};
        checkAllReady(count);
      })
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*---------------температура воздуха-----------------------------------------------*/
     await getArchiveData({
        source_type:'station',
        source_id: activeTab,
        parameter_name: "air_temperature",
        time_from: '2022-01-01',
      }).unwrap().then((res)=>{
        if (wasTabChange()){
          return;
        }
        count = count - 1;

        ArchiveAirTemperatureData = res?.result?.data || {};
        checkAllReady(count);
      }).catch(()=>{
      })
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*----------количество посещений--------------------------------------------------*/
      await getArchiveData({
        source_type:'station',
        source_id: activeTab,
        parameter_name: "visits_count",
        time_from: '2022-01-01',
      }).unwrap().then((res)=>{
        if (wasTabChange()){
          return;
        }
        count = count - 1;
        ArchiveVisitsCountData = res?.result?.data || {};
        checkAllReady(count);
      })
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*---уровень в канале-------------------------------------------------------------*/

      let remoteData = [];
      let names = [];
      const n = remoteIds.length;

      const remote = new Promise((resolve)=>{
        let i = 0;
        remoteIds.forEach((id, index)=>{
          getArchiveData({
            source_type:'remote_level_sensor',
            source_id: id,
            parameter_name: "level_baltic_system",
            time_from: '2022-01-01',
          }).unwrap().then((res)=>{
            if (wasTabChange()){
              resolve();
              return;
            }
            i++;
            if (typeof res?.result?.data !== "undefined"){
              remoteData.push(res?.result?.data);
              names.push(data?.polder?.remote_level_sensors.find((el)=>el?.remote_level_sensor_id === id)?.sensor_name
                || `Датчик ${id}`)
            }
            if (i === n){
              resolve();
            }
          })
        });
      });
      await remote;
      if (wasTabChange()){
        return;
      }
      count = count - n;
      ArchiveWaterLevelData = remoteData || [];
      ArchiveWaterLevelNames = names;
      checkAllReady(count);
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*---потребленная энергия насоса (ConsumedEnergy chart)---------------------------*/
      let consumedData = [];
      let consumedNames = [];
      const n1 = pumpsIds.length;

      const consumed = new Promise((resolve)=>{
        let i = 0;
        pumpsIds.forEach((id, index)=>{
          getArchiveData({
            source_type:'pump',
            source_id: id,
            parameter_name: "consumed_energy",
            time_from: '2022-01-01',
          }).unwrap().then((res)=>{
            if (wasTabChange()){
              resolve();
              return;
            }
            i++;
            if (typeof res?.result?.data !== "undefined"){
              consumedData.push(res?.result?.data);
              consumedNames.push(`${capitalizeFirstLetter(data?.station?.pumps?.[index]?.priority)}`)
            }
            if (i === n1){
              resolve();
            }
          })
        });
      });
      await consumed;
      if (wasTabChange()){
        return;
      }
      count = count - n1;
      ArchiveConsumedEnergyData = consumedData || [];
      ArchiveConsumedEnergyNames = consumedNames;
      checkAllReady(count);
      /*--------------------------------------------------------------------------------*/
      if (wasTabChange()){
        return;
      }
      /*---Мощность насоса (actual_power pumps chart)-----------------------------------*/
      let pumpPowerData = [];
      let pumpNames = [];
      const n3 = pumpsIds.length;
      const pumpPower = new Promise((resolve)=>{
        let i = 0;
        pumpsIds.forEach((id, index)=>{
          getArchiveData({
            source_type:'pump',
            source_id: id,
            parameter_name: "actual_power",
            time_from: '2022-01-01',
          }).unwrap().then((res)=>{
            if (wasTabChange()){
              resolve();
              return;
            }
            i++;
            if (typeof res?.result?.data !== "undefined"){
              pumpPowerData.push(res?.result?.data);
              pumpNames.push(`${capitalizeFirstLetter(data?.station?.pumps?.[index]?.priority)}`)
            }
            if (i === n3){
              resolve();
            }
          })
        });
      });
      await pumpPower;
      if (wasTabChange()){
        return;
      }
      count = count - n3;
      ArchivePumpsPowerData = pumpPowerData || [];
      ArchivePumpsPowerNames = pumpNames;
      checkAllReady(count);
      /*--------------------------------------------------------------------------------*/

    })
  }

  return (
    <>
      <BaseHeaderContainer className={styles.BaseHeaderContainer}>
        <div className={styles.Header}>
          <div className={styles.Title}>Аналитика и отчетность</div>
          <Button type="primary" onClick={showModal}>
            Сформировать отчёт
          </Button>
        </div>
      </BaseHeaderContainer>

      <div className={styles.Content}>
        <div className={styles.Tabs}>
          <Tabs onChange={tabChange}>
            {tabs.map((tab)=>{
              return <TabPane tab={tab.tab} key={tab.key} />
            })}
          </Tabs>
        </div>

        <div className={styles.Container}>
          <StationInfo name={`ОНС-${dataStationDetail?.result?.station?.station_id || ""}`}
                       state={dataStationDetail?.result?.station?.state || ""}
                       count={dataStationDetail?.result?.station?.visits_count || 0}
                       location={dataStationDetail?.result?.station?.address || ""}
                       operatingMode={dataStationDetail?.result?.station?.operating_mode || ""}

          />
          {!ready ? <LoadingData/> : <>
            {
              archiveWaterLevel && archiveWaterLevel?.length > 0 &&
              (
                <div className={styles.wrapChart}>
                  <WaterLevelChannel archiveData={archiveWaterLevel} names={archiveWaterLevelNames}/>
                </div>
              )
            }
            {
              Object.keys(archiveRainIntensity)?.length > 0 &&
              (<div className={styles.wrapChart}>
                <RainIntensity archiveData={archiveRainIntensity}/>
              </div>)
            }
            {
              Object.keys(archiveAirTemperature).length > 0 &&
              (<div className={styles.wrapChart}>
                <AirTemperature archiveData={archiveAirTemperature}/>
              </div>)
            }
            {
              Object.keys(archiveUndergroundLevelChannel).length > 0 &&
              (<div className={styles.wrapChart}>
                <WaterLevelUnderground archiveData={archiveUndergroundLevelChannel}/>
              </div>)
            }

            {pumpData?.length > 0 && <PumpGraph data={pumpData} /> }

            {archiveConsumedEnergy?.length > 0 && (
              <div className={styles.wrapChart}>
                <ConsumedEnergyChart archiveData={archiveConsumedEnergy} names={archiveConsumedEnergyNames}/>
              </div>
            )}

            {archivePumpsPower?.length > 0 && (
              <div className={styles.wrapChart}>
                <ActualPowerPumpsChart archiveData={archivePumpsPower} names={archivePumpsPowerNames}/>
              </div>
            )}

            {
              Object.keys(archiveVisitsCount).length > 0 &&
              (<div className={styles.wrapChart}>
                <VisitsCount archiveData={archiveVisitsCount}/>
              </div>)
            }

          </>}

          {/*  <RainfullGraph />
          <AirTempGraph />*/}
          {/* <GroundLevelGraph />
          <PipeLevelGraph />*/}
          {/*    <BottomLevelGraph />*/}
          {/*<PowerGraph />
          <CurrentGraph />*/}
        </div>
      </div>
      {isModalVisible && <GraphReportPopup
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        stationId={+activeTab}
      /> }

    </>
  );
};

export default Analytics;




const LoadingData = () => (
  <div className={styles.EmptyWrap}>
    <Empty

      imageStyle={{
        height: 60,
      }}
      description={
        <span style={{color: "#A9A9A9"}}>
         Данные загружаются &nbsp; &nbsp;<Spin className={styles.Spin}/>
        </span>
      }
    >

    </Empty>
  </div>
);


const prepareVisitsCountData = (dataArchive)=>{
  if (typeof dataArchive === "undefined"){return undefined}
  const data = Object.entries(dataArchive);
  if (typeof data[0] === "undefined" || typeof data[0][0] === "undefined"){
    return dataArchive
  }
  console.log("prepareVisitsCountData 2 data length", data?.length);

  const getDay = (dateString)=>{
    const date = new Date(dateString);
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    return new Date(year, month, day)
  }

  const result = {};

  let i = 1;
  let n = data.length; 
  let currentDay = getDay(data[0][0]); //2022-05-07T10:44:13
  let currentVal = data[0][1];
  let count = 0;
  while (true) {
    try {
      if (i > n) {
        break;
      }
      if (i === n){
        const prevVal = data[i-1][1];
        if (count > 0){
          result[currentDay.toISOString()] = prevVal - currentVal;
        }else{
          result[currentDay.toISOString()] = currentVal;
        }
        break;
      }
      const date = data[i][0];
      const val = data[i][1];
      const prevVal = data[i-1][1];
      const day = getDay(date);

      if (day.getTime() == currentDay.getTime()) {
        i++;
        count++;
      } else {
        if (count > 0){
          result[currentDay.toISOString()] = prevVal - currentVal;
          currentDay = getDay(data[i][0]);
          currentVal = val;
        }else{
          result[currentDay.toISOString()] = currentVal;
          currentDay = getDay(data[i][0]);
          currentVal = val;
        }
        i++;
        count = 0;
      }
    }catch(err){
      console.error("ERROR in prepareVisitsCountData:" ,err)
      break;
    }
  }
  console.log("result prepareVisitsCountData",result);
  return result;
}