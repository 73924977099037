import React, {useEffect} from 'react';
import { BaseIcon } from '@base/index';
import { ALL_ICONS } from '@constants/icons';
import styles from './PasswordRecovery.module.scss';

import { Form, Button, Input } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useNotification from "@hooks/useNotification";
import useCountDown from 'react-countdown-hook';
import {useAuthControllerPasswordRecoveryMutation} from "../../store/slices/authEndpoints"
import {MaskedInput} from "antd-mask-input";
import {usePrevious} from "../../utils/common";

const PasswordRecovery: React.FC = () => {
  let navigate = useNavigate();
  const notificate = useNotification();
  const [step, setStep] = React.useState<number>(0);
  const [timer, setTimer] = React.useState<number>(46);
  const [phone, setPhone] = React.useState(undefined as string | undefined);
  const [code, setCode] = React.useState(undefined as string | undefined);

  const [errorPhone, setErrorPhone] = React.useState("");
  const [errorCode, setErrorCode] = React.useState("");

  const [sendRecovery] =  useAuthControllerPasswordRecoveryMutation();


  const loginHandler = () => {
    navigate('/');
  };

  const sendCode = async (phone:string) =>{
    if (phone?.length === 0){
      setErrorPhone("Введите номер телефона");
      return;
    }
    const reg = /[+][7]\s\d{3}\s\d{3}[-]\d{2}[-]\d{2}/;
    const res = reg.test(phone);
    if (!res){
      setErrorPhone("Введите корректный номер телефона");
      return;
    }
    const phoneUnmasked = phone.replace(/[^\d]/g, '')?.slice(1);
    sendRecovery({passwordRecoveryRequestDto:{phone: phoneUnmasked}}).unwrap().then((result: any) => {
      if (result?.status === "ERROR"){
        setErrorPhone(result?.messages?.[0] || "Неправильный телефон")
      }else{
        setTimer(result?.result);
        setStep(1);
        setTimeout(()=>{setTimer(0)},2000) //для возможного рестарата таймера
      }
    }).catch(rejected => {
      notificate('error', rejected.data.message);
    })
  }
  const confirmCode = async (phone:string, code: string) =>{
    if (code?.length === 0){
      setErrorCode("Введите код из смс")
    }
    const phoneUnmasked = phone.replace(/[^\d]/g, '')?.slice(1);
    sendRecovery({passwordRecoveryRequestDto: {phone: phoneUnmasked, code:code}}).unwrap().then((result: any) => {
      if (result?.status === "ERROR"){
        setErrorCode(result?.messages?.[0] || "Неправильный код")
      }else {
        setStep(2);
      }
    }).catch(rejected => {
      notificate('error', rejected.data.message);
    })
  }

  return (
    <div className={styles.PasswordRecovery}>
      <BaseIcon
        icon={ALL_ICONS.LOGO}
        viewBox="0 0 43 43"
        className={styles.IconLogo}
      />
      <div className={styles.Title}>Мелиорация</div>
      <div className={styles.Subtitle}>управление системами</div>
      <div className={styles.Description}>
        Панель управления мелиоративной системой Калининградской области
      </div>

      {step === 0 && <Step0 onSubmit={sendCode} phone={phone} setPhone={setPhone} errorPhone={errorPhone}/>}
      {step === 1 && <Step1 onSubmit={confirmCode}
                            phone={phone}
                            setPhone={setPhone}
                            errorPhone={errorPhone}
                            code={code}
                            setCode={setCode}
                            errorCode={errorCode}
                            timer={timer}
                            onResendCode={sendCode}
      />}
      {step === 2 && <Step2 loginHandler={loginHandler}/>}

      <BaseIcon
        icon={ALL_ICONS.WAVE}
        viewBox="0 0 1440 250"
        className={styles.Wave}
      />
      <BaseIcon
        icon={ALL_ICONS.WAVE_TABLET}
        viewBox="0 0 768 301"
        className={styles.WaveTablet}
      />
    </div>
  );
};

export default PasswordRecovery;

export type TSubmitStep0 = {
  onSubmit: (phone: string)=>void;
  phone: string | undefined;
  errorPhone: string;
  setPhone: (phone: string)=>void;
}
export type TSubmitStep1 = {
  onSubmit: (phone: string, code: string)=>void;
  phone: string | undefined;
  code: string | undefined;
  errorPhone: string;
  errorCode: string;
  setPhone: (phone: string)=>void;
  setCode: (code: string)=>void;
  timer: number;
  onResendCode: (phone: string)=>void;
}
export type TLogin = {
  loginHandler: ()=>void
}

const Step0 = ({onSubmit, phone, errorPhone, setPhone}: TSubmitStep0)=>{
  return(
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
    >

        <div className={styles.FormContent}>
          <div className={styles.FormTitle}>Восстановление пароля</div>
          <div className={styles.FormSubtitle}>Введите номер телефона на который зарегистрирована учетная запись.</div>

          <Form.Item
            name="phone"
            { ...(
              errorPhone?.length > 0 && {
                help: errorPhone,
                validateStatus: 'error',
              }
            )
            }


          >
            <MaskedInput mask={'+7 000 000-00-00'}
                         prefix={<PhoneOutlined className="site-form-item-icon" />}
                         value={phone}
                         className={styles.Input}
                         onChange={(e)=>{setPhone(e.target.value)}}
                         placeholder="Введите номер телефона"

            />
           {/* <Input
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              value={phone}
              onChange={(e)=>{setPhone(e.target.value)}}
              placeholder="+7 996 401-36-92"
              className={styles.Input}
            />*/}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.Button}
              onClick={()=>{onSubmit(phone as string)}}
            >
              Отправить
            </Button>
          </Form.Item>
        </div>

    </Form>
  )

}

const Step1 = ({onSubmit, phone, errorPhone, setPhone, code, errorCode, setCode, timer, onResendCode}: TSubmitStep1)=>{

  console.log("Step1 phone", phone);
  const initialTime = 60 * 1000; // initial time in milliseconds, defaults to 60000
  const interval = 1000; // interval to change remaining time amount, defaults to 1000

  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const prevTimer = usePrevious(timer);
  useEffect(()=>{
    if (timer > 0 && typeof prevTimer === "undefined" ||
      typeof prevTimer !== "undefined" && prevTimer!==timer && timer > 0
    ){
        start(timer * 1000)
    }
  },[timer, prevTimer])

  return(
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
    >

        <div className={styles.FormContent}>
          <div className={styles.FormTitle}>Восстановление пароля</div>
          <div className={styles.FormSubtitle}>Введите номер телефона на который зарегистрирована учетная запись.</div>

          <Form.Item
            name="phone"
            { ...(
              errorPhone?.length > 0 && {
                help: errorPhone,
                validateStatus: 'error',
              }
            )
            }
          >
            <MaskedInput mask={'+7 000 000-00-00'}

                         prefix={<PhoneOutlined className="site-form-item-icon" />}
                         value={phone}
                         defaultValue={phone}
                         className={styles.Input}
                         onChange={(e)=>{setPhone(e.target.value)}}
                         placeholder="Введите номер телефона"

            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Неправильный код' }]}
            className={styles.Input}
            { ...(
              errorCode?.length > 0 && {
                help: errorCode,
                validateStatus: 'error',
              }
            )
            }
          >
            <Input
              placeholder="8239"
              value={code}
              onChange={(e)=>{setCode(e.target.value)}}
            />
          </Form.Item>

          <div className={`${styles.Message} ${timeLeft === 0 ? styles.MessageActive : ""}`}
               onClick={ ()=>{
                 if (timeLeft === 0){
                   onResendCode(phone as string)
                 }
               }}
          >
            Отправить код повторно {timeLeft > 0 && `(${timeLeft/1000} сек.)` }
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.Button}
              onClick={()=>{onSubmit(phone as string, code as string)}}
            >
              Отправить
            </Button>
          </Form.Item>
        </div>

    </Form>
  )

}

const Step2 = ({loginHandler}: TLogin)=>{
  return(
    <div
      className={`${styles.FormContent} ${styles.FormContentRecovery}`}
    >
      <BaseIcon
        icon={ALL_ICONS.RECOVERY_SUCCESS}
        viewBox="0 0 38 38"
        className={styles.IconRecoverySuccess}
      />
      <div className={styles.FormTitleRecovery}>
        Пароль успешно изменён
      </div>
      <div className={styles.FormSubtitleRecovery}>
        Новый пароль отправлен на почту
      </div>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.Button}
          onClick={loginHandler}
        >
          Войти
        </Button>
      </Form.Item>
    </div>
  )
}