// @ts-nocheck
import { BaseContainer, BaseHeaderContainer } from '@base/index';
import { Default } from '@layouts/index';
import React from 'react';
import styles from './Alerts.module.scss';
import {Button, Table, Switch, Select} from 'antd';
import {
  UsergroupAddOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { CreateUserGroupPopup, EditUserGroupPopup } from 'components/modals';
import {useAdminUsersControllerGetAllGroupsQuery,
  useAdminUsersControllerEditGroupMutation
} from "../../store/slices/adminEnhanced"
import useNotification from "@hooks/useNotification";
import {useUser} from "@hooks/index";
interface Props {}

export const TRolesArr = ['admin', 'observer', 'api', 'operator', 'support'];
export type TRoles = TRolesArr[0] | TRolesArr[1] | TRolesArr[2] | TRolesArr[3] | TRolesArr[4];

export const RoleToText = (role: TRoles) => {
  switch (role){
    case "admin":
      return "Администратор";
    case "observer":
      return "Наблюдатель"
    case "operator":
      return "Оператор"
    case "support":
      return "Техподдержка"
    case "api":
      return "Пользователь апи"
    default:
      return role
  }
}


const Alerts: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(0);

  const notificate = useNotification();
  const user = useUser();
  console.log("USER=",user);

  const {data: data2} = useAdminUsersControllerGetAllGroupsQuery({page: 1, limit: 100 });
  console.log("data2", data2);

  const [editGroup] = useAdminUsersControllerEditGroupMutation();

  const toggleAlert = (name: string, id: number) =>{
      return async (val: boolean)=>{
        console.log("toggleAlert:", name, id, val);
        editGroup({id: id, data:{
            [name]: val
          }}).unwrap().then(fulfilled => {
          console.log("fullfiled================================",fulfilled);
        }).catch(rejected => {
          console.error("rejected================================", rejected)
          notificate("error", "Не удалось обновить настройки группы")
        })
      }
  }
  const columns = [
    {
      title: 'Тип учетной записи',
      dataIndex: 'name',
      key: 'name',
      width: 464,
      render: (text, row) => (
        <div className={styles.Type}>
          <span className={styles.TypeTitle}>{RoleToText(text)}</span>
          {TRolesArr.indexOf(text) === -1 &&
          <span className={styles.TypeAction}>
              {user.role === "admin" &&
          <EditOutlined onClick={()=>{
            setEditId(row.id);
            setVisibleEdit(true);
          }}/> }
        </span>
          }
        </div>
      ),
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      key: 'email',
      width: 160,
      render: (checked, row) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={checked}
          checked={checked}
          onChange={toggleAlert("email", row.id)}
        />
      ),
    },
    {
      title: 'SMS',
      dataIndex: 'sms',
      key: 'sms',
      width: 160,
      render: (checked, row) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={checked}
          checked={checked}
          onChange={toggleAlert("sms", row.id)}
        />
      ),
    },
    {
      title: 'Telegram',
      key: 'telegram',
      dataIndex: 'telegram',
      width: 160,
      render: (checked, row) => {
        console.log("checked telegram", checked);
        return (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={checked}
          checked={checked}
          onChange={toggleAlert("telegram", row.id)}
        />
      )},
    },
    {
      title: 'Браузер',
      dataIndex: 'browser',
      key: 'browser',
      width: 160,
      render: (checked, row) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={checked}
          onChange={toggleAlert("browser", row.id)}
        />
      ),
    },
  ];
  return (
    <>
      <BaseHeaderContainer>
        <div className={styles.Title}>Оповещения</div>
        {user.role === "admin" &&
          <Button
            icon={<UsergroupAddOutlined/>}
            onClick={() => setVisible(true)}
          >
            Создать группу
          </Button>
        }
      </BaseHeaderContainer>

      <BaseContainer>
        <div className={styles.AlertsContent}>
          <div className={styles.AlertsTitle}>
            Настройки оповещений по типам пользователей
          </div>
          <div className={styles.AlertsSubtitle}>
            Учетные записи пользователей должны содержать соответствующие
            контактные данные.
          </div>
          <div className={styles.AlertsTable}>
            {typeof data2?.result !== "undefined" && data2?.result?.items?.length > 0 &&
              <Table
                columns={columns}
                dataSource={data2?.result?.items?.filter((el)=>{return el.name !== 'api'})}
                pagination={false}
                scroll={{x: 1300}}
              />
            }
          </div>
        </div>
      </BaseContainer>

      {visible &&
        <CreateUserGroupPopup visible={visible}
                              setVisible={setVisible}/>
      }

      {visibleEdit &&
        <EditUserGroupPopup visible={visibleEdit}
                            setVisible={setVisibleEdit}
                            id={editId}
                            data={data2?.result?.items?.find((el) => el.id == editId)}/>
      }
    </>
  );
};

export default Alerts;
