import { Button, DatePicker, Space, Table } from 'antd';
import axios from "axios";
import { ColumnsType } from 'antd/lib/table';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import {Modal} from "antd";
import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  SystemEventDto,
  SystemEventFilterDto,
  SystemEventSortingDto,
} from 'store/slices/adminEndpoints';
import { useAdminEventsControllerGetSystemEventsQuery } from 'store/slices/adminEnhanced';
import { useLazyDkuControllerGetEventsPhotoQuery } from 'store/slices/dku';
import { camelToSnakeCase } from 'utils';
import styles from '../EventLog.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {useAppSelector} from "../../../store";
import {selectNewNotices} from "../../../store/slices/navbarSlice";

const { RangePicker } = DatePicker;

export const statusToText = (status: 'info' | 'critical' | 'service' | 'error' | 'warning') => {
  switch (status){
    case "critical":
      return "Критическое";
    case "info":
      return "Информационное"
    case "service":
      return "Сервисное"
    case "error":
      return "Авария"
    case "warning":
      return "Предупредительное"
  }
}
export enum SourceTypeEnum {
  service = 'service',
  polder = 'polder',
  station = 'station',
  pump = 'pump',
  levelSensor = 'level_sensor',
  remoteLevelSensor = 'remote_level_sensor',
  weatherStation = 'weatherstation',
}
export const sourceToText = (source: 'service' | 'polder' | 'station' | 'pump' | 'level_sensor' | 'remote_level_sensor' | 'weatherstation') => {
  switch (source){
    case "service":
      return "Техническая служба";
    case "polder":
      return "Польдер"
    case "station":
      return "Станция"
    case "pump":
      return "Насос"
    case "level_sensor":
      return "Датчик уровня"
    case "remote_level_sensor":
      return "Удалённый датчик уровня"
    case "weatherstation":
      return "Погодная станция"
  }
}
console.log("statusToText", statusToText("critical"));

const SystemEvents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const date = Number(searchParams.get('date'));
  useEffect(() => {
    if (!page) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, []);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<
    SorterResult<SystemEventDto> | SorterResult<SystemEventDto>[]
  >({});
  const [filter, setFilter] = useState<SystemEventFilterDto[]>([]);
  const [sorting, setSorting] = useState<SystemEventSortingDto[]>([]);

  const { data, refetch } = useAdminEventsControllerGetSystemEventsQuery({
    getSystemEventsDto: { limit: 10, filter, sorting, page },
  });
  const refetchInterval = async () => {
    try {
      await refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(refetchInterval , 10000);
    }
  };
  useEffect(()=>{
    refetchInterval();
  }, [])
  const [getPhoto] = useLazyDkuControllerGetEventsPhotoQuery();
  const navigate = useNavigate();

  const newNotices = useAppSelector(selectNewNotices);
  console.log(newNotices, "newNotices");
  useEffect(()=>{
    console.log("refetch new notices system", newNotices);
    refetch();
  },[newNotices])

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorting: SorterResult<SystemEventDto> | SorterResult<SystemEventDto>[]
  ) => {
    console.log('Various parameters:', pagination, filters, sorting);
    setFilteredInfo(filters);
    const filterParams = [
      ...Object.entries(filters)
        .filter(([_, value]) => value)
        .reduce((acc, [field, search]) => {
          if (!Array.isArray(search)) {
            acc.push({
              field: camelToSnakeCase(field),
              search: search || undefined,
            } as SystemEventFilterDto);
            return acc;
          }

          const multipleFields = search.map(
            (label) =>
              ({
                field: camelToSnakeCase(field),
                search: label as unknown,
              } as SystemEventFilterDto)
          );
          acc.push(...multipleFields);

          return acc;
        }, [] as Array<SystemEventFilterDto>),
    ];
    setFilter(filterParams);
    setSortedInfo(sorting);

    const sortingParams = (
      Array.isArray(sorting)
        ? sorting.map(({ columnKey: sort, order }) => ({
            sort: sort && camelToSnakeCase(sort as string),
            order: order && order === 'ascend' ? 'ASC' : 'DESC',
          }))
        : [
            {
              sort:
                sorting.columnKey &&
                camelToSnakeCase(sorting.columnKey as string),
              order: sorting.order
                ? sorting.order === 'ascend'
                  ? 'ASC'
                  : 'DESC'
                : undefined,
            },
          ]
    ).filter(({ sort, order }) => sort && order);
    setSorting([...(sortingParams as SystemEventSortingDto[])]);

    const url = new URLSearchParams();
    url.append('page', pagination.current?.toString() || '1');
    for (const { field, search } of filterParams) {
      if (field && search) {
        url.append(field, search);
      }
    }
    for (const { sort, order } of sortingParams) {
      if (sort && order) {
        url.append(sort, order);
      }
    }
    navigate('?' + url.toString(), { replace: true });
  };

  const location = useLocation();
  const openModalPhoto = Modal.info;
  const onOpenPhoto = async (fileName: string)=>{
    console.log("fileName", fileName);
    getPhoto({photo: fileName}).then(fulfilled => {
      console.log("fullfiled================================",fulfilled);
      openModalPhoto({
        icon: null,
        okText: "Закрыть",
        content: <div><img style={{maxWidth: "100%"}} src={`${fulfilled?.data}`} alt=""/></div>
      })
    }).catch(rejected => {
      console.error("rejected================================", rejected)
    })
  }
  /*const onOpenPhotoOld = async (url: string)=>{
    const res = await axios.get(`dku/photo/1.jpg`, {
      //baseURL: process.env.baseUrl,
      baseURL: "https://meliodev.beetbarrel.ru/api/",
      responseType: 'blob',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    const photo = res?.data;
    const photo2 = URL.createObjectURL(photo);
    console.log("photo==",res);
    if (res?.status == 200){
      openModalPhoto({
        icon: null,
        okText: "Закрыть",
        content: <div><img  style={{maxWidth: "100%"}} src={`${photo2}`} alt=""/></div>
      })
    }

  }*/
  useEffect(() => {
    const filterParams: SystemEventFilterDto[] = [];
    const sortingParams: SystemEventSortingDto[] = [];

    for (const key of searchParams.entries()) {
      if (
        key[0] === 'date' ||
        key[0] === 'event_name' ||
        key[0] === 'event_type' ||
        key[0] === 'source_type'
      ) {
        if (key[1] !== 'ASC' && key[1] !== 'DESC') {
          filterParams.push({
            field: key[0],
            search: key[1],
          });
        }
      }
      if (key[0] === 'date') {
        if (key[1] === 'ASC' || key[1] === 'DESC') {
          sortingParams.push({
            sort: key[0],
            order: key[1],
          });
        }
      }
    }

    setFilter(filterParams);
    setSorting(sortingParams);
  }, [location]);

  const columnsSystem: ColumnsType<SystemEventDto> = [
    {
      title: 'Дата и время',
      dataIndex: 'date',
      key: 'date',
      width: 186,
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'date' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      sortOrder: sorting[0]
        ? sorting[0].order === 'ASC'
          ? 'ascend'
          : 'descend'
        : undefined,
      sorter: (a, b) => {
        return 0;
      },
      ellipsis: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const startDate =
          selectedKeys[0] &&
          moment((selectedKeys[0] as string).split(',')[0]).isValid()
            ? moment((selectedKeys[0] as string).split(',')[0])
            : null;
        const endDate =
          selectedKeys[0] &&
          moment((selectedKeys[0] as string).split(',')[1]).isValid()
            ? moment((selectedKeys[0] as string).split(',')[1])
            : null;

        return (
          <div className={styles.RangePickerContainer}>
            <RangePicker
              onCalendarChange={(_date, dateString) => {
                setSelectedKeys([dateString.toString()]);
              }}
              value={[startDate, endDate]}
              className={styles.RangePicker}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Поиск
              </Button>
              <Button
                onClick={() => {
                  setSelectedKeys([]);
                  if (clearFilters) {
                    clearFilters();
                  }
                  const idx = filter.findIndex((el)=>el.field === "date");
                  const copyFilter = [...filter.slice(0, idx), ...filter.slice(idx+1)];
                  setTimeout(()=>{
                    setFilter(copyFilter);
                    confirm({ closeDropdown: true });
                  }, 0)
                }}
                size="small"
                style={{ width: 90 }}
              >
                Сбросить
              </Button>
            </Space>
          </div>
        );
      },
      render: (date) => (
        <span className={styles.Name}>
          {new Date(date).toLocaleDateString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
          {' • '}
          {new Date(date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </span>
      ),
    },
    {
      title: 'Название события',
      dataIndex: 'event_name',
      key: 'eventName',
      width: 290,
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'event_name' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
      render: (eventName) => <span className={styles.Name}>{eventName}</span>,
      filters: [
        { text: 'Потеря связи с ОНС', value: 'Потеря связи с ОНС' },
        { text: 'Восстановление связи с ОНС', value: 'Восстановление связи с ОНС' },
        { text: 'Срабатывание охранной сигнализации', value: 'Срабатывание охранной сигнализации' },
        { text: 'Постановка на охрану', value: 'Постановка на охрану' },
        { text: 'Снятие с охраны', value: 'Снятие с охраны' },
        { text: 'Дверь открыта', value: 'Дверь открыта' },
        { text: 'Дверь закрыта', value: 'Дверь закрыта' },
        { text: 'Окно открыто', value: 'Окно открыто' },
        { text: 'Окно закрыто', value: 'Окно закрыто' },
        { text: 'Окно разбито', value: 'Окно разбито' },
        { text: 'Щит открыт', value: 'Щит открыт' },
        { text: 'Щит закрыт', value: 'Щит закрыт' },
        { text: 'Присутствие в здании ОНС', value: 'Присутствие в здании ОНС' },
        { text: 'Тревога системы видеонаблюдения', value: 'Тревога системы видеонаблюдения' },
        { text: 'Превышение аварийного уровня воды в подземной части ОНС', value: 'Превышение аварийного уровня воды в подземной части ОНС' },
        { text: 'Превышение предупредительного уровня воды в подземной части ОНС', value: 'Превышение предупредительного уровня воды в подземной части ОНС' },
        { text: 'Оптимальный уровень воды в подземной части ОНС', value: 'Оптимальный уровень воды в подземной части ОНС' },
        { text: 'Авария датчика', value: 'Авария датчика' },
        { text: 'Датчик в норме', value: 'Датчик в норме' },
        { text: 'Датчик в ремонте', value: 'Датчик в ремонте' },
        { text: 'Датчик выведен из ремонта', value: 'Датчик выведен из ремонта' },
        { text: 'Потеря связи с удаленным датчиком', value: 'Потеря связи с удаленным датчиком' },
        { text: 'Восстановление связи с удаленным датчиком', value: 'Восстановление связи с удаленным датчиком' },
        { text: 'Открытие дверцы шкафа', value: 'Открытие дверцы шкафа' },
        { text: 'Закрытие дверцы шкафа', value: 'Закрытие дверцы шкафа' },
        { text: 'Шкаф опрокинут', value: 'Шкаф опрокинут' },
        { text: 'Превышение аварийного уровня воды в мелиоративном канале', value: 'Превышение аварийного уровня воды в мелиоративном канале' },
        { text: 'Превышение предупредительного уровня воды в мелиоративном канале', value: 'Превышение предупредительного уровня воды в мелиоративном канале' },
        { text: 'Уровень воды в мелиоративном канале в пределах нормы', value: 'Уровень воды в мелиоративном канале в пределах нормы' },
        { text: 'Авария удаленного датчика', value: 'Авария удаленного датчика' },
        { text: 'Удаленный датчик в норме', value: 'Удаленный датчик в норме' },
        { text: 'Удаленный датчик в ремонте', value: 'Удаленный датчик в ремонте' },
        { text: 'Удаленный датчика выведен из ремонта', value: 'Удаленный датчика выведен из ремонта' },
        { text: 'Запуск насоса', value: 'Запуск насоса' },
        { text: 'Останов насоса', value: 'Останов насоса' },
        { text: 'Авария насоса', value: 'Авария насоса' },
        { text: 'Насос в ремонте', value: 'Насос в ремонте' },
        { text: 'Насос выведен из ремонта', value: 'Насос выведен из ремонта' },
        { text: 'Затопление польдера', value: 'Затопление польдера' },
        { text: 'Ожидается затопление польдера', value: 'Ожидается затопление польдера' },
      ],
    },
    {
      title: 'ID польдера',
      dataIndex: 'station_id',
      key: 'station_id',
      width: 95,
    },
    {
      title: 'Источник события',
      dataIndex: 'source_type',
      key: 'sourceType',
      width: 250,
      filters: [
        { text: 'Станция', value: 'station' },
        { text: 'Насос', value: 'pump' },
        { text: 'Польдер', value: 'polder' },
        { text: 'Датчик уровня', value: 'level_sensor' },
        { text: 'Удалённый датчик уровня', value: 'remote_level_sensor' },
        { text: 'Погодная станция', value: 'weatherstation' },
        { text: 'Техническая служба', value: 'service' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'source_type' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
      render: (source, row) => {
        console.log("source", source, "row", row);
        return <span className={styles.EventType}>
          {sourceToText(source)}
        </span>
      },
    },
    {
      title: 'ID источника',
      dataIndex: 'source_id',
      key: 'source_id',
      width: 95,
    },
    {
      title: 'Тип события',
      dataIndex: 'event_type',
      key: 'eventType',
      width: 240,
      filters: [
        { text: 'Авария', value: 'error' },
        { text: 'Информационное', value: 'info' },
        { text: 'Предупредительное', value: 'warning' },
        { text: 'Критическое', value: 'critical' },
        { text: 'Обслуживание', value: 'service' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'event_type' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
      render: (status, row) => {
        console.log("status", status, "row", row);
        return <span className={styles.EventType}>
          <span
            className={`${styles.Marker} ${
              status === 'Информационное'
                ? styles.Blue
                : status === 'Предупредительное'
                ? styles.Orange
                : status === 'Авария'
                  ? styles.Red
                  : ''
            }`}
          ></span>
          {statusToText(status)}
        </span>
      },
    },
    {
      title: 'Содержание сообщения',
      dataIndex: 'data',
      key: 'messageContent',
      width: 189,
      ellipsis: true,
      render: (data: any) => {
        console.log("data===", data);
        const photoList = data?.photo_list;
        const floodList = data?.flood_remote_sensor_list;
        if (typeof  photoList !== "undefined"){
          return (
            <>
              {Array.isArray(photoList)
                ? photoList.map((item, index) => {
                  return (
                    <a
                      key={index}
                      onClick={()=>{onOpenPhoto(`${item?.replace("/api", "")}`)}}
                      className={styles.Link}
                    >
                      фото {index + 1}
                    </a>
                  );
                })
                : data}
            </>
          );
        }
        if (typeof  floodList !== "undefined"){
          return (
            <>
              {Array.isArray(floodList)
                ? <>
                  ids:
                  {floodList.join(",")}
                </>
                : floodList}
            </>
          );
        }

        return "";
      },
    },
  ];

  return (
    <Table
      columns={columnsSystem}
      dataSource={data?.result?.items}
      onChange={handleChange}
      scroll={{ x: 1500 }}
      className="UsersTable"
      pagination={{
        onChange: (page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        },
        current: data?.result?.currentPage,
        total: data?.result?.totalItems,
      }}
    />
  );
};

export default SystemEvents;
