import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Alerts, Analytics, EventLog, Login, Management, PasswordRecovery, Users,} from 'pages';
import './App.scss';
import {RequireAuth} from 'utils/RequireAuth';
import {RequireNonAuth} from 'utils/RequireNonAuth';
import {UserRole} from 'types';
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import {useUser} from "@hooks/index";
import InfoLayout from "@layouts/InfoLayout/InfoLayout";
import Content from "./pages/VerifyEmail/Content";
import {useAppDispatch, useAppSelector} from "./store";
import {logout} from "./store/slices/authSlice";
import {Default} from "@layouts/index";

const App: React.FC = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const logoutHandler = () => {
    dispatch(logout({needLogoutFetch: true}));
  };
  if (user?.role === "api"){
    return(
      <InfoLayout>
        <Content state={"warning"} title={"Вы являетесь пользователем API"}
                 subtitle={"Вам недоступен пользовательский интерфейс"}
                 buttonText={"Выйти из системы"}
                 onSubmit={logoutHandler}/>
      </InfoLayout>
    )
  }
  return (
    <div>

        <Router>
          <Default>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireNonAuth>
                    <Login />
                  </RequireNonAuth>
                }
              />
              <Route
                path="/password_recovery"
                element={
                  <RequireNonAuth>
                    <PasswordRecovery />
                  </RequireNonAuth>
                }
              />
              <Route
                path="/alerts"
                element={
                  <RequireAuth>
                    <Alerts />
                  </RequireAuth>
                }
              />
              <Route
                path="/analytics"
                element={
                  <RequireAuth>
                    <Analytics />
                  </RequireAuth>
                }
              />
              <Route
                path="/event-log/:activeTab"
                element={
                  <RequireAuth>
                    <EventLog  />
                  </RequireAuth>
                }
              />
              <Route
                path="/users"
                element={
                  <RequireAuth excludeRoles={[UserRole.observer, UserRole.operator, UserRole.support]}>
                    <Users  />
                  </RequireAuth>
                }
              />
              <Route
                path="management/:activeTab"
                element={
                  <RequireAuth>
                    <Management  />
                  </RequireAuth>
                }
              />
              <Route
                path="verify/:token"
                element={
                    <VerifyEmail />
                }
              />
            </Routes>
          </Default>
        </Router>

    </div>
  );
};

export default App;
