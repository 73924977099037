import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { authEndpoints } from './slices/authEndpoints';
import { adminEnhanced } from './slices/adminEnhanced';
import navbarReducer from './slices/navbarSlice';
import authReducer from './slices/authSlice';
import mapReducer from './slices/mapSlice';
import { userEndpoints } from './slices/userEndpoints';
import { apiSlice } from './slices/apiSlice';

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    auth: authReducer,
    map: mapReducer,
    [authEndpoints.reducerPath]: authEndpoints.reducer,
    [adminEnhanced.reducerPath]: adminEnhanced.reducer,
    [userEndpoints.reducerPath]: userEndpoints.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
