import { Input, Space, Button } from 'antd';
import ColorHash from 'color-hash';
import { SearchOutlined } from '@ant-design/icons';

export const colorHash = (str: string) => new ColorHash().hex(str);

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getColumnSearchProps = (
  dataIndex: string,
  placeholder: string,
  handleSearch: (
    selectedKeys: any[],
    confirm: () => void,
    dataIndex: string
  ) => void,
  handleReset: (clearFilters: any) => void
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: any) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Поиск
        </Button>
        <Button
          onClick={() => {
            handleReset(clearFilters);
            setTimeout(()=>{
              handleSearch(selectedKeys, confirm, dataIndex);
            }, 0)
          }}
          size="small"
          style={{ width: 90 }}
        >
          Сбросить
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
});
