const computedColor = (value = 'норма') => {
  if (value === 'норма') {
    return 'Normal';
  }
  if (value === 'аварийный') {
    return 'Error';
  }
  if (value === 'warning') {
    return 'Warning';
  }
  return '';
};


const computedColorOnsState
  = (value = 'норма') => {
  if (value === 'норма') {
    return 'ItemDot Green';
  }
  if (value === 'авария') {
    return 'ItemDot Red';
  }
  if (value == 'предупреждение') {
    return 'ItemDot Yellow';
  }
  return 'ItemDot Grey';
};
const computedColorSensorState
  = (value = 'норма') => {
  if (value === 'норма') {
    return 'ItemDot Green';
  }
  if (value === 'авария') {
    return 'ItemDot Red';
  }
  if (value == 'в ремонте') {
    return 'ItemDot Yellow';
  }
  return 'ItemDot Grey';
};
const computedColorMode = (value = 'штатный') => {
  if (value === 'штатный') {
    return 'ItemDot Green';
  }
  if (value === 'аварийный') {
    return 'ItemDot Red';
  }
  if (value === 'нештатный') {
    return 'ItemDot Yellow';
  }
  if (value === 'сервисный') {
    return 'ItemDot Blue';
  }
  return 'ItemDot Grey';
};
const computedColorLinkState //связь с ЦОД
  = (value = 'норма') => {
  if (value === 'норма') {
    return 'ItemDot Green';
  }
  if (value === 'обрыв связи') {
    return 'ItemDot Red';
  }
  return 'ItemDot Grey';
};
const computedColorSecurity = (value = 'под охраной') => {
  if (value === 'под охраной') {
    return 'ItemDot Green';
  }
  if (value === 'тревога') {
    return 'ItemDot Red';
  }
  if (value == 'снято') {
    return 'ItemDot Orange';
  }
  if (value === 'ожидание снятия') {
    return 'ItemDot Yellow';
  }
  if (value === 'ожидание постановки') {
    return 'ItemDot Blue';
  }
  return 'ItemDot Grey';
};
const computedColorUPS = (value = 'питание от сети') => {
  if (value === 'питание от сети') {
    return 'ItemDot Green';
  }
  if (value === 'авария') {
    return 'ItemDot Red';
  }
  if (value === 'питание от батареи') {
    return 'ItemDot Yellow';
  }
  if (value === 'обрыв связи') {
    return 'ItemDot Grey';
  }
  return 'ItemDot Grey';
};
const computedColorBoolean = (value = false) => {
  if (!value) {
    return 'ItemDot Green';
  }
  return 'ItemDot Red';
};

const computedColorPumpState
  = (value = 'в работе') => {
  if (value === 'в работе') {
    return 'ItemDot Green';
  }
  if (value === 'авария') {
    return 'ItemDot Red';
  }
  if (value == 'остановлен') {
    return 'ItemDot Yellow';
  }
  if (value == 'обрыв связи') {
    return 'ItemDot Grey';
  }
  if (value == 'в ремонте') {
    return 'ItemDot Blue';
  }
  if (value == 'отключен') {
    return 'ItemDot Orange';
  }
  return 'ItemDot Grey';
};

export {
  computedColorOnsState,
  computedColorLinkState,
  computedColorMode,
  computedColorUPS,
  computedColorSecurity,
  computedColorSensorState,
  computedColorBoolean,
  computedColorPumpState,
};
export default computedColor;

