import centerOfMass from '@turf/center-of-mass';
import { lineString } from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import L, { DivIcon } from 'leaflet';
import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { StationType } from 'types';

type OnsClusterProps = {
  stations: StationType[];
};

const OnsCluster = ({ stations }: OnsClusterProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const map = useMapEvents({
    zoomend: (e) => {
      if (e.target.getZoom() > 13) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    },
  });

  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={14}
      spiderfyOnMaxZoom={false}
      iconCreateFunction={function (cluster) {
        return L.divIcon({
          html: '<div class="clusterOns">' + cluster.getChildCount() + ' онс' + '</div>',
        });
      }}
      showCoverageOnHover={false}
    >
      {stations.map((station, i) => {
        //const stationLine = lineString(station.coords);
        const stationLine = lineString(station.borders);
        const polygonStation = lineToPolygon(stationLine);
        const center = centerOfMass(polygonStation).geometry.coordinates;

        return (
          <Marker
            key={i}
            position={center as any}
            opacity={isVisible ? 1 : 0}
            icon={
              new DivIcon({
                className: undefined,
                html: ReactDOMServer.renderToStaticMarkup(
                  <div>{`ОНС-${station.station_id}`}</div>
                ),
                iconAnchor: undefined,
                shadowUrl: undefined,
                shadowSize: undefined,
                shadowAnchor: undefined,
                iconSize: undefined,
                popupAnchor: [25, -5],
              })
            }
          />
        );
      })}
    </MarkerClusterGroup>
  );
};

export default OnsCluster;
