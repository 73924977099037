import React from "react";
import StockChart from "@content/Analytics/Charts/ChartTypes";
import MultipleStockChart from "@content/Analytics/Charts/ChartTypes/multipleSeriesChart";

interface Props{
  archiveData: TArchive[],
  names: string[]
}
type TArchive = {
  [key: string] : number
}

export default function ConsumedEnergyChart({archiveData, names}: Props){
  return <MultipleStockChart
    archiveData={archiveData}
    colors={[
      "#ffa940",
      "#36cfc9",
      "#40a9ff",
      "#f759ab",
      "#ffec3d",
      "#bae637",
      ]}

    names={names}
    title={"Потребленная электроэнергия (кВт*ч)"}
    textYAxis={"Электроэнергия (кВт*ч)"}
    enableLegend={true}
  />
}
