import Highcharts from 'highcharts/highstock'
import HighchartsReact from "highcharts-react-official";
import {buttonTheme, dataFormatter} from "@content/Analytics/Charts/helper";
import React, {useEffect, useMemo, useState} from "react";
interface Props{
  archiveData: TArchive[];
  names: string[];
  colors: string[];
  title: string;
  textYAxis: string;
  enableLegend?: boolean;
}
type TArchive = {
  [key: string] : number
}

export default function MultipleStockChart({archiveData, names, colors, title, textYAxis, enableLegend}: Props){
  const dataChart = useMemo(()=>{
    return archiveData.map((el)=>{
      return dataFormatter(el);
    })
  },[archiveData]);

  const series = dataChart.map((el, index)=>{
    return {
      data: el,
      name: names[index],
      color: colors[index]
    }
  })
  const  chartOptions = {
    series: series,
    scrollbar: { enabled: false },
    xAxis: {
      type: 'datetime',
    },
    legend:{ enabled: typeof enableLegend !== "undefined" ? enableLegend : false},
    yAxis: {
      opposite: false,
      title: {
        text: textYAxis
      },
    },
    chart: {
      zoomType: 'x',
    },
    title: {
      text: title
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {

      series: {
        tooltip:{
          valueDecimals: 3,
        },
        dataGrouping: {
          enabled: true,
          approximation: "average"
        }
      },

    },
    hoverData: null,
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    rangeSelector: {
      selected: 1,
      buttons: [
        {
          type: 'week',
          count: 1,
          text: '1н',
        },
        {
          type: 'month',
          count: 1,
          text: '1м',
        },
        {
          type: 'month',
          count: 3,
          text: '3м'
        },
        {
          type: 'year',
          count: 1,
          text: '1г'
        }, {
          type: 'all',
          text: 'Все'
        }],
      buttonTheme: buttonTheme
    },

  };
  const [chart, setChart] = useState({} as any);
  useEffect(()=>{
    try {
      chart?.reflow();
    }catch(err){
     // console.error("CATCH reflow", err)
    }
  },[chart])

  return(
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={chartOptions}
      callback={(chart: any)=>{
        setChart(chart)
      }}
    />
  )
}

const dataChart2 = [
  [Date.UTC(2021, 9, 24), 0],
  [Date.UTC(2021, 9, 27), 0.12],
  [Date.UTC(2021, 9, 30), 0.09],
  [Date.UTC(2021, 10,  3), 0.13],
  [Date.UTC(2021, 10,  6), 0.12],
  [Date.UTC(2021, 10,  9), 0.13],
  [Date.UTC(2021, 10, 12), 0.13],
  [Date.UTC(2021, 10, 15), 0.16],
  [Date.UTC(2021, 10, 18), 0.19],
  [Date.UTC(2021, 10, 21), 0.25],
  [Date.UTC(2021, 10, 24), 0.26],
  [Date.UTC(2021, 10, 27), 0.24],
  [Date.UTC(2021, 10, 30), 0.25],
  [Date.UTC(2021, 11,  3), 0.26],
  [Date.UTC(2021, 11,  6), 0.36],
  [Date.UTC(2021, 11,  9), 0.43],
  [Date.UTC(2021, 11, 12), 0.32],
  [Date.UTC(2021, 11, 15), 0.48],
  [Date.UTC(2021, 11, 18), 0.5],
  [Date.UTC(2021, 11, 21), 0.44],
  [Date.UTC(2021, 11, 24), 0.43],
  [Date.UTC(2021, 11, 27), 0.45],
  [Date.UTC(2021, 11, 30), 0.4],
  [Date.UTC(2022, 0,  3), 0.39],
  [Date.UTC(2022, 0,  6), 0.56],
  [Date.UTC(2022, 0,  9), 0.57],
  [Date.UTC(2022, 0, 12), 0.68],
  [Date.UTC(2022, 0, 15), 0.93],
  [Date.UTC(2022, 0, 18), 1.11],
  [Date.UTC(2022, 0, 21), 1.01],
  [Date.UTC(2022, 0, 24), 0.99],
  [Date.UTC(2022, 0, 27), 1.17],
  [Date.UTC(2022, 0, 30), 1.24],
  [Date.UTC(2022, 1,  3), 1.41],
  [Date.UTC(2022, 1,  6), 1.47],
  [Date.UTC(2022, 1,  9), 1.4],
  [Date.UTC(2022, 1, 12), 1.92],
  [Date.UTC(2022, 1, 15), 2.03],
  [Date.UTC(2022, 1, 18), 2.46],
  [Date.UTC(2022, 1, 21), 2.53],
  [Date.UTC(2022, 1, 24), 2.73],
  [Date.UTC(2022, 1, 27), 2.67],
  [Date.UTC(2022, 2,  3), 2.65],
  [Date.UTC(2022, 2,  6), 2.62],
  [Date.UTC(2022, 2,  9), 2.79],
  [Date.UTC(2022, 2, 13), 2.93],
  [Date.UTC(2022, 2, 20), 3.09],
  [Date.UTC(2022, 2, 27), 2.76],
  [Date.UTC(2022, 2, 30), 2.73],
  [Date.UTC(2022, 3,  4), 2.9],
  [Date.UTC(2022, 3,  9), 2.77],
  [Date.UTC(2022, 3, 12), 2.78],
  [Date.UTC(2022, 3, 15), 2.76],
  [Date.UTC(2022, 3, 18), 2.76],
  [Date.UTC(2022, 3, 21), 2.7],
  [Date.UTC(2022, 3, 24), 2.61],
  [Date.UTC(2022, 3, 27), 2.52],
  [Date.UTC(2022, 3, 30), 2.53],
  [Date.UTC(2022, 4,  3), 2.55],
  [Date.UTC(2022, 4,  6), 2.52],
  [Date.UTC(2022, 4,  9), 2.44],
  [Date.UTC(2022, 4, 12), 2.43],
  [Date.UTC(2022, 4, 15), 2.43],
  [Date.UTC(2022, 4, 18), 2.48],
  [Date.UTC(2022, 4, 21), 2.41],
  [Date.UTC(2022, 4, 24), 2.16],
  [Date.UTC(2022, 4, 27), 2.01],
  [Date.UTC(2022, 4, 30), 1.88],
  [Date.UTC(2022, 5,  2), 1.62],
  [Date.UTC(2022, 5,  6), 1.43],
  [Date.UTC(2022, 5,  9), 1.3],
  [Date.UTC(2022, 5, 12), 1.11],
  [Date.UTC(2022, 5, 15), 0.84],
  [Date.UTC(2022, 5, 18), 0.54],
  [Date.UTC(2022, 5, 21), 0.19],
  [Date.UTC(2022, 5, 23), 0]
]