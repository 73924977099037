//@ts-nocheck
import { BaseIcon } from '@base/index';
import { ALL_ICONS } from '@constants/icons';
import React, {useEffect, useState, useReducer, useRef} from 'react';
import styles from './Header.module.scss';
import {TelegramIcon} from "../../../assets/icons/Telegram";
import {
  LogoutOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store';
import { UserRole } from 'types';
import {logout, selectAccessToken, selectUser} from 'store/slices/authSlice';
import {initSocket} from "../../../utils/socket";
import {
  useLazyNotificationsControllerGetNotificationsQuery,
  useNotificationsControllerReadNotificationsMutation,
  useLazyNotificationsControllerGetUnreadCountQuery
} from "../../../store/slices/notificationsEndpoints";
import NotificationSound from "../../../assets/sound/notify_info.mp3"
import {NotificationsDropdown} from "@content/index";
import NotificationsPopup from "@modals/NotificationsPopup/NotificationsPopup";
import {setNewNotices} from "../../../store/slices/navbarSlice";
import {usePrevious} from "../../../utils/common";


interface Props {}


const Header: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectAccessToken);
  const prevToken = usePrevious(token);

  const socketInstance = useRef(undefined);

  const logoutHandler = () => {
    dispatch(logout({needLogoutFetch: true}));
  };

  const onNewNotice = (val) => {
    console.log("onNewNotice val", val);
    dispatch(setNewNotices(val));
  };

  const [openNotices, setOpenNotices] = useState(false);
  const [openBigNotices, setOpenBigNotices] = useState(false);

  const [page, setPage] = useState(0);
  const [newNoticesSocket, setNewNoticesSocket] = useState(0);

  const prevNewNoticesSocket = usePrevious(newNoticesSocket);
  const [play, setPlay] = useState(false);

  useEffect(()=>{
    if (prevNewNoticesSocket !== newNoticesSocket && newNoticesSocket > 0){
        setPlay(true);
        setTimeout(()=>{setPlay(false)}, 1100)
    }
  },[newNoticesSocket, prevNewNoticesSocket])

  const pageRef = React.useRef(page);
  const newNoticesSocketRef = React.useRef(newNoticesSocket);

  useEffect(()=>{
    pageRef.current = page;
  },[page])

  useEffect(()=>{
    newNoticesSocketRef.current = newNoticesSocket;
  },[newNoticesSocket])


  const [hasMore, setHasMore] = useState(true);

  const [loading, setLoading] = useState(false);

  const [getUnreadCount, {data: countUnread}] = useLazyNotificationsControllerGetUnreadCountQuery();
  const [getNotices, {data: notifications}] = useLazyNotificationsControllerGetNotificationsQuery();
  const [readNotices] = useNotificationsControllerReadNotificationsMutation();

  useEffect(()=>{
    getUnreadCount();
  },[])
  const getNotifications = async ()=>{
    console.log("getNotifications", page);
    if (loading){
      return;
    }
    setLoading(true);
    getNotices({page: 1, limit: 4*page+ 4 + newNoticesSocketRef.current}).unwrap().then(result => {
      console.log("fullfiled result================================",result);
      if (result?.result?.items?.length === 0 ||
        (result?.result?.items?.length === result?.result?.totalItems) && (page === 1)
      ){
        setHasMore(false);
      }

      setPage((page)=>page+1);
      setLoading(false);

    }).catch(rejected => {
      console.error("rejected================================", rejected)
      setLoading(false);
    })
  }
  useEffect(()=>{
    console.log("MOUNT HEADER");
    getNotifications();
    return ()=>{
      if (typeof socketInstance?.current !== "undefined") {
        console.log("UNMOUNT cancelSocket");
        cancelSocket(socketInstance.current);
      }
    }
  }, [])

  const audioPlayer = useRef(null);

  const initSocketFunc = async (token)=>{
    console.log("initSocketFunc token", token);
    const socket = await initSocket({baseUrl: process.env.REACT_APP_API_URL as string, token: token as string });
    socketInstance.current = socket;
    console.log("initSocketFunc socket", socket);
    console.log("initSocketFunc socketInstance.current", socketInstance.current);
    socket.on("ping_event", (msg: any) => {
      console.log("SOCKET PING message", msg);
    });

    socket.on("notification", (msg) => {
      console.log("notification SOCKET", msg, "PAGE", page);
      getNotices({page: 1, limit: 4 * pageRef.current + 1 + newNoticesSocketRef.current});
      setNewNoticesSocket((val) => {
        return val + 1
      })
      getUnreadCount();
    });
    socket.on("new_event", (msg) => {
      console.log("new_event SOCKET", msg);
      onNewNotice(getRandomInt(2,11111));
      getUnreadCount();
    });
  }

  const cancelSocket = (socket)=>{
    if (socket) {
      socket.disconnect();
      socket.off("notification");
      socket.off("new_event");
      socket.off("ping_event");
    }
  }
  useEffect(() => {
    console.log("USEEFFECT TOKEN", token, "prevToken", prevToken);
    console.log( "USEEFFECT socketInstance?.current==", socketInstance?.current)
    if (prevToken !== token && typeof prevToken !== "undefined" || typeof prevToken === "undefined") {
      console.log("prevToken !== token", "prevToken", prevToken, "token", token);
      if (typeof socketInstance?.current !== "undefined"){
        console.log("cancelSocket", socketInstance?.current);
        cancelSocket(socketInstance.current);
      }
      if (token?.length > 0) {
        initSocketFunc(token);
        setNewNoticesSocket(0);
      }
    }
  }, [token, prevToken]);
  

  const onReadNotification = (id: number)=>{
    console.log(" onReadNotification", id);
    readNotices({ids: [id]}).unwrap().then(()=>{
      getUnreadCount();
      getNotices({page: 1, limit: 4*pageRef.current })
    });
  }
  const rootEl = useRef(null);


  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeaderLogo}>
          <BaseIcon
            icon={ALL_ICONS.LOGO2}
            viewBox="0 0 24 24"
            className={styles.LogoIcon}
          />
          <div className={styles.LogoText}>Мелиорация</div>
        </div>

        <div className={styles.HeaderActions}>
          <a target={"_blank"}  rel="noreferrer"  href={`https://t.me/klg_melioration_bot?start=${user?.telegramToken}`}
             className={`${styles.HeaderActionsItem} ${styles.ItemTelegram}`}>
            Подключить уведомления <div className={styles.WrapTg}> <TelegramIcon/> </div>
          </a>

          <div className={`${styles.HeaderActionsItem} ${styles.ItemAlert}`}
               onClick={(e)=>{
                 const element = rootEl?.current as any;
                 if (!element?.contains(e.target)){
                   setOpenNotices(true); e.stopPropagation();
                 }
               }}>
            {React.createElement(BellOutlined)}
            {countUnread?.result > 0 &&
              <div className={styles.AlertValue}>{countUnread?.result}</div>
            }
            { play ? <audio ref={audioPlayer} src={NotificationSound} autoPlay={true} /> : null}

            {openNotices && <NotificationsDropdown notifications={notifications?.result?.items}
                                                   getNotifications={getNotifications}
                                                   hasMore={hasMore}
                                                   openAll={()=>{setOpenBigNotices(true); setOpenNotices(false)}}
                                                   onRead={onReadNotification}
                                                   rootEl={rootEl}
                                                   onClose={()=>{
                                                     console.log("ONCLOSE==");
                                                     setOpenNotices(false)}}
                            />
            }
          </div>

          {openBigNotices && <NotificationsPopup isModalVisible={openBigNotices}
                                                 setIsModalVisible={setOpenBigNotices}
                                                 onSubmit={()=>{}}
          /> }

          <div className={`${styles.HeaderActionsItem} ${styles.ItemUsername}`}>
            {user &&
              (user.firstName && user.lastName
                ? user.firstName + ' ' + user.lastName
                : UserRole[user.role])}
          </div>

          <div
            className={`${styles.HeaderActionsItem} ${styles.ItemLogout}`}
            onClick={logoutHandler}
          >
            {React.createElement(LogoutOutlined)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}