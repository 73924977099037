export enum UserRole {
  admin = 'Администратор',
  observer = 'Наблюдатель',
  operator = 'Оператор',
  support = 'Поддержка',
  api = 'пользователь API',
}

export enum UserStatus {
  'active' = 'Активен',
  'inactive' = 'Неактивен',
  'deleted' = 'Удален',
}

export type ApiError = {
  status: number;
  data: { statusCode: number; message: string };
};

export type SensorType = {
  coords: number[];
  level: number;
  sensor_target: string,
  remote_level_sensor_id: string;
  sensor_type: string;
  sensor_name: string;
};
export type SensorType2 = {
  coords: number[];
  level_baltic_system: number;
};

export type StationType = {
  station_id: string;
  coords: number[];
  borders: number[][];
  remote_level_sensors: SensorType2[];
};
