import React from 'react';
import styles from './ManagementMapPopup.module.scss';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import computedColor, {
  computedColorBoolean,
  computedColorMode,
  computedColorOnsState,
  computedColorPumpState,
  computedColorSecurity
} from 'utils/computedColor';
import { UserRole } from 'types';
import RoleBasedView from 'utils/RoleBasedView';
import {OnsPopUpDto} from "../../../store/slices/dku";
import {capitalizeFirstLetter} from "../../../utils/common";
import {round} from "@turf/helpers";

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  data: OnsPopUpDto;
  goToParams: (value: string)=>void
}

const ManagementMapPopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  data,
  goToParams
}) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //const station = stations.find((el)=>el.station_id === stationId);
  console.log("station data====",data);
  return (
    <>
      <Modal
        title={
          <div className={styles.ModalHeader}>
            <div className={styles.Title}>{`ОНС-${data?.station?.station_id}`}</div>
            <RoleBasedView excludeRoles={[UserRole.observer]}>
              <div className={styles.Subtitle} onClick={()=>{goToParams(data?.station?.station_id)}}>
                Параметры
              </div>
            </RoleBasedView>
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={596}
      >
        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Состояние ОНС</span>
          <span className={styles.ItemBorder}/>
          <span
            className={`${styles.ItemDot} ${computedColorOnsState(data?.station?.state)}`}
          />
          <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.state)}</span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Режим работы ОНС</span>
          <span className={styles.ItemBorder}/>
          <span
            className={`${styles.ItemDot} ${computedColorMode(data?.station?.operating_mode)}`}
          />
          <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.operating_mode) || "Нет данных"}</span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Режим охраны</span>
          <span className={styles.ItemBorder}></span>
          <span
            className={`${styles.ItemDot} ${computedColorSecurity(data?.station?.security_system_mode)}`}
          ></span>
          <span className={styles.ItemValue}>{capitalizeFirstLetter(data?.station?.security_system_mode) || "Нет данных"}</span>
        </div>

        {
          data?.station?.pumps?.map((el)=> {
            return <div className={styles.Item} key={el.pump_id}>
              <span className={styles.ItemTitle}>{el.priority}</span>
              <span className={styles.ItemBorder}></span>
              <span
                className={`${styles.ItemDot} ${computedColorPumpState(el?.pump_state)}`}
              ></span>
              <span className={styles.ItemValue}>{capitalizeFirstLetter(el?.pump_state) || "Нет данных"}</span>
            </div>
          })
        }
  {/*      <div className={styles.Item}>
          <span className={styles.ItemTitle}>Основной насос</span>
          <span className={styles.ItemBorder}></span>
          <span
            className={`${styles.ItemDot} ${styles[computedColor()]}`}
          ></span>
          <span className={styles.ItemValue}>В работе</span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Резервный насос 1</span>
          <span className={styles.ItemBorder}></span>
          <span
            className={`${styles.ItemDot} ${styles[computedColor('error')]}`}
          ></span>
          <span className={styles.ItemValue}>Остановлен</span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Резервный насос 2</span>
          <span className={styles.ItemBorder}></span>
          <span
            className={`${styles.ItemDot} ${styles[computedColor('warning')]}`}
          ></span>
          <span className={styles.ItemValue}>В ремонте</span>
        </div>*/}

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Текущая уставка включения насоса
          </span>
          <span className={styles.ItemBorder}/>
          <span className={styles.ItemValue}>
             {`${round(data?.station?.active_setpoint_list?.setpoint_level_high, 2)} м` || "Нет данных"}
          </span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Текущая уставка отключения насоса
          </span>
          <span className={styles.ItemBorder}></span>
          <span className={styles.ItemValue}>
            {`${round(data?.station?.active_setpoint_list?.setpoint_level_low, 2)} м` || "Нет данных"}
          </span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Уровень воды в канале, текущий/прогноз (от ур. моря)
          </span>
          <span className={styles.ItemBorder}/>
          <span className={`${styles.ItemValue}`}>
            {data?.polder?.remote_level_sensors?.map((el)=>{
              return round(el?.level_baltic_system, 2)
            })?.join(", ")
            || "-"}/-
          </span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Возникающие заторы</span>
          <span className={styles.ItemBorder}/>
          <span
            className={`${styles.ItemDot} ${computedColorBoolean(data?.polder?.flood_remote_sensor_list?.length > 0)}`}
          />
          <span className={styles.ItemValue}>
            {data?.polder?.flood_remote_sensor_list?.length > 0 ? (
                <>
                  Есть (
                  { data?.polder?.flood_remote_sensor_list?.join(",") }
                  )
                </>
              )
              : "Отсутствуют"}
          </span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>
            Текущая интенсивность осадков
          </span>
          <span className={styles.ItemBorder}></span>
          <span className={styles.ItemValue}>
            {round(data?.polder?.weatherstation?.precipitation, 2) || "-"}
          </span>
        </div>

        <div className={styles.Item}>
          <span className={styles.ItemTitle}>Текущая температура воздуха</span>
          <span className={styles.ItemBorder}></span>
          <span className={styles.ItemValue}>
            {data?.polder?.weatherstation?.air_temperature ? data?.polder?.weatherstation?.air_temperature + '°С' : "-"}
          </span>
        </div>
      </Modal>
    </>
  );
};

export default ManagementMapPopup;
