import { apiSlice as api } from './apiSlice';
const fileDownload = require('js-file-download');
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    dkuControllerGetStationsCommonInfo: build.query<
      DkuControllerGetStationsCommonInfoApiResponse,
      DkuControllerGetStationsCommonInfoApiArg
    >({
      query: () => ({ url: `/dku/station/get-all-common-info` }),
      keepUnusedDataFor: 5,
    }),
    dkuControllerGetStation: build.query<
      DkuControllerGetStationApiResponse,
      DkuControllerGetStationApiArg
    >({
      query: (queryArg) => ({ url: `/dku/station/${queryArg.stationId}` }),
      keepUnusedDataFor: 5,
    }),
    dkuControllerGetStationSettings: build.query<
      DkuControllerGetStationSettingsApiResponse,
      DkuControllerGetStationSettingsApiArg
    >({
      query: (queryArg) => ({ url: `/dku/station/${queryArg.stationId}/settings` }),
      keepUnusedDataFor: 5,
    }),
    dkuControllerPostStationAction: build.mutation<
      DkuControllerPostStationActionApiResponse,
      DkuControllerPostStationActionApiArg
    >({
      query: (queryArg) => ({
        url: `/dku/station/${queryArg.stationId}/action`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    dkuControllerPostStationSettingsAction: build.mutation<
      DkuControllerPostStationSettingsActionApiResponse,
      DkuControllerPostStationSettingsActionApiArg
    >({
      query: (queryArg) => ({
        url: `/dku/station/${queryArg.stationId}/settings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    dkuControllerGetEventsPhoto: build.query<
      Blob,
      DkuControllerGetEventsPhotoApiArg
      >({
      query: (queryArg) => (
        { url: `/dku/photo/${queryArg.photo}`,
          responseHandler: (response: any) => response.blob().then((blob:any) => URL.createObjectURL(blob))
        }),
      keepUnusedDataFor: 100000,
    }),
    dkuControllerGetReport: build.query<
      Blob,
      DkuControllerGetReportApiArg
      >({
      query: (queryArg) => (
        {url: `/dku/${queryArg.id}/report?period=${queryArg.period}&format=${queryArg.format}`,
          responseHandler: (response: any) => response.blob().then((blob:any) => {
            let format = queryArg.format?.toLowerCase() as string;
            if (format === "xls"){format = "xlsx"}
            if (format === "doc"){format = "docx"}
            fileDownload(blob, `report-${queryArg.period.toLowerCase()}-${queryArg.id}.${format}`);
          })
        }),
      keepUnusedDataFor: 10,
    }),
    dkuControllerGetArchiveData: build.query<
      DkuControllerGetArchiveApiResponse,
      DkuControllerGetArchiveApiArg
      >({
      query: (queryArg) =>{
        const timeFrom = queryArg.time_from;
        const timeTo = queryArg.time_to;
        let timeParams = `&time_from=${timeFrom}T00:00:00`;
        if (typeof timeTo !== "undefined"){
          timeParams+=`&time_to=${timeTo}`
        }else{
          const date = new Date();
          let month = (date.getMonth() + 1)?.toString();
          if (month.length < 2){month = `0${month}`}
          const year = date.getFullYear();
          let day = date.getDate()?.toString();
          if (day.length < 2){day = `0${day}`}
          timeParams+=`&time_to=${year}-${month}-${day}T23:59:59`
        }
        return {
          url: `/dku/archive?source_type=${queryArg.source_type}&source_id=${queryArg.source_id}&parameter_name=${queryArg.parameter_name}${timeParams}`,
        }},
      keepUnusedDataFor: 5,
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as dkuEndpoints };
type TArchive = {
  data: any
}
export type DkuControllerGetArchiveApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: TArchive;
  };
export type DkuControllerGetStationsCommonInfoApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: StationCommonInfoDto[];
  };
export type DkuControllerGetStationsCommonInfoApiArg = void;
export type DkuControllerGetStationApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: OnsPopUpDto;
  };
export type DkuControllerGetStationApiArg = {
  stationId: string;
};
export type DkuControllerPostStationActionApiResponse =
  /** status 200  */ BaseResponseDto;
export type  DkuControllerPostStationSettingsActionApiResponse =
  /** status 201  */ BaseResponseDto;
export type  DkuControllerGetEventsPhotoApiResponse =
  /** status 201  */ BaseResponseDto;

export type DkuControllerGetEventsPhotoApiArg = {
  photo: string
}
export type DkuControllerGetReportApiArg = {
  format: 'pdf' | 'doc' | 'xls' | 'xml',
  period: 'monthly' | 'daily'
  id: number
}
export type DkuControllerGetArchiveApiArg = {
  source_type: 'station' | 'pump' | 'level_sensor' | 'remote_level_sensor' | 'weatherstation';
  source_id: string;
  parameter_name: string;
  time_from?: string;
  time_to?: string; //2022-08-31T13:13:00
}
export type DkuControllerPostStationActionApiArg = {
  stationId: string;
  body:
    | StationActionRequestDto
    | StationChangeModeRequestDto
    | StationStartPumpRequestDto
    | StationStopPumpRequestDto;
};
export type DkuControllerPostStationSettingsActionApiArg = {
  stationId: string;
  body:
    StationActionSettingsRequestDto

};
export type DkuControllerGetStationSettingsApiArg = {
  stationId: string;
};
export type StationSettingsDto = {
  setpoint_list_id: number;
  station_id: string;
  state: string;
  start_time: string;
  finish_time: string;
  setpoint_level_high: number;
  setpoint_level_low: number;
  setpoint_level_change_speed: number;
  last_update_time: string;
  last_update_source: string;
}
export type DkuControllerGetStationSettingsApiResponse =
  BaseResponseDto & {
  result?: StationSettingsDto []
}
export type BaseResponseDto = {
  status: 'OK' | 'ERROR';
  messages?: string[];
  result?: object;
};
export type LevelSensorDto = {
  level_sensor_id: string;
  station_id: string;
  warning_level: number;
  fault_level: number;
  min_level: number;
  max_level: number;
  level_sensor_readings: number;
  level_baltic_system: number;
  installation_height: number;
  setting_max_level: number;
  setting_min_level: number;
  setting_warning_level: number;
  setting_fault_level: number;
  state:
    | 'норма'
    | 'авария'
    | 'в ремонте';
  last_update_time: string;
};
export type RemoteLevelSensorDto = {
  remote_level_sensor_id: string;
  station_id: string;
  sensor_type:
    | 'радарный'
    | 'гидростатический';
  sensor_name: string;
  sensor_target:
    | 'канал'
    | 'грунтовые воды'
    | 'водоприемник';
  level_sensor_readings: number;
  level_baltic_system: number;
  installation_height: number;
  setting_max_level: number;
  setting_min_level: number;
  setting_warning_level: number;
  setting_fault_level: number;
  state:
    | 'норма'
    | 'авария'
    | 'в ремонте';
  box_opened: boolean;
  box_overturned: boolean;
  last_update_time: string;
  coords: number[];
};
export type StationCommonInfoDto = {
  station_id: string;
  state:
    | 'норма'
    | 'предупреждение'
    | 'авария';
  security_system_mode:
    | 'обрыв связи'
    | 'под охраной'
    | 'тревога'
    | 'ожидание постановки на охрану'
    | 'отключена';
  actual_level: number;
  active_level_sensor_id: string;
  level_sensors: LevelSensorDto[];
  remote_level_sensors: RemoteLevelSensorDto[];
  borders: number[][],
  coords: number[]
};

export type SecuritySystemStateType = {
  door_opened: boolean;
  window_opened: boolean;
  window_broken: boolean;
  automation_shield_opened: boolean;
  outdoor_shield_opened: boolean;
  presence_detection: boolean;
};
export type PumpDto = {
  pump_id: string;
  station_id: string;
  pump_state:
    | 'остановлен'
    | 'работе'
    | 'авария'
    | 'обрыв связи'
    | 'в ремонте'
    | 'отключен';
  priority:
    | 'основной'
    | 'резервный 1'
    | 'резервный 2';
  level_sensor_id: string;
  actual_level: number;
  level_sensor_readings: number;
  actual_current: number;
  actual_power: number;
  consumed_energy: number;
  operating_time: number;
  last_update_time: string;
};
export type ActiveSetpointListDto = {
  finish_time: string;
  last_update_source: string;
  last_update_time: string;
  setpoint_level_change_speed: number;
  setpoint_level_high: number;
  setpoint_level_low: number;
  setpoint_list_id: number;
  start_time: string;
  state: string;
  station_id: string;
}

export const StationOperatingModeSet = new Set(['штатный', 'нештатный', 'сервисный', 'аварийный']);
export const isValueStationOperatingMode = (value: string)=>{
  return StationOperatingModeSet.has(value)
}
export type StationOperatingMode =  'штатный' | 'нештатный' | 'сервисный' | 'аварийный';

export type StationDto = {
  station_id: string;
  state:
    | 'норма'
    | 'предупреждение'
    | 'авария';
  operating_mode: StationOperatingMode;
  link_state:
    | 'норма'
    | 'обрыв связи';
  security_system_mode:
    | 'обрыв связи'
    | 'под охраной'
    | 'тревога'
    | 'ожидание постановки на охрану'
    | 'отключена';
  security_system_state: SecuritySystemStateType;
  pumps: PumpDto[];
  level_sensors: LevelSensorDto[];
  active_level_sensor_id: string;
  actual_level: number;
  actual_level_change_speed: number;
  active_setpoint_list: ActiveSetpointListDto;
  UPS_state:
    | 'питание от сети'
    | 'питание от батареи'
    | 'авария'
    | 'обрыв связи';
  air_temperature: number;
  visits_count: number;
  last_update_time: string;
};
export type WeatherstationDto = {
  weatherstation_id: string;
  station_id: string;
  air_temperature: number;
  air_humidity: number;
  atmospheric_pressure: number;
  precipitation: number;
  last_update_time: string;
};
export type PolderDto = {
  station_id: string;
  actual_level: number;
  remote_level_sensors: RemoteLevelSensorDto[];
  flood_remote_sensor_list: string[];
  weatherstation: WeatherstationDto;
  forecast: object;
  last_update_time: string;
};
export type OnsPopUpDto = {
  station: StationDto;
  polder: PolderDto;
};
export type StationActionSettingsRequestDto = {
  setpoint_level_high?:	number;
  setpoint_level_low?: number;
  setpoint_level_change_speed?: number;
};
export type StationActionRequestDto = {
  action:
    | 'reset_pump_failure'
    | 'set_operating_mode'
    | 'arm_security_system'
    | 'disarm_security_system'
    | 'start_pump'
    | 'stop_pump';
};
export type StationChangeModeRequestDto = {
  action: any;
  operating_mode:
    | 'штатный'
    | 'нештатный'
    | 'сервисный'
    | 'аварийный';
};

export type StationStartPumpRequestDto = {
  action: any;
  pump_id: string;
};
export type StationStopPumpRequestDto = {
  action: any;
  pump_id: string;
};
export const {
  useDkuControllerGetStationsCommonInfoQuery,
  useDkuControllerGetStationSettingsQuery,
  useLazyDkuControllerGetEventsPhotoQuery
} = injectedRtkApi;
