import React, { ReactNode } from 'react';
import styles from './BaseHeaderContainer.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
}

const BaseHeaderContent: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={`${styles.BaseHeaderContent} ${className}`}>{children}</div>
  );
};

export default BaseHeaderContent;
