import React, {useEffect, useState} from 'react';
import styles from './WorkingModePopup.module.scss';
import { Modal, Select } from 'antd';
import {StationOperatingMode} from "../../../store/slices/dku";
import {usePrevious} from "../../../utils/common";
import useNotification from "@hooks/useNotification";

const { Option } = Select;

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  initialValue: StationOperatingMode,
  onSubmit: (value: StationOperatingMode)=>any
}

const WorkingModePopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  initialValue,
  onSubmit,
}) => {

  const notificate = useNotification();
  const [value, setValue] = useState(initialValue);

  const prevInitialValue = usePrevious(initialValue);

  useEffect(()=>{
    if (typeof prevInitialValue !== "undefined" && initialValue !== prevInitialValue){
      setValue(initialValue);
    }
  },[initialValue, prevInitialValue])

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    const result = await onSubmit(value);
    console.log("result submit",result);
    if (result?.status === "OK") {
      setIsModalVisible(false);
    }else{
      notificate('error',result?.data?.messages?.[0] || "Не удалось обновить состояние");
    }
  };

  const handleChange = (value: StationOperatingMode) => {
    setValue(value);
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Modal
        title="Режим работы ОНС"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Сохранить"
      >
        <Select
          style={{ width: '100%' }}
          defaultValue="штатный"
          onChange={handleChange}
          value={value}
        >
          <Option value="штатный">Штатный</Option>
          <Option value="нештатный">Нештатный</Option>
          <Option value="сервисный">Сервисный</Option>
        </Select>
      </Modal>
    </>
  );
};

export default WorkingModePopup;
