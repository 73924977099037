import { BaseHeaderContainer, BaseContainer } from '@base/index';
import { Default } from '@layouts/index';
import React, { useEffect, useState } from 'react';
import styles from './Users.module.scss';

import { UserAddOutlined, SearchOutlined } from '@ant-design/icons';

import { Table, Button, Tag, Pagination, Input, Space } from 'antd';
import { AddUserPopup, EditUserPopup } from 'components/modals';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useAdminUsersControllerGetAllUsersQuery } from 'store/slices/adminEnhanced';
import { UserDto, UserFilter, UserSorting } from 'store/slices/adminEndpoints';
import { UserRole, UserStatus } from 'types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { camelToSnakeCase, colorHash, getColumnSearchProps } from 'utils';
import {stationsIdsToColor} from "../../utils/common";
import { PresetColorTypes } from 'antd/lib/_util/colors';
import {useDkuControllerGetStationsCommonInfoQuery} from "../../store/slices/dku";
import {useAdminUsersControllerResetUserPasswordQuery} from "../../store/slices/adminEndpoints"
import {useUser} from "@hooks/index";

interface Props {}

type User = UserDto;

const Users: React.FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  useEffect(() => {
    if (!page) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, []);
  const [filter, setFilter] = useState<UserFilter[]>([]);
  const [sorting, setSorting] = useState<UserSorting[]>([]);
  const { data } = useAdminUsersControllerGetAllUsersQuery({
    getAllUsersDto: { page, limit: 10, filter, sorting },
  });
  //const r = useAdminUsersControllerResetUserPasswordQuery({id:7});

  const {data: stationsData, refetch} = useDkuControllerGetStationsCommonInfoQuery();
  const user = useUser();
  useEffect(()=>{
    console.log("here!")
    refetch();
  }, [user])
  const stationsIds = stationsData?.result?.map(el=>el.station_id);

  const [visible, setVisible] = React.useState(false);
  const [visibleEditPopup, setVisibleEditPopup] = React.useState(false);
  const [editUser, setEditUser] = useState<User>();
  const [filteredInfo, setFilteredInfo] = React.useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = React.useState<
    SorterResult<User> | SorterResult<User>[]
  >({});
  const navigate = useNavigate();
  const [params, setParams] = useState<UserFilter & UserSorting>();

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorting: SorterResult<User> | SorterResult<User>[]
  ) => {
    console.log('Various parameters', pagination, filters, sorting);
    const sortingParams = [
      ...(Array.isArray(sorting)
        ? sorting.map(({ columnKey: sort, order }) => ({
            sort: sort && camelToSnakeCase(sort as string),
            order: order && order === 'ascend' ? 'ASC' : 'DESC',
          }))
        : [
            {
              sort:
                sorting.columnKey &&
                camelToSnakeCase(sorting.columnKey as string),
              order: sorting.order
                ? sorting.order === 'ascend'
                  ? 'ASC'
                  : 'DESC'
                : undefined,
            },
          ]
      ).filter(({ sort, order }) => sort && order),
    ] as UserSorting[];
    const filterParams = [
      ...Object.entries(filters)
        .filter(([_, value]) => value)
        .reduce((acc, [field, search]) => {
          if (!Array.isArray(search)) {
            acc.push({
              field,
              search: search || undefined,
            } as UserFilter);
            return acc;
          }

          const multipleFields = search.map(
            (label) =>
              ({
                field,
                search: label,
              } as UserFilter)
          );
          acc.push(...multipleFields);

          return acc;
        }, [] as Array<UserFilter>),
    ];
    setSortedInfo(sorting);
    setFilteredInfo(filters);

    const url = new URLSearchParams();
    url.append('page', pagination.current?.toString() || '1');
    for (const { field, search } of filterParams) {
      if (field && search) {
        url.append(field, search);
      }
    }
    for (const { sort, order } of sortingParams) {
      if (sort && order) {
        url.append(sort, order);
      }
    }
    navigate('?' + url.toString(), { replace: true });
  };

  const location = useLocation();
  useEffect(() => {
    const filterParams: UserFilter[] = [];
    const sortingParams: UserSorting[] = [];

    for (const key of searchParams.entries()) {
      if (
        key[0] === 'name' ||
        key[0] === 'role' ||
        key[0] === 'status' ||
        key[0] === 'active_till' ||
        key[0] === 'ons'
      ) {
        if (key[1] !== 'ASC' && key[1] !== 'DESC') {
          filterParams.push({
            field: key[0],
            search: key[1],
          });
        }
      }
      if (key[0] === 'name' || key[0] === 'active_till') {
        if (key[1] === 'ASC' || key[1] === 'DESC') {
          sortingParams.push({
            sort: key[0],
            order: key[1],
          });
        }
      }
    }

    setFilter(filterParams);
    setSorting(sortingParams);
  }, [location]);

  const handleSearch = (
    selectedKeys: any[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };
  const filtersOns = stationsIds?.map((id)=>{
    return {
      text: `ОНС-${id}`,
      value: id
    }
  });

  //поиск по фамилии
  const columns: ColumnsType<User> = [
    {
      title: 'Фамилия, Имя',
      dataIndex: ['firstName', 'lastName'],
      key: 'name',
      width: 265,
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'name' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      sorter: (a, b) => {
        return 0;
      },
      sortOrder: sorting.find(({ sort }) => sort === 'name')
        ? sorting.find(({ sort }) => sort === 'name')?.order === 'ASC'
          ? 'ascend'
          : 'descend'
        : undefined,
      ellipsis: true,
      render: (_value, record, _index) => {
        const { firstName, lastName } = record;

        return (
          <span className={styles.Name}>
            {!firstName && !lastName && 'Нет ФИО'}
            {lastName && lastName}
            {firstName && ' ' + firstName}
          </span>
        );
      },
      ...getColumnSearchProps('name', 'Имя', handleSearch, handleReset),
    },
    {
      title: 'Тип учетной записи',
      dataIndex: 'role',
      key: 'role',
      width: 265,
      filters: [
        { text: 'Администратор', value: 'admin' },
        { text: 'Наблюдатель', value: 'observer' },
        { text: 'Оператор', value: 'operator' },
        { text: 'Техподдержка', value: 'support' },
        { text: 'Пользователь API', value: 'api' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'role' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
      render: (role: keyof typeof UserRole) => <span>{UserRole[role]}</span>,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 125,
      filters: [
        { text: 'Активен', value: 'active' },
        { text: 'Неактивен', value: 'inactive' },
        { text: 'Удален', value: 'deleted' },
      ],
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'status' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      ellipsis: true,
      render: (status: keyof typeof UserStatus) => (
        <span className={styles.Status}>
          <span
            className={`${styles.Marker} ${
              status === 'active'
                ? styles.Blue
                : status === 'inactive'
                ? styles.Grey
                : status === 'deleted'
                ? styles.Red
                : ''
            }`}
          ></span>
          {UserStatus[status]}
        </span>
      ),
    },
    {
      title: 'Истекает',
      dataIndex: 'activeTill',
      key: 'activeTill',
      width: '150px',
      sorter: (a, b) => {
        return 0;
      },
      sortOrder: sorting.find(({ sort }) => sort === 'active_till')
        ? sorting.find(({ sort }) => sort === 'active_till')?.order === 'ASC'
          ? 'ascend'
          : 'descend'
        : undefined,
      ellipsis: true,
    },
    {
      title: 'Доступные ОНС',
      key: 'ons',
      dataIndex: 'ons',
      width: 345,
      filters: filtersOns,
   /*     [
        { text: 'ОНС-1', value: 'ОНС-1' },
        { text: 'ОНС-2', value: 'ОНС-2' },
      ],*/
      filteredValue: filter.reduce((acc, value) => {
        if (value.field === 'ons' && value.search) {
          acc.push(value.search);
        }
        return acc;
      }, [] as string[]),
      render: (tags: string[], row) => {
        console.log("tags",tags);
        if (row.role !== 'observer'){
          return "-"
        }
        return <>
          {tags.map((tag, i) => {
            return (
              <Tag color={PresetColorTypes[stationsIdsToColor(stationsIds, tag)]} key={tag}>
                {`ОНС-${tag}`}
              </Tag>
            );
          })}
        </>
      },
    },
    {
      title: 'Действия',
      key: 'action',
      width: '150px',
      render: (_value, record) => {
        return (
          <span
            className={styles.Edit}
            onClick={() => {
              setEditUser(record);
              setVisibleEditPopup(true);
            }}
          >
            Редактировать
          </span>
        );
      },
    },
  ];

  return (
    <>
      <BaseHeaderContainer>
        <div className={styles.Title}>Пользователи и доступ</div>
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setVisible(true)}
        >
          Добавить пользователя
        </Button>
      </BaseHeaderContainer>

      <BaseContainer>
        <div className={styles.AlertsContent}>
          <Table<User>
            rowKey="id"
            columns={columns}
            dataSource={data?.result?.items}
            onChange={handleChange}
            scroll={{ x: 1500 }}
            className="UsersTable"
            pagination={{
              onChange: (page) => {
                searchParams.set('page', page.toString());
                setSearchParams(searchParams);
              },
              current: data?.result?.currentPage,
              total: data?.result?.totalItems,
            }}
          />
        </div>
      </BaseContainer>

      {editUser && (
        <EditUserPopup
          visible={visibleEditPopup}
          setVisible={setVisibleEditPopup}
          user={editUser}
          filtersOns={filtersOns}
        />
      )}
      <AddUserPopup visible={visible} setVisible={setVisible} />
    </>
  );
};

export default Users;
