import React, {useEffect, useState} from 'react';
import styles from './TimeSettingPopup.module.scss';
import { Modal, DatePicker } from 'antd';
import useNotification from "@hooks/useNotification";
import {usePrevious} from "../../../utils/common";
import moment from "moment";

interface Props {
  timeSetting: string;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  initialValue: string;
  onSubmit: (value: string)=>any;
}

const TimeSettingPopup: React.FC<Props> = ({
  timeSetting,
  isModalVisible,
  setIsModalVisible,
  initialValue,
  onSubmit,
}) => {
  //устанавливаем заголовок модалки
  const [title, setTitle] = React.useState('');
  React.useEffect(() => {
    if (timeSetting === 'start') {
      setTitle('Время начала выполнения');
    }
    if (timeSetting === 'end') {
      setTitle('Время окончания выполнения');
    }
  }, [timeSetting]);

  const notificate = useNotification();
  const [value, setValue] = useState(initialValue);

  const prevInitialValue = usePrevious(initialValue);

  useEffect(()=>{
    if (typeof prevInitialValue !== "undefined" && initialValue !== prevInitialValue){
      setValue(initialValue);
    }
  },[initialValue, prevInitialValue])

  const handleOk = async () => {
    const result = await onSubmit(value);
    console.log("result submit",result);
    if (result?.status === "OK") {
      setIsModalVisible(false);
    }else{
      notificate('error',result?.data?.messages?.[0] || "Не удалось обновить состояние");
    }

    if (timeSetting === 'start') {
     // alert('Уставили Время начала выполнения');
    }
    if (timeSetting === 'end') {
     // alert('Уставили Время окончания выполнения');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function onChange(value: any, dateString: any) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setValue(value);
  }

  function onOk(value: any) {
    console.log('onOk: ', value);
  }

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Сохранить"
      >
        <DatePicker
          showTime
          onChange={onChange}
          onOk={onOk}
          defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
          style={{ width: '100%' }}
        />
      </Modal>
    </>
  );
};

export default TimeSettingPopup;
