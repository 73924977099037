import { apiSlice as api } from './apiSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersControllerGet: build.query<
      UsersControllerGetApiResponse,
      UsersControllerGetApiArg
    >({
      query: () => ({ url: `/user` }),
      keepUnusedDataFor: 1
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userEndpoints };
export type UsersControllerGetApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: UserDto;
  };
export type UsersControllerGetApiArg = void;
export type BaseResponseDto = {
  status: 'OK' | 'ERROR';
  messages?: string[];
  result?: object;
};
export type UserDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  telegram: number;
  telegramToken: string;
  role: 'admin' | 'observer' | 'operator' | 'support' | 'api';
  status: 'active' | 'inactive' | 'deleted';
  activeTill: string;
  ons: string[];
};
export const { useUsersControllerGetQuery, useLazyUsersControllerGetQuery } = injectedRtkApi;
