import { apiSlice as api } from './apiSlice';
import {NotificationDto} from "./notificationsEndpoints";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    adminUsersControllerGetAllUsers: build.query<
      AdminUsersControllerGetAllUsersApiResponse,
      AdminUsersControllerGetAllUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users`,
        method: 'POST',
        body: queryArg.getAllUsersDto,
      }),
    }),
    adminUsersControllerGetAllUsersIds: build.query<
      AdminUsersControllerGetAllUsersIdsApiResponse,
      void
    >({
      query: () => ({
        url: `/admin/users/get-all-ids`,
      }),
    }),
    adminUsersControllerCreateSysAdmin: build.query<
      AdminUsersControllerCreateSysAdminApiResponse,
      AdminUsersControllerCreateSysAdminApiArg
    >({
      query: () => ({ url: `/admin/users/create-sys-admin` }),
    }),
    adminUsersControllerRegister: build.mutation<
      AdminUsersControllerRegisterApiResponse,
      AdminUsersControllerRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/register`,
        method: 'POST',
        body: queryArg.createUserDto,
      }),
    }),
    adminUsersControllerUpdateUserStatus: build.mutation<
      AdminUsersControllerUpdateUserStatusApiResponse,
      AdminUsersControllerUpdateUserStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.id}/status`,
        method: 'PUT',
        body: queryArg.updateUserStatusDto,
      }),
    }),
    adminUsersControllerUpdateUser: build.mutation<
      AdminUsersControllerUpdateUserApiResponse,
      AdminUsersControllerUpdateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.id}/update`,
        method: 'PUT',
        body: queryArg.updateUserDto,
      }),
    }),
    adminUsersControllerResetUserPassword: build.query<
      AdminUsersControllerResetUserPasswordApiResponse,
      AdminUsersControllerResetUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.id}/reset-password`,
      }),
    }),
    adminUsersControllerProlongActiveTill: build.mutation<
      AdminUsersControllerProlongActiveTillApiResponse,
      AdminUsersControllerProlongActiveTillApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.id}/prolong`,
        method: 'PUT',
        body: queryArg.prolongUserActiveTillDto,
      }),
    }),
    adminUsersControllerRefreshTelegramToken: build.mutation<
      AdminUsersControllerRefreshTelegramTokenApiResponse,
      AdminUsersControllerRefreshTelegramTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.id}/refresh-telegram-token`,
        method: 'PUT',
      }),
    }),
    adminUsersControllerGetById: build.query<
      AdminUsersControllerGetByIdApiResponse,
      AdminUsersControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/users/${queryArg.id}` }),
    }),
    adminEventsControllerGetUserEvents: build.query<
      AdminEventsControllerGetUserEventsApiResponse,
      AdminEventsControllerGetUserEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/events/user`,
        method: 'POST',
        body: queryArg.getUserEventsDto,
      }),
      keepUnusedDataFor: 10
    }),
    adminEventsControllerGetSystemEvents: build.query<
      AdminEventsControllerGetSystemEventsApiResponse,
      AdminEventsControllerGetSystemEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/events/system`,
        method: 'POST',
        body: queryArg.getSystemEventsDto,
      }),
      keepUnusedDataFor: 10
    }),
    adminUsersControllerGetAllGroups: build.query<
      AdminUsersControllerGetAllGroupsApiResponse,
      PaginationArgs
      >({
      query: (queryArg) => ({
        url: `/admin/users/groups?page=${queryArg.page || 1}&limit=${queryArg.limit || 10000}`,
      }),
      keepUnusedDataFor: 10
    }),
    adminUsersControllerGetAllSystemEventsNames: build.query<
      AdminUsersControllerGetAllSystemEventsNamesApiResponse, string>({
      query: ()=>({
        url: `/admin/users/groups/system-event-names`,
      })
    }),
    adminUsersControllerCreateGroup: build.mutation<
      BaseResponseDto,
      AdminUsersControllerCreateGroupApiArg
      >({
      query: (queryArg) => ({
        url: `/admin/users/groups`,
        method: 'POST',
        body: queryArg
      }),
    }),
    adminUsersControllerEditGroup: build.mutation<
      BaseResponseDto,
      AdminUsersControllerEditGroupApiArg
      >({
      query: (queryArg) => ({
        url: `/admin/users/groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.data
      }),
    }),
    adminUsersControllerDeleteGroup: build.mutation<
      BaseResponseDto,
      TId
      >({
      query: (queryArg) => ({
        url: `/admin/users/groups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    adminUsersControllerGetUsersNotInGroup: build.query<
      AdminUsersControllerGetUsersNotInGroupsApiResponse,
      {id: number, page: number}
      >({
      query: (queryArg) => ({
        url: `/admin/users/groups/${queryArg.id}/users-not-in`,
      }),
    }),
    adminUsersControllerSendSocket: build.mutation<
      BaseResponseDto,
      AdminTestSocketArgs
      >({
      query: (queryArg) => ({
        url: `/admin/events/test/${queryArg.id}`,
        method: 'POST',
        body: queryArg.event
      }),
    }),

  }),
  overrideExisting: false,
});

export const { useAdminUsersControllerResetUserPasswordQuery } = injectedRtkApi;
export { injectedRtkApi as adminEndpoints };

export type AdminTestSocketArgs =  {
  id: number,
  event: NotificationDto
}
export type AdminUsersControllerGetAllUsersApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: PaginationDto & {
      items?: UserDto[];
    };
  };
  export type AdminUsersControllerGetAllUsersIdsApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: number[]
  };
  export type AdminUsersControllerGetAllGroupsApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: PaginationDto & {
      items?: GroupDto[];
    };
  };
  export type  AdminUsersControllerGetAllSystemEventsNamesApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: SystemEventsNamesDto[];

  };
export type AdminUsersControllerGetAllUsersApiArg = {
  getAllUsersDto: GetAllUsersDto;
};
export type AdminUsersControllerCreateSysAdminApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerCreateSysAdminApiArg = void;
export type AdminUsersControllerRegisterApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerRegisterApiArg = {
  createUserDto: CreateUserDto;
};
export type AdminUsersControllerUpdateUserStatusApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerUpdateUserStatusApiArg = {
  id: number;
  updateUserStatusDto: UpdateUserStatusDto;
};
export type AdminUsersControllerUpdateUserApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerUpdateUserApiArg = {
  id: number;
  updateUserDto: UpdateUserDto;
};
export type AdminUsersControllerResetUserPasswordApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerResetUserPasswordApiArg = {
  id: number;
};
export type AdminUsersControllerProlongActiveTillApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerProlongActiveTillApiArg = {
  id: number;
  prolongUserActiveTillDto: ProlongUserActiveTillDto;
};
export type AdminUsersControllerRefreshTelegramTokenApiResponse =
  /** status 200  */ BaseResponseDto;
export type AdminUsersControllerRefreshTelegramTokenApiArg = {
  id: number;
};
export type AdminUsersControllerGetUsersNotInGroupsApiResponse =
/** status 200  */ BaseResponseDto & {
  result: PaginationDto & {
    items: UserDto[];
  };
};
type TId = {
  id: number
}
export type AdminUsersControllerCreateGroupApiArg = {
  name: string;
  sms: boolean;
  telegram: boolean;
  email: boolean;
  browser: boolean;
  users: TId[],
  event_names: TId[]
};
export type AdminUsersControllerEditGroupApiArg = {
  id: number,
  data: {
    name?: string;
    sms?: boolean;
    telegram?: boolean;
    email?: boolean;
    browser?: boolean;
    users?: TId[],
    event_names?: TId[]
  }
}
export type AdminUsersControllerGetByIdApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: UserDto;
  };
export type AdminUsersControllerGetByIdApiArg = {
  id: number;
};
export type AdminEventsControllerGetUserEventsApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: PaginationDto & {
      items?: UserEventDto[];
    };
  };
export type AdminEventsControllerGetUserEventsApiArg = {
  getUserEventsDto: GetUserEventsDto;
};
export type AdminEventsControllerGetSystemEventsApiResponse =
  /** status 200  */ BaseResponseDto & {
    result?: PaginationDto & {
      items?: SystemEventDto[];
    };
  };
export type AdminEventsControllerGetSystemEventsApiArg = {
  getSystemEventsDto: GetSystemEventsDto;
};
export type BaseResponseDto = {
  status: 'OK' | 'ERROR';
  messages?: string[];
  result?: object;
};
export type PaginationDto = {
  totalItems: number;
  currentPage: number;
  totalPages: number;
  limit: number;
  items: object[];
};
export type PaginationArgs = {
  page?: number;
  limit?: number;
};
export type GroupDto ={
  id: number;
  name: string;
  email: boolean;
  sms: boolean;
  telegram: boolean;
  browser: boolean;
  users?: UserDto[];
  event_names: any[];
}
export type UserDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  telegram: number;
  telegramToken: string;
  role: 'admin' | 'observer' | 'operator' | 'support' | 'api';
  status: 'active' | 'inactive' | 'deleted';
  activeTill: string;
  ons: string[];
  group?: GroupDto[]
};
export type UserFilter = {
  search?: string;
  field?: 'name' | 'role' | 'status' | 'active_till' | 'ons';
};
export type UserSorting = {
  order: string;
  sort: 'name' | 'active_till';
};
export type GetAllUsersDto = {
  page?: number;
  limit?: number;
  filter?: UserFilter[];
  sorting?: UserSorting[];
};
export type CreateUserDto = {
  email: string;
  firstName: string;
  secondName: string;
  lastName: string;
  phone: string;
  telegram: number;
  role: 'admin' | 'observer' | 'operator' | 'support' | 'api';
};
export type UpdateUserStatusDto = {
  status: string;
};
export type UpdateUserDto = {
  email?: string;
  firstName?: string;
  secondName?: string;
  lastName?: string;
  phone?: string;
  telegram?: number;
  role?: 'admin' | 'observer' | 'operator' | 'support' | 'api';
  ons: any[];
};
export type ProlongUserActiveTillDto = {
  months: number;
};
export type UserEventDto = {
  date: string;
  userId: number;
  firstName: string;
  lastName: string;
  action: string;
  parameter: string;
  valueBefore: string;
  valueAfter: string;
};
export type UserEventFilterDto = {
  search?: string;
  field?: 'date' | 'name' | 'action' | 'parameter';
};
export type UserEventSortingDto = {
  order: string;
  sort: 'date' | 'name';
};
export type GetUserEventsDto = {
  page?: number;
  limit?: number;
  filter: UserEventFilterDto[];
  sorting: UserEventSortingDto[];
};
export type EventDataType = {
  photo_list?: string[];
  flood_remote_sensor_list?: string[];
};
export type SystemEventDto = {
  id: string;
  foreign_id: string;
  event_name: string;
  event_type: string;
  station_id: string;
  source_type: string;
  source_id: string;
  data: EventDataType;
  date: string;
};
export type SystemEventFilterDto = {
  search?: string;
  field?: 'date' | 'event_name' | 'event_type' | 'source_type';
};
export type SystemEventSortingDto = {
  order: string;
  sort: 'date' | 'event_type' | 'source_type' | 'source_id';
};
export type GetSystemEventsDto = {
  page?: number;
  limit?: number;
  filter: SystemEventFilterDto[];
  sorting: SystemEventSortingDto[];
};
export type SystemEventsNamesDto = {
  id: number;
  value: string;
}