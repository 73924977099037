import io from "socket.io-client";

export type TInitSocketProps = {
  baseUrl: string;
  token: string;
}
export const initSocket = async ({baseUrl, token} : TInitSocketProps) => {
  console.log("initSocket===================================================================================================================");
  const socket = await io(baseUrl.replace("/api", ""), {
    path: "/api/socket.io",
    query: { token: token },
    forceNew: true,
    transports: ["websocket", "polling"],
  });
  return socket;
};