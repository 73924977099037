//@ts-nocheck
import React, {useEffect, useState} from 'react';
import styles from './EditUserPopup.module.scss';
import {
  Drawer,
  Form,
  Input,
  Select,
  DatePicker,
  Tag,
  Button,
  Modal,
} from 'antd';
import moment from 'moment';
import { useNotification } from '@hooks/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { colorHash } from 'utils';
import { UpdateUserDto, UserDto } from 'store/slices/adminEndpoints';
import {
  useAdminUsersControllerUpdateUserMutation,
  useAdminUsersControllerUpdateUserStatusMutation,
  useAdminUsersControllerSendSocketMutation
} from 'store/slices/adminEnhanced';
import {ApiError, UserRole} from 'types';
import { PresetColorTypes } from 'antd/lib/_util/colors';
import {stationsIdsToColor} from "../../../utils/common";
import {useDkuControllerGetStationsCommonInfoQuery} from "../../../store/slices/dku";

const { Option } = Select;
const { confirm } = Modal;
type TFilter = {
  value: string,
  text: string
}

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  user: UserDto;
  filtersOns: TFilter[] | undefined
}

const EditUserPopup: React.FC<Props> = ({ visible, setVisible, user, filtersOns }) => {
  const notificate = useNotification();
  const [updateUser] = useAdminUsersControllerUpdateUserMutation();
  const [updateStatus] = useAdminUsersControllerUpdateUserStatusMutation();
  const {data: stationsData} = useDkuControllerGetStationsCommonInfoQuery();
  const stationsIds = stationsData?.result?.map(el=>el.station_id);

  const isObserver = user.role === 'observer';
  //модалка удаления юзера
  function showDeleteConfirm() {
    confirm({
      title: 'Вы уверены, что хотите удалить пользователя?',
      icon: <ExclamationCircleOutlined />,
      content: 'Данные не сохранятся',
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await updateStatus({
            id: user.id,
            updateUserStatusDto: {
              status: 'deleted',
            },
          });
        } catch (e) {
          const error = e as ApiError;
          notificate('error', error.data.message);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  //модалка Отмены редактирования юзера
  function showConfirm() {
    confirm({
      title: 'Вы уверены, что хотите отменить изменения?',
      icon: <ExclamationCircleOutlined />,
      content: 'Данные не сохранятся',
      cancelText: 'Вернуться',
      okText: 'Подтвердить',
      onOk() {
        form.resetFields();
        onClose();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onClose = () => {
    setVisible(false);
  };

  const submitHandler = async () => {
    const values = form.getFieldsValue();
    const valuesClear = {...values};
    if (valuesClear?.telegram?.length === 0 ){
      valuesClear.telegram = 0;
    }else{
      valuesClear.telegram = +valuesClear.telegram;
    }
    let valuesDto = {
      ...valuesClear,
      activeTill: values.activeTill?.format('YYYY-MM-DD'),
    };

    try {
      await updateUser({
        id: user.id,
        updateUserDto: valuesDto as UpdateUserDto,
      }).unwrap();
      setVisible(false);
      notificate('success', 'Настройки профиля изменены');
    } catch (e) {
      const error = e as ApiError;
      notificate('error', error.data.message);
    }
  };

  //логика формы
  const [form] = Form.useForm();
  //дефолтный сабмит формы
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  //Доступные ОНС
  const options = filtersOns || [];
/*  const options = [
    { value: 'ОНС-1' },
    { value: 'ОНС-2' },
    { value: 'ОНС-3' },
    { value: 'ОНС-4' },
    { value: 'ОНС-5' },
  ];*/

  const dateFormat = 'YYYY/MM/DD';

  function TagRender(props: any) {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={PresetColorTypes[stationsIdsToColor(stationsIds, value)]}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      activeTill: moment(user.activeTill, dateFormat).isValid()
        ? moment(user.activeTill, dateFormat)
        : null,
      phone: user.phone ? user.phone : '',
    });
  }, [form, user]);

  const [sendNotice] = useAdminUsersControllerSendSocketMutation();
  const [ons, setOns] = useState("");
  const [msg, setMsg] = useState("");
  const [level, setLevel] = useState("info");
  const onSendNotice = ()=>{
      sendNotice({
        id: user.id,
        event: {
          level,
          message: msg,
          stationId: ons,
          isRead:	false,
          createdAt:  new Date().toISOString()
        }
      }).unwrap().then(result=>{
        console.log("rrrrr", result);
          notificate("success", "Отправлено")
      }).catch(err=>{
        console.log("rrrr2",err);
      })
  }
  const onSendNoticeAdmin = ()=>{
      sendNotice({
        id: 1,
        event: {
          level,
          message: msg,
          stationId: ons,
          isRead:	false,
          createdAt:  new Date().toISOString()
        }
      }).unwrap().then(result=>{
        console.log("rrrrr", result);
          notificate("success", "Отправлено")
      }).catch(err=>{
        console.log("rrrr2",err);
      })
  }

  return (
    <>
      <Drawer
        title={`${user.lastName || ''} ${user.firstName || ''}`}
        placement="right"
        width={470}
        onClose={onClose}
        visible={visible}
      >
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          style={{ paddingBottom: 50 }}
          initialValues={{
            ...user,
            activeTill: moment(user.activeTill, dateFormat),
            phone: user.phone ? user.phone : '',
          }}
          scrollToFirstError
          className={styles.AddUserForm}
        >
          <Form.Item
            name="lastName"
            label="Фамилия"
            rules={[
              {
                required: true,
                message: 'Укажите фамилию',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="Имя"
            rules={[
              {
                required: true,
                message: 'Укажите имя',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="role"
            label="Тип учетной записи"
            rules={[{ required: true }]}
          >
            <Select placeholder="Выберите тип">
              <Option value="admin">Администратор</Option>
              <Option value="observer">Наблюдатель</Option>
              <Option value="operator">Оператор</Option>
              <Option value="support">Техподдержка</Option>
              <Option value="api">Пользователь API</Option>
            </Select>
          </Form.Item>

          <Form.Item name="status" label="Статус" rules={[{ required: true }]}>
            <Select placeholder="Выберите статус">
              <Option value="active">Активен</Option>
              <Option value="inactive">Неактивен</Option>
              <Option value="deleted">Удален</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="activeTill"
            label="Временная метка"
            rules={[{ required: true, message: 'Выберите дату' }]}
          >
            <DatePicker
              placeholder=""
              format={dateFormat}
              style={{ width: '100%' }}
            />
          </Form.Item>


          {isObserver &&
            <Form.Item
              name="ons"
              label="Доступные ОНС"
              rules={[{required: true, message: 'Выберите ОНС'}]}
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={TagRender}
                style={{width: '100%'}}
              >
                {options.map((item) => (
                  <Select.Option key={item.value}>{item.text}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          }

          <div className={styles.Contacts}>Контакты</div>

          <Form.Item
            name="phone"
            label="Телефон"
            rules={[{ required: true, message: 'Введите номер телефона' }]}
            getValueFromEvent={(e) => {
              const value = e.target.value;
              return value.replace(/[^0-9.]/g, "");
            }}
          >
            <Input
              placeholder="996-606-53-23"
              addonBefore="+7"
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="telegram"
            label="Телеграм ID"
            rules={[
              {
                message: 'Укажите телеграм',
                whitespace: true,
              },
            ]}
            getValueFromEvent={(e) => {
              const value = e.target.value;
              return value.replace(/[^0-9.]/g, "");
            }}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            name="email"
            label="Эл.почта"
            rules={[
              {
                type: 'email',
                message: 'Введен неверный E-mail!',
              },
              {
                required: true,
                message: 'Введите эл.почту',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div>Отправить тестовое уведомление</div>
          <br/>
          <div>Сообщение</div>
          <input type="text" value={msg} style={{width: "100%"}}
                 onChange={(e)=>{setMsg(e.target.value)}}/>
          <div>Номер онс</div>
          <input type="text" value={ons} style={{width: "100%"}}
                 onChange={(e)=>{setOns(e.target.value)}}/>
          <div>Тип</div>
          <select name="typeNotice" id="12345" value={level}
                  style={{width: "100%"}}
                  onChange={(e)=>{setLevel(e.target.value)}}>
            <option value="info">Info</option>
            <option value="warning">Warning</option>
            <option value="error">Error</option>
          </select>
          <br/>
          <br/>
          <div>
            <button onClick={onSendNotice} style={{width: "100%"}}>Send</button>
            <br/>
            <br/>
            <button onClick={onSendNoticeAdmin} style={{width: "100%"}}>Send Admin</button>
          </div>

        </Form>
        <div className={styles.FormActions}>
          <Button danger onClick={showDeleteConfirm}>
            Удалить
          </Button>

          <div className={styles.FormActionsMain}>
            <Button className={styles.BtnSecondary} onClick={showConfirm}>
              Отменить
            </Button>
            <Button type="primary" onClick={submitHandler}>
              Сохранить
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default EditUserPopup;
