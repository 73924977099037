import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../';

export interface NavbarState {
  collapsed: boolean;
  newNotices: number;
}

const initialState: NavbarState = {
  collapsed: JSON.parse(localStorage.getItem('collapsed') || 'false'),
  newNotices: 0
};

export const counterSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggle: (state) => {
      localStorage.setItem('collapsed', JSON.stringify(!state.collapsed));
      state.collapsed = !state.collapsed;
    },
    setNewNotices: (state, action) => {
      console.log("setNewNotices payload", action.payload);
      state.newNotices = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggle, setNewNotices } = counterSlice.actions;

export default counterSlice.reducer;

export const selectCollapsed = (state: RootState) => state.navbar.collapsed;
export const selectNewNotices = (state: RootState) => state.navbar.newNotices;
